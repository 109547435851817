import { Pipe, PipeTransform } from '@angular/core';
import {
  ActivityFilter,
  ActivityWithCategoryAndLocationsAndReportedDetails,
  GeoLocation,
} from '@wilson/interfaces';
import { LocationsStoreService } from '@wilson/locations';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'activityEndLocation',
  standalone: true,
})
export class ActivityEndLocationPipe implements PipeTransform {
  constructor(private readonly locationService: LocationsStoreService) {}

  transform(
    activity: ActivityWithCategoryAndLocationsAndReportedDetails,
    selectedFilter: ActivityFilter,
  ): Observable<GeoLocation> {
    if (selectedFilter === ActivityFilter.Reported) {
      return this.locationService.getLocationFromCache(
        activity.reportedEndLocationId,
      );
    } else {
      return of(activity.endLocation);
    }
  }
}
