import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { GeoLocation } from '@wilson/interfaces';
import { map, Observable, of } from 'rxjs';
import { GetLocationAction } from './state/location.actions';
import { LocationState } from './state/location.state';

@Injectable({
  providedIn: 'root',
})
export class LocationsStoreService {
  constructor(private store: Store) {}

  getLocationFromCache(
    id: string | null,
  ): Observable<GeoLocation | null | undefined> {
    if (id) {
      const location = this.store.selectSnapshot<GeoLocation>(
        LocationState.location(id),
      );
      if (location) {
        return of(location);
      } else {
        return this.store
          .dispatch(new GetLocationAction(id))
          .pipe(
            map(() =>
              this.store.selectSnapshot<GeoLocation>(
                LocationState.location(id),
              ),
            ),
          );
      }
    } else {
      return of(null);
    }
  }
}
