import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AccountService } from '@wilson/account';
import { User } from '@wilson/interfaces';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { catchError, map, Observable, of } from 'rxjs';

@Component({
  selector: 'wilson-multi-users-select',
  standalone: true,
  imports: [CommonModule, TranslateModule, NzSelectModule, FormsModule],
  templateUrl: './multi-users-select.component.html',
  styleUrl: './multi-users-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiUsersSelectComponent),
      multi: true,
    },
  ],
})
export class MultiUsersSelectComponent implements OnInit, ControlValueAccessor {
  protected userOptions$!: Observable<User[]>;
  protected isDisabled = false;

  @Input()
  hideLabel = false;

  @Input()
  label!: string;

  @Input()
  isRequired!: boolean;

  @Input()
  hideSelected = false;

  @Input()
  maxTagCount = 3;

  @Input()
  listOfSelected: string[] = [];

  constructor(
    private accountService: AccountService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.userOptions$ = this.accountService.getUsers().pipe(
      map((users) => {
        return users.sort((a, b) =>
          `${a.lastName}, ${a.firstName}`.localeCompare(
            `${b.lastName}, ${b.firstName}`,
          ),
        );
      }),
      catchError(() => of([])),
    );
  }

  protected selectedUserIds: string[] = [];

  protected updateSelectedUserIds(ids: string[]) {
    this.selectedUserIds = ids;
    this.propagateChange(ids);
  }

  writeValue(value: string[]) {
    this.selectedUserIds = value;
    this.cdr.detectChanges();
  }

  // eslint-disable-next-line
  propagateChange: (value: string[]) => void = () => {};

  registerOnChange(fn: () => void) {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched() {}

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.cdr.detectChanges();
  }

  public isSelected(value: string | undefined): boolean {
    if (this.hideSelected && value) {
      return this.listOfSelected.indexOf(value) !== -1;
    }
    return false;
  }
}
