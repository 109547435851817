import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-light-svg-icons';
import { faGear } from '@fortawesome/pro-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'wilson-activities-setting-button',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NzToolTipModule,
    FontAwesomeModule,
    NzButtonModule,
    NzDropDownModule,
    NzSwitchModule,
  ],
  templateUrl: './activities-setting-button.component.html',
  styleUrl: './activities-setting-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivitiesSettingButtonComponent {
  @Input({ required: true }) isAutoAdaptActivitiesEnabled = true;
  @Output() isAutoAdaptActivitiesEnabledChange = new EventEmitter<boolean>();

  readonly faGear = faGear;
  readonly faCheck = faCheck;
  readonly faXmark = faXmark;
}
