import { Pipe, PipeTransform } from '@angular/core';
import {
  ActivityFilter,
  ActivityWithCategoryAndLocationsAndReportedDetails,
} from '@wilson/interfaces';

@Pipe({
  name: 'activityStartDatetime',
  standalone: true,
})
export class ActivityStartDatetimePipe implements PipeTransform {
  transform(
    activity: ActivityWithCategoryAndLocationsAndReportedDetails,
    selectedFilter: ActivityFilter,
  ): string {
    if (selectedFilter === ActivityFilter.Reported) {
      return activity.reportedStartDatetime;
    } else {
      return activity.startDatetime;
    }
  }
}
