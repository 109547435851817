import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { captureException } from '@sentry/angular';
import { ActivityReportsService } from '@wilson/activities';
import { ActivitiesGateway } from '@wilson/api/gateway';
import {
  Activity,
  ActivityReport,
  ActivityReportCategory,
  ActivityReportType,
  ActivityWithCategoryAndLocationsAndReportedDetails,
  OperationStatus,
  PublicationStatus,
  Shift,
  WithPreparedAttributes,
} from '@wilson/interfaces';
import { ShiftsService } from '@wilson/shifts';
import { differenceInMilliseconds, subMilliseconds } from 'date-fns';
import { NzMessageService } from 'ng-zorro-antd/message';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class DeleteActivityHelperService {
  constructor(
    private readonly nzMessageService: NzMessageService,
    private readonly translate: TranslateService,
    private readonly activitiesGateway: ActivitiesGateway,
    private readonly activityReportsService: ActivityReportsService,
    private readonly shiftsService: ShiftsService,
  ) {}

  public async deleteActivity(
    activity: Activity,
    shift: Shift &
      WithPreparedAttributes & { id: string } & {
        activities: ActivityWithCategoryAndLocationsAndReportedDetails[];
      },
    isShiftSubmitted: boolean,
    isAutoAdaptActivitiesEnabled: boolean,
  ): Promise<boolean> {
    try {
      const deletedActivityDuration = differenceInMilliseconds(
        new Date(activity.endDatetime),
        new Date(activity.startDatetime),
      );
      const activityIndex = shift.activities.findIndex(
        (a) => a.id === activity.id,
      );
      if (isShiftSubmitted) {
        // TODO: We need BE for this case then we can use this function - https://menlo1679.atlassian.net/browse/WILP-7918
        // await this.deleteActivityWhenShiftIsSubmitted(
        //   activity,
        //   activityIndex,
        //   deletedActivityDuration,
        //   shift,
        //   isAutoAdaptActivitiesEnabled,
        // );
      } else {
        await this.deleteActivityWhenShiftIsNotStarted(
          activityIndex,
          deletedActivityDuration,
          shift,
          isAutoAdaptActivitiesEnabled,
        );
      }
      return true;
    } catch (error) {
      this.nzMessageService.error(
        this.translate.instant('general.something_went_wrong'),
      );
      captureException(error);
      return false;
    }
  }

  private async deleteActivityWhenShiftIsNotStarted(
    activityIndex: number,
    deletedActivityDuration: number,
    shift: Shift &
      WithPreparedAttributes & { id: string } & {
        activities: ActivityWithCategoryAndLocationsAndReportedDetails[];
      },
    isAutoAdaptActivitiesEnabled: boolean,
  ): Promise<void> {
    shift.activities.splice(activityIndex, 1);
    if (isAutoAdaptActivitiesEnabled) {
      shift.activities.map(
        (
          currentActivity: ActivityWithCategoryAndLocationsAndReportedDetails,
          index,
        ) => {
          if (index >= activityIndex) {
            currentActivity.startDatetime = subMilliseconds(
              new Date(currentActivity.startDatetime),
              deletedActivityDuration,
            ).toISOString();
            currentActivity.endDatetime = subMilliseconds(
              new Date(currentActivity.endDatetime),
              deletedActivityDuration,
            ).toISOString();
          }
          return currentActivity;
        },
      );
    }

    await firstValueFrom(
      this.shiftsService.updateShift({
        id: shift.id,
        publicationStatus: PublicationStatus.NotPublished,
        activities: shift.activities,
      }),
    );
  }

  private async deleteActivityWhenShiftIsSubmitted(
    activity: Activity,
    activityIndex: number,
    deletedActivityDuration: number,
    shift: Shift &
      WithPreparedAttributes & { id: string } & {
        activities: ActivityWithCategoryAndLocationsAndReportedDetails[];
      },
    isAutoAdaptActivitiesEnabled: boolean,
  ): Promise<void> {
    const activityReportsToCreate: ActivityReport[] = [];
    if (isAutoAdaptActivitiesEnabled) {
      shift.activities.map(
        (
          currentActivity: ActivityWithCategoryAndLocationsAndReportedDetails,
          index,
        ) => {
          if (index === activityIndex) {
            currentActivity.operationalStatus = OperationStatus.SkippedByUser;
          } else if (
            index > activityIndex &&
            currentActivity.activityReports.length > 0
          ) {
            activityReportsToCreate.push(
              {
                reportType: ActivityReportType.IsTimes,
                reportCategory: ActivityReportCategory.Start,
                dateTime: subMilliseconds(
                  new Date(currentActivity.reportedStartDatetime),
                  deletedActivityDuration,
                ).toISOString(),
                activityId: currentActivity.id,
                locationId: currentActivity.reportedStartLocationId,
              },
              {
                reportType: ActivityReportType.IsTimes,
                reportCategory: ActivityReportCategory.End,
                dateTime: subMilliseconds(
                  new Date(currentActivity.reportedEndDatetime),
                  deletedActivityDuration,
                ).toISOString(),
                activityId: currentActivity.id,
                locationId: currentActivity.reportedEndLocationId,
              },
            );
          }
          return currentActivity;
        },
      );
    }

    if (activity.serviceId) {
      shift.activities.splice(activityIndex, 1);
    }
    if (activity.activityReports.length > 0) {
      await this.activitiesGateway.deleteActivityReports(activity.id as string);
    }
    await firstValueFrom(
      this.shiftsService.updateShift({
        id: shift.id,
        activities: shift.activities,
      }),
    );
    if (activityReportsToCreate.length > 0) {
      await this.activityReportsService.create(...activityReportsToCreate);
    }
  }
}
