<div class="details-container" [ngClass]="{ 'timeline-view': timelineView }">
  <h6 *ngIf="!timelineView" class="mb-3 title">
    {{ 'tooltip.subheader.working_regulations' | translate }}
  </h6>
  <ng-container
    *ngIf="!!workingTimeValidations?.length; else emptyStateNoWorkingTime"
  >
    <div
      class="d-flex align-items-center item"
      *ngFor="let item of workingTimeValidations"
    >
      <ng-container
        *ngIf="item.checkName === userApprovalCheck; else otherQualifications"
      >
        <fa-icon
          *ngIf="item.valid"
          class="icon validIcon"
          [icon]="faValidIcon"
        ></fa-icon>
        <fa-icon
          *ngIf="!item.valid"
          class="icon inValidIcon"
          [icon]="faInValidIcon"
        ></fa-icon>
        <p
          class="m-0"
          [ngClass]="timelineView ? 'timeline-item-text' : 'item-text'"
        >
          <ng-container *ngIf="!item.valid; else valid">
            {{
              'enum.shift_validation.' + item.checkName + '.' + item.valid
                | translate
            }}
          </ng-container>
          <ng-template #valid>
            {{
              'enum.shift_validation.' + item.checkName + '.' + item.valid
                | translate
            }}
          </ng-template>
        </p>
      </ng-container>

      <ng-template #otherQualifications>
        <fa-icon
          *ngIf="item.valid"
          class="icon validIcon"
          [icon]="faValidIcon"
        ></fa-icon>
        <fa-icon
          *ngIf="!item.valid"
          class="icon inValidIcon"
          [icon]="faInValidIcon"
        ></fa-icon>
        <p
          class="m-0"
          [ngClass]="timelineView ? 'timeline-item-text' : 'item-text'"
        >
          {{ 'enum.shift_validation.' + item.checkName | translate }}
        </p>
      </ng-template>
    </div>
  </ng-container>

  <ng-template #emptyStateNoWorkingTime>
    {{ 'tooltip.subheader.no_working_time_regulations_deposited' | translate }}
  </ng-template>
</div>
