<div class="form-text d-flex align-items-center mt-2">
  {{ 'general.activities' | translate }}
</div>

<div class="activity-card mb-3">
  @let progress = (progress$ | async) || 0;
  <div
    class="progress-bar"
    [style.transform]="'scaleX(' + progress / 100 + ')'"
    *ngIf="showUndo$ | async"
  ></div>
  <div class="d-flex justify-content-between">
    <div class="px-2 line-height-1">
      <span
        class="activity-text"
        *ngFor="let activity of shiftWithActivities?.activities; let i = index"
      >
        <span class="fw-bold">
          {{ i === 0 ? '' : '- ' }}
          {{ activity.activityCategory.translatedName }}
        </span>
        <span> {{ activity.startDatetime | date : 'HH:mm' }} - </span>
        <span [class.changed-location]="activity.isStartLocationChanged">
          {{ activity.startLocation | locationName }}
        </span>
      </span>
    </div>
    <div class="d-flex align-items-center">
      <button
        nz-button
        nzType="text"
        class="ms-1 p-0"
        nz-tooltip
        [nzTooltipTitle]="'general.undo' | translate"
        *ngIf="showUndo$ | async"
        (click)="handleUndo()"
        (mouseenter)="onUndoHover(true)"
        (mouseleave)="onUndoHover(false)"
      >
        <fa-icon [icon]="faRotateLeft"></fa-icon>
      </button>
      <button
        nz-button
        nzType="text"
        class="ms-2 p-0"
        [disabled]="true"
        nz-tooltip
        [nzTooltipTitle]="'general.coming_soon' | translate"
      >
        <fa-icon [icon]="faPenToSquare"></fa-icon>
      </button>
    </div>
  </div>
</div>
