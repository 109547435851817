@let hasShiftUpdatePermissions = (RoleAction.Update | ablePure :
RolePermissionSubject.Shift | async) ; @let hasCompanyAdminRole
=hasCompanyAdminRole$ | async; @let hasSufficientPermissions =
hasShiftUpdatePermissions && hasCompanyAdminRole && hasAdditionalRolePermission;

<button
  *ngIf="
    shift?.state === ShiftState.AcceptedTimes ||
    shift?.state === ShiftState.SubmittedToPayrollProvider
  "
  nz-button
  nz-tooltip
  [nzTooltipTitle]="
    !hasSufficientPermissions
      ? ('general.forbidden_action' | translate)
      : showIconOnly
      ? ('page.shift_timeline.drawer.shift_form.revert_shift' | translate)
      : ''
  "
  class="fw-normal me-2 w-100"
  (click)="updateShiftState()"
  [disabled]="disabled || !hasSufficientPermissions"
  data-test-id="wilson-revert-shift-button:action-button"
>
  @if(showIconOnly) {
  <fa-icon [icon]="faUndo"></fa-icon>
  } @else {
  <fa-icon class="mx-2" [icon]="faUndo"></fa-icon>
  {{ 'page.shift_timeline.drawer.shift_form.revert_shift' | translate }}

  }
</button>
