@let hasShiftUpdatePermissions = !!(RoleAction.Update | ablePure:
RolePermissionSubject.Shift | async); @let hasServiceUpdatePermissions =
!!(RoleAction.Update | ablePure: RolePermissionSubject.Service | async); @let
isAdminUser = !!(isAdminUser$ | async);
<nz-collapse
  [nzBordered]="false"
  nzGhost
  data-test-id="wilson-collapsible-activities-list:activities-list-collapse"
>
  <nz-collapse-panel
    class="collapse-panel"
    [nzHeader]="'general.activities' | translate"
    [nzActive]="isExpandedOnOpen"
  >
    <div class="d-flex justify-content-between mb-3">
      <nz-segmented
        [ngModel]="selectedActivityFilterIndex"
        [ngModelOptions]="{ standalone: true }"
        [nzOptions]="filterOptions"
        (nzValueChange)="onActivityFilterChange($event)"
      ></nz-segmented>

      <div class="d-flex align-items-center">
        <wilson-activities-setting-button
          [isAutoAdaptActivitiesEnabled]="isAutoAdaptActivitiesEnabled"
          (isAutoAdaptActivitiesEnabledChange)="
            isAutoAdaptActivitiesEnabled = $event
          "
        ></wilson-activities-setting-button>
        <!-- TODO: add this back after BE support activity report deletion in single endpoint - https://menlo1679.atlassian.net/browse/WILP-7918 -->
        <!-- @let isAddActivityDisabled = !hasShiftUpdatePermissions ||
        isShiftAcceptedOrSubmittedToPayrollProviderSignal() ||
        (isShiftStartedSignal() && !isShiftSubmittedSignal()) ||
        (isShiftSubmittedSignal() && !isAdminUser); -->
        @let isAddActivityDisabled = !hasShiftUpdatePermissions ||
        isShiftAcceptedOrSubmittedToPayrollProviderSignal() ||
        (isShiftStartedSignal() && !isShiftSubmittedSignal()) ||
        isShiftSubmittedSignal();

        <button
          class="d-flex align-items-center"
          nz-button
          nzType="default"
          nz-tooltip
          [nzTooltipTitle]="{
          hasShiftUpdatePermissions,
          isShiftAcceptedOrSubmittedToPayrollProvider: isShiftAcceptedOrSubmittedToPayrollProviderSignal(),
          isShiftStarted: isShiftStartedSignal(),
          isShiftSubmitted: isShiftSubmittedSignal(),
          isAdminUser
        } | addActivityForbiddenMessage"
          [disabled]="isAddActivityDisabled"
          [nzLoading]="isSavingSignal()"
          (click)="addActivity()"
        >
          @if (isShiftSubmittedSignal()) {
          <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
          {{
            'page.shift_timeline.shift_drawer.activity.add_reported_activity'
              | translate
          }}
          } @else {
          {{ 'general.add' | translate }}
          }
        </button>
      </div>
    </div>

    <nz-table
      #basicTable
      nzOuterBordered
      [nzData]="activities"
      nzSize="small"
      nzShowPagination="false"
      class="mb-3"
    >
      <thead>
        <tr>
          <th nzWidth="12rem">{{ 'activity.type' | translate }}</th>
          <th nzWidth="3.5rem">
            {{ 'shift_drawer.activity.start_time' | translate }}
          </th>
          <th nzWidth="3.5rem">
            {{ 'shift_drawer.activity.start_date' | translate }}
          </th>
          <th>{{ 'shift_drawer.activity.start_location' | translate }}</th>
          <th nzWidth="3.5rem">
            {{ 'shift_drawer.activity.end_time' | translate }}
          </th>
          <th nzWidth="3.5rem">
            {{ 'shift_drawer.activity.end_date' | translate }}
          </th>
          <th>{{ 'shift_drawer.activity.end_location' | translate }}</th>
          <th nzWidth="2rem"></th>
        </tr>
      </thead>
      <tbody>
        @if (filteredActivities.length > 0) {
        <ng-template [ngTemplateOutlet]="activityTemplate"></ng-template>
        } @else {
        <ng-template [ngTemplateOutlet]="noActivityTemplate"></ng-template>
        }
      </tbody>
    </nz-table>
  </nz-collapse-panel>
</nz-collapse>

<ng-template #activityTemplate>
  <ng-container *rxFor="let activity of filteredActivities">
    <!-- Activity data row -->
    <tr
      [ngClass]="{
        'reported-activity-data-row':
          selectedActivityFilter.value === ActivityFilter.Reported,
        'highlighted-activity': highlightedActivityIdsSignal().includes(
          activity.id
        )
      }"
    >
      <td>
        <!-- Activity type -->
        @let translatedActivityCategory = activity | translateActivityCategory |
        translate;
        <div class="d-flex justify-content-between">
          <span
            class="me-2 text-truncate"
            nz-tooltip
            [nzTooltipTitle]="translatedActivityCategory"
          >
            {{ translatedActivityCategory }}
          </span>
          <nz-tag
            class="rounded-0"
            *ngIf="activity.createdFrom === WilsonApp.Mobile"
          >
            {{
              'page.shift_timeline.shift_drawer.activity.created_by_user'
                | translate
            }}
          </nz-tag>
          <nz-tag
            class="rounded-0"
            nzColor="red"
            *ngIf="activity.operationalStatus === OperationStatus.SkippedByUser"
          >
            {{
              'page.shift_timeline.shift_drawer.activity.skipped' | translate
            }}
          </nz-tag>
        </div>
      </td>
      <td>
        <!-- Start time -->
        {{
          activity
            | activityStartDatetime : selectedActivityFilter.value
            | date : DateTimeFormat.TimeFormat
        }}
      </td>
      <td class="day-cell">
        <!-- Start date -->
        {{
          activity
            | activityStartDatetime : selectedActivityFilter.value
            | date : DateTimeFormat.DayAndMonth
        }}
      </td>
      <td>
        <!-- Start location -->
        @let startLocation = activity | activityStartLocation :
        selectedActivityFilter.value | async; @if (startLocation) { @let
        startLocationLabel = startLocation | locationName;
        <span nz-tooltip [nzTooltipTitle]="startLocationLabel">
          {{ startLocationLabel }}
        </span>
        } @else {
        {{ 'general.n/a' | translate }}
        }
      </td>
      <td>
        <!-- End time -->
        {{
          activity
            | activityEndDatetime : selectedActivityFilter.value
            | date : DateTimeFormat.TimeFormat
        }}
      </td>
      <td class="day-cell">
        <!-- End date -->
        {{
          activity
            | activityEndDatetime : selectedActivityFilter.value
            | date : DateTimeFormat.DayAndMonth
        }}
      </td>

      <td>
        <!-- End location -->
        @let endLocation = activity | activityEndLocation :
        selectedActivityFilter.value | async; @if (endLocation) { @let
        endLocationLabel = endLocation | locationName;
        <span nz-tooltip [nzTooltipTitle]="endLocationLabel">
          {{ endLocationLabel }}
        </span>
        } @else {
        {{ 'general.n/a' | translate }}
        }
      </td>
      <!-- Action cell -->
      <td>
        <div class="d-flex justify-content-end">
          <wilson-delete-activity-button
            [shift]="shift"
            [activity]="activity"
            [hasShiftUpdatePermissions]="hasShiftUpdatePermissions"
            [hasServiceUpdatePermissions]="hasServiceUpdatePermissions"
            [isShiftAcceptedOrSubmittedToPayrollProvider]="
              isShiftAcceptedOrSubmittedToPayrollProviderSignal()
            "
            [isShiftStarted]="isShiftStartedSignal()"
            [isShiftSubmitted]="isShiftSubmittedSignal()"
            [isAdminUser]="isAdminUser"
            [isAutoAdaptActivitiesEnabled]="isAutoAdaptActivitiesEnabled"
            (delete)="deleteActivity($event)"
          ></wilson-delete-activity-button>
        </div>
      </td>
    </tr>
  </ng-container>
</ng-template>

<ng-template #noActivityTemplate>
  <tr>
    <td colspan="8" class="empty-state-cell">
      <div class="d-flex justify-content-center align-items-center">
        <nz-empty [nzNotFoundContent]="null"></nz-empty>
      </div>
    </td>
  </tr>
</ng-template>
