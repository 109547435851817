/* eslint-disable @nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LocationsStoreService } from '@wilson/locations';
import { PipesModule } from '@wilson/pipes';
import { ViaPointsToolTipModule } from '@wilson/shared-components';
import { AgGridModule } from 'ag-grid-angular';
import {
  FormatDistanceStrictPurePipeModule,
  FormatDistanceToNowStrictPurePipeModule,
} from 'ngx-date-fns';
import { AcceptanceStatusRendererComponent } from '../components/ag-grid-renderers/acceptance-status.renderer';
import { HeaderRendererComponent } from '../components/ag-grid-renderers/header.renderer';
import { PresentShiftsRendererComponent } from '../components/ag-grid-renderers/present-shifts.renderer';
import { TitleRendererComponent } from '../components/ag-grid-renderers/title.renderer';
import { TokenIconRendererComponent } from '../components/ag-grid-renderers/token-icon.renderer';
import { WebhookEventsRendererComponent } from '../components/ag-grid-renderers/webhook-events.renderer';
import { AgGridSharedService } from './ag-grid-shared.service';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NgbModule,
    AgGridModule,
    FormatDistanceStrictPurePipeModule,
    ViaPointsToolTipModule,
    PipesModule,
    FormatDistanceToNowStrictPurePipeModule,
    NzPopoverModule,
  ],
  declarations: [
    HeaderRendererComponent,
    TitleRendererComponent,
    AcceptanceStatusRendererComponent,
    PresentShiftsRendererComponent,
    TokenIconRendererComponent,
    WebhookEventsRendererComponent,
  ],
  exports: [
    HeaderRendererComponent,
    TitleRendererComponent,
    AcceptanceStatusRendererComponent,
    PresentShiftsRendererComponent,
    TokenIconRendererComponent,
    WebhookEventsRendererComponent,
  ],
  providers: [AgGridSharedService, LocationsStoreService],
})
export class AgGridSharedModule {}
