import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { FileAccess, FileObject } from '@wilson/interfaces';
import { firstValueFrom, Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';

const fileAccessCacheKey = 'fileAccessCache';

@Injectable()
export class FilesService {
  private cache: Record<string, FileAccess> = {};

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {
    try {
      const storageCache = localStorage.getItem(fileAccessCacheKey);
      if (storageCache && JSON.parse(storageCache)) {
        this.cache = JSON.parse(storageCache);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
    }
  }

  public createFile(file: FileObject) {
    return firstValueFrom(
      this.httpClient
        .put<FileAccess>(`${this.config.host}/files`, {
          fileName: file.name,
          fileKind: file.filekind,
        })
        .pipe(take(1)),
    );
  }

  public uploadFile(data: FileAccess, file: File) {
    return firstValueFrom(
      this.httpClient.put<FileAccess>(`${data.accessUrl}`, file).pipe(take(1)),
    );
  }

  public uploadFiles(data: FileAccess, files: File[]) {
    return firstValueFrom(
      this.httpClient.put<FileAccess>(`${data.accessUrl}`, files).pipe(take(1)),
    );
  }

  public getFileAccess(s3Urn: string): Observable<FileAccess> {
    const encodedUrl = encodeURIComponent(s3Urn);
    return this.httpClient.get<FileAccess>(
      `${this.config.host}/files/${encodedUrl}`,
    );
  }

  public getFileAccessFromCache(s3Urn: string): Observable<FileAccess> {
    return this.cache[s3Urn]
      ? of(this.cache[s3Urn])
      : this.getFileAccess(s3Urn).pipe(
          tap((file) => {
            this.cache[s3Urn] = file;
            localStorage.setItem(
              fileAccessCacheKey,
              JSON.stringify(this.cache),
            );
          }),
        );
  }

  public uncacheFile(s3Urn: string) {
    delete this.cache[s3Urn];
    localStorage.setItem(fileAccessCacheKey, JSON.stringify(this.cache));
  }

  public deleteFile(s3Urn: string): Observable<string> {
    const encodedUrl = encodeURIComponent(s3Urn);
    return this.httpClient.delete<string>(
      `${this.config.host}/files/${encodedUrl}`,
    );
  }

  public clearFileAccessCache() {
    localStorage.removeItem(fileAccessCacheKey);
  }
}
