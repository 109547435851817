import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { ShiftValidationDetails } from '@wilson/interfaces';

@Component({
  selector: 'wilson-shift-logic-details',
  templateUrl: './shift-logic-details.component.html',
  styleUrls: ['./shift-logic-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
})
export class ShiftLogicDetailsComponent {
  public faValidIcon = faCheckCircle;
  public faInValidIcon = faTimesCircle;

  @Input() timelineView = false;
  @Input() shiftLogicValidations: ShiftValidationDetails[] = [];
}
