import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import {
  ShiftQuickCheckValidate,
  ShiftValidationDetails,
} from '@wilson/interfaces';

@Component({
  selector: 'wilson-working-time-details',
  templateUrl: './working-time-details.component.html',
  styleUrls: ['./working-time-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
})
export class WorkingTimeDetailsComponent {
  public faValidIcon = faCheckCircle;
  public faInValidIcon = faTimesCircle;
  public userApprovalCheck = ShiftQuickCheckValidate.UserApprovalCheck;

  @Input() timelineView = false;
  @Input() workingTimeValidations: ShiftValidationDetails[] = [];
}
