<!-- TODO: add this back after BE support activity report deletion in single endpoint - https://menlo1679.atlassian.net/browse/WILP-7918 -->
<!-- @let isDeleteActivityDisabled = !hasShiftUpdatePermissions || (activity.serviceId &&
!hasServiceUpdatePermissions) || isShiftAcceptedOrSubmittedToPayrollProvider ||
shift.activities.length === 1 || (isShiftStarted && !isShiftSubmitted) ||
(isShiftSubmitted && !isAdminUser) || deletingSignal(); -->
@let isDeleteActivityDisabled = !hasShiftUpdatePermissions ||
(activity.serviceId && !hasServiceUpdatePermissions) ||
isShiftAcceptedOrSubmittedToPayrollProvider || shift.activities.length === 1 ||
(isShiftStarted && !isShiftSubmitted) || isShiftSubmitted || deletingSignal();

<button
  class="px-1"
  nz-button
  nzType="text"
  nz-tooltip
  [nzTooltipTitle]="
    activity
      | deleteActivityForbiddenMessage
        : {
            hasShiftUpdatePermissions,
            hasServiceUpdatePermissions,
            isShiftAcceptedOrSubmittedToPayrollProvider,
            isLastActivity: shift.activities.length === 1,
            isShiftStarted,
            isShiftSubmitted,
            isAdminUser,
            serviceTooltipTemplate
          }
  "
  [disabled]="isDeleteActivityDisabled"
  [nzLoading]="deletingSignal()"
  (click)="deleteActivity(activity)"
>
  <fa-icon
    [icon]="activity.serviceId ? faLinkSimpleSlash : faTrashCan"
    *ngIf="!deletingSignal()"
  ></fa-icon>
</button>
<ng-template #serviceTooltipTemplate>
  {{ 'page.shift_detail.activities_table.service' | translate }} -
  <a
    [routerLink]="
      activity.serviceId ? [activity.serviceId | navigateToService] : ''
    "
    target="_blank"
    nz-typography
  >
    {{ activity.service.name }}
  </a>
</ng-template>
