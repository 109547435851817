@if(form){ @let sector= sector$|async; @let hasShiftUpdatePermissions =
(RoleAction.Update | ablePure : RolePermissionSubject.Shift | async); @let
hasServiceUpdatePermissions = RoleAction.Update | ablePure :
RolePermissionSubject.Service | async; @let hasUpdatePermissions =
!!(hasServiceActivity?hasServiceUpdatePermissions && hasShiftUpdatePermissions
:hasShiftUpdatePermissions); @let isShiftStartedSubmittedOrAccepted =
(shiftWithActivities?.state | isShiftSubmittedOrAccepted) ||
shiftWithActivities?.operationalStatus === OperationStatus.Ongoing; @let
toolTipErrorMessage = hasUpdatePermissions ? '' : hasServiceActivity &&
!hasServiceUpdatePermissions ?
('general.shift_with_service_activities.forbidden_action' | translate) :
('general.forbidden_action' | translate) ;
<form [formGroup]="form" class="col-lg-12" *ngIf="form">
  <div class="d-flex gap-2">
    <div class="col-3">
      <div
        [ngClass]="{
          'text-danger':
            form.controls[ShiftContentFormFieldEnum.ShiftStartTime]
            | formControlInvalid
        }"
        class="form-text d-flex align-items-center"
      >
        {{ 'general.start' | translate }}
      </div>
      <nz-form-item class="mb-0">
        <nz-form-control>
          <ng-container
            *ngTemplateOutlet="
              timePickerTemplate;
              context: { controlName: ShiftContentFormFieldEnum.ShiftStartTime }
            "
          >
          </ng-container>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-6">
      <div
        [ngClass]="{
          'text-danger':
            form.controls[ShiftContentFormFieldEnum.ShiftStartLocation]
            | formControlInvalid
        }"
        class="form-text d-flex align-items-center"
      >
        {{ 'general.activity.start.location' | translate }}
      </div>
      <nz-form-item class="right-column mb-0">
        <ng-container
          *ngTemplateOutlet="
            locationTemplate;
            context: {
              locationControlName: ShiftContentFormFieldEnum.ShiftStartLocation
            }
          "
        >
        </ng-container>
      </nz-form-item>
    </div>

    <div class="col-2">
      <wilson-shift-overwrite-all-toggle
        [hasUpdatePermissions]="hasUpdatePermissions"
        [isDisabled]="isShiftStartedSubmittedOrAccepted"
        [errorMessage]="toolTipErrorMessage"
        (overWriteAll)="takeOverAllActivityLocations()"
      ></wilson-shift-overwrite-all-toggle>
    </div>
  </div>

  <div class="d-flex gap-2">
    <div class="col-3 w-45">
      <div
        [ngClass]="{
          'text-danger':
            form.controls[ShiftContentFormFieldEnum.ShiftEndTime]
            | formControlInvalid
        }"
        class="form-text d-flex align-items-center"
      >
        {{ 'general.end' | translate }}
      </div>
      <nz-form-item class="mb-0">
        <nz-form-control>
          <ng-container
            *ngTemplateOutlet="
              timePickerTemplate;
              context: { controlName: ShiftContentFormFieldEnum.ShiftEndTime }
            "
          >
          </ng-container>
        </nz-form-control>
      </nz-form-item>
    </div>
    @if(renderShiftEndLocation){
    <div class="col-9 w-55">
      <div
        [ngClass]="{
          'text-danger':
            form.controls[ShiftContentFormFieldEnum.ShiftStartLocation]
            | formControlInvalid
        }"
        class="form-text d-flex align-items-center"
      >
        {{ 'general.activity.end.location' | translate }}
      </div>
      <nz-form-item class="right-column mb-0">
        <nz-input-group [nzAddOnAfter]="addOnAfterTemplate">
          <ng-container
            *ngTemplateOutlet="
              locationTemplate;
              context: {
                locationControlName: ShiftContentFormFieldEnum.ShiftEndLocation
              }
            "
          >
          </ng-container>
        </nz-input-group>
      </nz-form-item>
    </div>
    }@else{
    <ng-container
      *ngTemplateOutlet="renderShiftEndLocationTemplate"
    ></ng-container>
    }
  </div>
  <ng-template #addOnAfterTemplate>
    <div
      nz-tooltip
      [nzTooltipTitle]="toolTipErrorMessage"
      (click)="
        !(!hasUpdatePermissions || isShiftStartedSubmittedOrAccepted) &&
          resetEndLocation()
      "
      [ngClass]="{
        'addon-after-clickable':
          hasUpdatePermissions && !isShiftStartedSubmittedOrAccepted,
        'disabled-icon':
          !hasUpdatePermissions || isShiftStartedSubmittedOrAccepted
      }"
    >
      <i class="fas fa-times"></i>
    </div>
  </ng-template>

  <ng-template #timePickerTemplate let-controlName="controlName">
    <nz-time-picker
      class="w-45"
      [formControlName]="controlName"
      [nzFormat]="DateTimeFormat.TimeFormat"
      [nzDisabled]="true"
      nz-tooltip
      [nzTooltipTitle]="toolTipErrorMessage"
    ></nz-time-picker>
  </ng-template>

  <ng-template #locationTemplate let-locationControlName="locationControlName">
    <wilson-location-selects
      nz-tooltip
      [nzTooltipTitle]="toolTipErrorMessage"
      [sector]="sector.name"
      [invalid]="
        form.controls[locationControlName]?.touched &&
        !form.controls[locationControlName]?.value
      "
      [readonly]="!hasUpdatePermissions || isShiftStartedSubmittedOrAccepted"
      [ignoreFeatureFlag]="true"
      [selectedLocation]="form.controls[locationControlName].value"
      (locationChange)="
        setLocation({
          location: $event.location,
          locationToFill: locationControlName
        })
      "
      (touched)="form.controls[locationControlName].markAsTouched()"
    ></wilson-location-selects>
  </ng-template>
</form>

<ng-template #renderShiftEndLocationTemplate>
  <div class="col-9 w-55 content-checkbox">
    <label
      nz-tooltip
      nz-checkbox
      [nzTooltipTitle]="toolTipErrorMessage"
      class="right-column mb-0"
      [(ngModel)]="renderShiftEndLocation"
    >
      {{
        'page.shift_timeline.stay_content.drawer.checkbox_description'
          | translate
      }}
    </label>
  </div>
</ng-template>
}

<wilson-shift-activities-content
  [shiftWithActivities]="shiftWithActivities"
  (updateSuccess)="updateSuccess.emit($event)"
></wilson-shift-activities-content>
