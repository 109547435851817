import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { QualificationDetailsComponent } from './components/shift-validations-tooltip/components/qualification-details/qualification-details.component';
import { ShiftLogicDetailsComponent } from './components/shift-validations-tooltip/components/shift-logic-details/shift-logic-details.component';
import { ValidationCategoryRowComponent } from './components/shift-validations-tooltip/components/validation-category-row/validation-category-row.component';
import { WorkingTimeDetailsComponent } from './components/shift-validations-tooltip/components/working-time-details/working-time-details.component';
import { ShiftValidationsTooltipComponent } from './components/shift-validations-tooltip/shift-validations-tooltip.component';

@NgModule({
  declarations: [ShiftValidationsTooltipComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    ValidationCategoryRowComponent,
    QualificationDetailsComponent,
    WorkingTimeDetailsComponent,
    ShiftLogicDetailsComponent,
  ],
  exports: [ShiftValidationsTooltipComponent],
})
export class ShiftValidationsCoreModule {}
