import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { ResolvedUser } from '@wilson/interfaces';
import { Observable } from 'rxjs';
import { ExternalUser } from './shared-users-with-you.service';

@Injectable({
  providedIn: 'root',
})
export class ExternalUsersService extends BackendService<ResolvedUser> {
  protected readonly path = 'partnerships';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }

  getUser(id?: string): Observable<ExternalUser[]> {
    return this.http.get<ExternalUser[]>(
      `${this.config.host}/${this.path}/${id}/shared-users`,
    );
  }
}
