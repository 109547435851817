import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import {
  ActivityCategory,
  GeoLocation,
  ResolvedActivity,
  Sector,
  SplittedActivities,
} from '@wilson/interfaces';
import { SectorState } from '@wilson/sectors';
import { set } from 'date-fns';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { SplitActivityPopupHelperService } from './split-activity-popup-helper.service';
import { SplitActivityDateValidator } from './validators/split-activity-date.validator';
import { SplitActivityTimeDurationValidator } from './validators/split-activity-time-duration.validator';
import { SplitActivityTimeValidator } from './validators/split-activity-time.validator';

interface InputData {
  activity: ResolvedActivity;
  activityCategories: ActivityCategory[];
  preFilledForm: boolean;
}

@Component({
  selector: 'wilson-split-activity-popup',
  templateUrl: './split-activity-popup.component.html',
  styleUrls: ['./split-activity-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitActivityPopupComponent implements OnInit {
  @Input() activity!: ResolvedActivity; // actually ResolvedActivity | ActivityTemplate
  @Input() activityCategories: ActivityCategory[] = [];
  @Input() preFilledForm = false;

  public sector!: Sector;
  public form = new UntypedFormGroup({
    location: new FormControl(null, [Validators.required]),
    date: new FormControl(null, [Validators.required]),
    time: new FormControl(null, [Validators.required]),
    insertBreak: new FormControl(false),
  });

  constructor(
    private readonly store: Store,
    private readonly splitActivityPopupHelperService: SplitActivityPopupHelperService,
    private readonly nzModalRef: NzModalRef<SplitActivityPopupComponent>,
    @Inject(NZ_MODAL_DATA) private data: InputData,
  ) {}

  ngOnInit(): void {
    this.activity = this.data.activity;
    this.activityCategories = this.data.activityCategories;
    this.preFilledForm = this.data.preFilledForm;

    const sectors = this.store.selectSnapshot<Sector[]>(SectorState.sectors);
    this.sector = sectors[0];
    if (this.activity) {
      this.form.controls['date'].addValidators([
        SplitActivityDateValidator(
          this.activity.startDatetime,
          this.activity.endDatetime,
        ),
      ]);
      this.form.controls['time'].addValidators([
        SplitActivityTimeValidator(
          this.activity.startDatetime,
          this.activity.endDatetime,
        ),
      ]);

      this.form.patchValue({
        date: new Date(this.activity.startDatetime),
      });

      if (this.preFilledForm) {
        this.setPreFillValues();
      }
    }
  }

  private setPreFillValues(): void {
    const activityMidpointInDuration = new Date(
      (new Date(this.activity.startDatetime).getTime() +
        new Date(this.activity.endDatetime).getTime()) /
        2,
    );
    this.form.patchValue({
      date: activityMidpointInDuration,
      time: activityMidpointInDuration,
      location: this.activity.startLocation,
      insertBreak: true,
    });
    this.setInsertBreak();
    this.form.patchValue({
      breakTime: set(new Date(), {
        hours: 0,
        minutes: 30,
      }),
    });
    this.form.markAllAsTouched();
    this.form.markAsDirty();
  }

  public setLocation(location: GeoLocation): void {
    this.form.controls['location'].setValue(location ?? null);
  }

  public setInsertBreak(): void {
    if (this.form.controls['insertBreak'].value) {
      const pauseCategory = this.activityCategories.find(
        (category) => category.id === 'c70aac31-b568-4c6f-b580-e26b53770c48',
      );
      this.form.addControl(
        'activityCategory',
        new FormControl(pauseCategory ?? null, Validators.required),
      );
      this.form.addControl(
        'breakLocation',
        new FormControl(
          this.form.controls['location'].value,
          Validators.required,
        ),
      );
      this.form.addControl(
        'breakTime',
        new FormControl(null, [Validators.required]),
      );
      this.form.addValidators([
        SplitActivityTimeDurationValidator(this.activity.endDatetime),
      ]);
    } else {
      this.form.removeValidators([
        SplitActivityTimeDurationValidator(this.activity.endDatetime),
      ]);
      this.form.removeControl('activityCategory');
      this.form.removeControl('breakLocation');
      this.form.removeControl('breakTime');
    }
  }

  public resetTime(): void {
    this.form.controls['time'].setValue(null);
  }

  public dismiss(): void {
    this.nzModalRef.destroy();
  }

  splitActivity(): void {
    this.nzModalRef.close(this.generateSplittedActivities());
  }

  generateSplittedActivities(): SplittedActivities {
    const formValue = this.form.value;
    const splittedActivities: SplittedActivities = {
      existingActivity: {
        ...this.activity,
        endDatetime: this.splitActivityPopupHelperService.getISODateTime(
          formValue.date,
          formValue.time,
        ),
        endLocation: formValue.location,
        endLocationId: formValue.location?.id ?? null,
      },
      newActivities: this.splitActivityPopupHelperService.generateNewActivities(
        this.form.value,
        this.activity,
      ),
    };
    return splittedActivities;
  }
}
