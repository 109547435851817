import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPenToSquare, faRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { captureException } from '@sentry/angular';
import { ActivitiesGateway } from '@wilson/api/gateway';
import {
  ActivityWithCategoryAndLocationsAndReportedDetails,
  Shift,
} from '@wilson/interfaces';
import { PipesModule } from '@wilson/pipes';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { AnimationHelperService } from './animation-helper.service';

interface ActivityWithAnimation
  extends ActivityWithCategoryAndLocationsAndReportedDetails {
  isChanged?: boolean;
  isStartLocationChanged?: boolean;
}

@Component({
  selector: 'wilson-shift-activities-content',
  templateUrl: './shift-activities-content.component.html',
  styleUrls: ['./shift-activities-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzButtonModule,
    FontAwesomeModule,
    NzToolTipModule,
    PipesModule,
  ],
  providers: [AnimationHelperService],
})
export class ShiftActivitiesContentComponent implements OnChanges, OnDestroy {
  @Input({ required: true }) shiftWithActivities:
    | (Shift & { id: string } & {
        activities: ActivityWithAnimation[];
      })
    | null = null;
  @Output() updateSuccess = new EventEmitter<boolean>();

  protected faRotateLeft = faRotateLeft;
  protected faPenToSquare = faPenToSquare;
  protected showUndo$ = this.animationHelperService.showUndo$;
  protected progress$ = this.animationHelperService.progress$;

  private previousLocations = new Map<string, { start: string; end: string }>();
  private originalLocations = new Map<string, { start: string; end: string }>();

  constructor(
    private readonly animationHelperService: AnimationHelperService,
    private readonly activitiesGateway: ActivitiesGateway,
    private readonly translate: TranslateService,
    private readonly nzMessageService: NzMessageService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['shiftWithActivities']?.currentValue) {
      if (
        changes['shiftWithActivities'].previousValue?.id !==
        changes['shiftWithActivities'].currentValue.id
      ) {
        this.previousLocations.clear();
        this.originalLocations.clear();
      }
      this.detectLocationChanges();
    }
  }

  private detectLocationChanges(): void {
    if (!this.shiftWithActivities?.activities) {
      this.nzMessageService.error(
        this.translate.instant('general.something_went_wrong'),
      );
      return;
    } else {
      let hasChanges = false;
      const currentLocations = new Map<
        string,
        { start: string; end: string }
      >();

      this.shiftWithActivities.activities.forEach((activity) => {
        const previousLocation = this.previousLocations.get(activity.id);
        currentLocations.set(activity.id, {
          start: activity.startLocationId,
          end: activity.endLocationId,
        });

        const startLocationChanged =
          previousLocation &&
          previousLocation.start !== activity.startLocationId;
        const endLocationChanged =
          previousLocation && previousLocation.end !== activity.endLocationId;

        if (startLocationChanged || endLocationChanged) {
          if (!this.originalLocations.has(activity.id)) {
            this.originalLocations.set(activity.id, {
              start: previousLocation.start,
              end: previousLocation.end,
            });
          }
          (activity as ActivityWithAnimation).isChanged = true;
          (activity as ActivityWithAnimation).isStartLocationChanged =
            startLocationChanged;
          hasChanges = true;
        } else {
          (activity as ActivityWithAnimation).isChanged = false;
          (activity as ActivityWithAnimation).isStartLocationChanged = false;
        }
      });

      this.previousLocations = currentLocations;

      if (hasChanges) {
        this.animationHelperService.startAnimation(() => this.resetState());
      }
    }
  }

  protected onUndoHover(isHovering: boolean): void {
    if (isHovering) {
      this.animationHelperService.pauseAnimation();
    } else {
      this.animationHelperService.resumeAnimation(() => this.resetState());
    }
  }

  protected async handleUndo(): Promise<void> {
    const changedActivities = this.shiftWithActivities?.activities?.filter(
      (activity) => (activity as ActivityWithAnimation).isChanged,
    );
    if (changedActivities?.length) {
      try {
        await Promise.all(
          changedActivities.map((activity) => {
            const originalLocation = this.originalLocations.get(activity.id);
            return this.activitiesGateway.updateActivity({
              id: activity.id,
              startLocationId: originalLocation?.start,
              endLocationId: originalLocation?.end,
            });
          }),
        );
        this.previousLocations.clear();
        this.originalLocations.clear();
        this.updateSuccess.emit(true);
      } catch (error) {
        this.nzMessageService.error(this.translate.instant('general.error'));
        captureException(error);
      }
    }
    this.resetState();
  }

  private resetState(): void {
    this.animationHelperService.resetAnimation();
    if (this.shiftWithActivities?.activities) {
      this.shiftWithActivities.activities.forEach((activity) => {
        (activity as ActivityWithAnimation).isChanged = false;
        (activity as ActivityWithAnimation).isStartLocationChanged = false;
      });
    }
  }

  ngOnDestroy(): void {
    this.animationHelperService.resetAnimation();
  }
}
