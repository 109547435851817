import { Pipe, PipeTransform, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Activity } from '@wilson/interfaces';
import { NzTSType } from 'ng-zorro-antd/core/types';

@Pipe({
  name: 'deleteActivityForbiddenMessage',
  standalone: true,
})
export class DeleteActivityForbiddenMessagePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(
    activity: Activity,
    {
      hasShiftUpdatePermissions,
      hasServiceUpdatePermissions,
      isShiftAcceptedOrSubmittedToPayrollProvider,
      isLastActivity,
      isShiftStarted,
      isShiftSubmitted,
      isAdminUser,
      serviceTooltipTemplate,
    }: {
      hasShiftUpdatePermissions: boolean;
      hasServiceUpdatePermissions: boolean;
      isShiftAcceptedOrSubmittedToPayrollProvider: boolean;
      isLastActivity: boolean;
      isShiftStarted: boolean;
      isShiftSubmitted: boolean;
      isAdminUser: boolean;
      serviceTooltipTemplate: TemplateRef<void>;
    },
  ): NzTSType {
    if (
      !hasShiftUpdatePermissions ||
      (activity.serviceId && !hasServiceUpdatePermissions)
    ) {
      return this.translateService.instant('general.forbidden_action');
    } else if (isShiftAcceptedOrSubmittedToPayrollProvider) {
      return this.translateService.instant(
        'page.shift_timeline.shift_drawer.activity.can_not_add_activity',
      );
    } else if (isLastActivity) {
      return this.translateService.instant(
        'page.shift_timeline.shift_drawer.activity.at_least_one_activity',
      );
    } else if (isShiftStarted && !isShiftSubmitted) {
      return this.translateService.instant(
        'page.shift_timeline.shift_drawer.activity.shift_started',
      );
    } else if (isShiftSubmitted) {
      //TODO: "isShiftSubmitted && !isAdminUser" add this case back after BE support activity report deletion in single endpoint - https://menlo1679.atlassian.net/browse/WILP-7918
      //   return this.translateService.instant(
      //     'page.shift_timeline.shift_drawer.activity.only_admin_can_do_this_action',
      //   );
      return this.translateService.instant(
        isAdminUser
          ? 'page.shift_timeline.shift_drawer.activity.only_admin_can_do_this_action_on_shift_detail_page'
          : 'page.shift_timeline.shift_drawer.activity.only_admin_can_do_this_action',
      );
    } else if (activity.serviceId) {
      return serviceTooltipTemplate;
    } else {
      return '';
    }
  }
}
