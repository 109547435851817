import { QualificationV2 } from './QualificationV2';
import { UserQualificationV2Attachment } from './UserQualificationV2Attachment';
import { UserQualificationV2History } from './UserQualificationV2History';

export interface UserQualificationV2 {
  id: string;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  validFrom: string;
  validTill: string;
  confirmedByUser: boolean;
  acquiredAt: string;
  notes: string | null;
  qualificationId: string;
  userId: string;
  createdById: string;
  updatedById: string | null;
  deletedById: string | null;
  status: UserQualificationV2Status;
  qualification?: QualificationV2;
}

export type UserQualificationV2WithQ = UserQualificationV2WithQualification;
export type UserQualificationV2WithQualification = UserQualificationV2 & {
  qualification: QualificationV2;
};

export type UserQualificationV2WithRelation = UserQualificationV2 & {
  qualification?: QualificationV2;
  userQualificationHistories?: UserQualificationV2History[];
  userQualificationAttachments?: UserQualificationV2Attachment[];
};

export enum UserQualificationV2Status {
  valid = 'valid',
  expiresSoon = 'expiring',
  expired = 'expired',
}
