import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  faBadgeCheck,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';
import {
  QualificationName,
  ShiftValidationDetails,
  ValidationCheckCategory,
} from '@wilson/interfaces';

@Component({
  selector: 'wilson-shift-validations-tooltip',
  templateUrl: './shift-validations-tooltip.component.html',
  styleUrls: ['./shift-validations-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftValidationsTooltipComponent implements OnInit {
  constructor(private cdRef: ChangeDetectorRef) {}

  public ValidationCheckCategory = ValidationCheckCategory;
  public faValidationTitleIcon = faBadgeCheck;
  public faValidIcon = faCheckCircle;
  public faInValidIcon = faTimesCircle;
  public selectedCategory: ValidationCheckCategory | null = null;
  public areQualificationsSufficient: boolean | undefined = true;
  public areWorkingTimeRulesSufficient: boolean | undefined = true;
  public areShiftLogicSufficient: boolean | undefined = true;
  public areProfessionsSufficient: boolean | undefined = true;

  @Input() header = '';
  @Input() qualificationNames: QualificationName[] | null = [];
  @Input() professionValidations: ShiftValidationDetails[] | null = [];
  @Input() workingTimeValidations: ShiftValidationDetails[] | null | undefined =
    [];
  @Input() shiftLogicValidations: ShiftValidationDetails[] | null | undefined =
    [];
  @Input() noValidationFound = false;
  @Output() expanded = new EventEmitter<() => void>();

  ngOnInit(): void {
    this.areQualificationsSufficient = this.qualificationNames?.every(
      (validation) => validation.valid,
    );
    this.areWorkingTimeRulesSufficient = this.workingTimeValidations?.every(
      (validation: ShiftValidationDetails) => validation.valid,
    );

    this.areShiftLogicSufficient = this.shiftLogicValidations?.every(
      (validation) => validation.valid,
    );

    this.areProfessionsSufficient = this.professionValidations?.every(
      (validation) => validation.valid,
    );
  }

  setSelectedCategory(category: ValidationCheckCategory): void {
    this.selectedCategory = category;
    this.cdRef.detectChanges();
    this.expanded.emit();
  }
}
