import { Injectable } from '@angular/core';
import { activitiesCompareFn } from '@wilson/activities/util';
import {
  Activity,
  PartialLocation,
  Shift,
  UnassignedShift,
  WithPreparedAttributes,
} from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ShiftTimelineDataTransformerService {
  convertShiftToShiftWithoutActivities<
    T extends Shift &
      WithPreparedAttributes & {
        id: string;
        activities: Activity[];
      },
  >(shift: T): Omit<T, 'activities'> {
    const { activities, ...shiftWithoutActivities } = shift;
    // We do not want to modify the original data.
    const sortedActivities = [...activities].sort(activitiesCompareFn);
    const firstActivity = sortedActivities[0];
    const lastActivity = sortedActivities[sortedActivities.length - 1];
    return {
      ...shiftWithoutActivities,
      startDatetime: firstActivity.startDatetime,
      endDatetime: lastActivity.endDatetime,
      startLocationId: firstActivity.startLocationId as string,
      endLocationId: lastActivity.endLocationId as string,
      startLocation: {
        name: firstActivity.startLocation?.name || '',
        locationCode: firstActivity.startLocation?.locationCode || '',
      },
      endLocation: {
        name: lastActivity.endLocation?.name || '',
        locationCode: lastActivity.endLocation?.locationCode || '',
      },
    };
  }

  convertToUnassignedShift(
    shift: Omit<Shift, 'activities'> & WithPreparedAttributes & { id: string },
  ): UnassignedShift {
    let reportedStartLocation: PartialLocation | null;
    if (shift.reportedStartLocationId) {
      reportedStartLocation = {
        id: shift.reportedStartLocationId,
        locationCode: shift.reportedStartLocation?.locationCode ?? '',
        name: shift.reportedStartLocation?.name ?? '',
      };
    } else {
      reportedStartLocation = null;
    }

    let reportedEndLocation: PartialLocation | null;
    if (shift.reportedEndLocationId) {
      reportedEndLocation = {
        id: shift.reportedEndLocationId,
        locationCode: shift.reportedEndLocation?.locationCode ?? '',
        name: shift.reportedEndLocation?.name ?? '',
      };
    } else {
      reportedEndLocation = null;
    }
    return {
      id: shift.id,
      name: shift.name,
      shiftCategoryId: shift.shiftCategoryId,
      startDatetime: shift.startDatetime,
      endDatetime: shift.endDatetime,
      startLocationId: shift.startLocationId,
      endLocationId: shift.endLocationId,
      reportedStartLocationId: shift.reportedStartLocationId ?? null,
      reportedEndLocationId: shift.reportedEndLocationId ?? null,
      startLocation: {
        id: shift.startLocationId,
        locationCode: shift.startLocation.locationCode,
        name: shift.startLocation.name,
      },
      endLocation: {
        id: shift.endLocationId,
        locationCode: shift.endLocation.locationCode,
        name: shift.endLocation.name,
      },
      deviatedStartDatetime: shift.deviatedStartDatetime,
      deviatedEndDatetime: shift.deviatedEndDatetime,
      reportedStartDatetime: shift.reportedStartDatetime,
      reportedEndDatetime: shift.reportedEndDatetime,
      reportedStartLocation,
      reportedEndLocation,
      labels: shift.labels || [],
      projectId: shift.projectId || '',
      activities: [], // we need to handle activities separately
      comment: shift.comment,
      organizationalUnitId: shift.organizationalUnitId,
      publicationStatus: shift.publicationStatus,
      startDate: shift.startDate,
      state: shift.state,
      userId: null,
      operationalStatus: shift.operationalStatus,
      priority: shift?.priority ?? null,
      ignoreStayNecessary: shift.ignoreStayNecessary,
    };
  }
}
