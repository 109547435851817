import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import {
  FormsModule as AngularFormsModule,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { captureException } from '@sentry/capacitor';
import { AuthorizationModule } from '@wilson/authorization';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import {
  EndDateTimeValidator,
  FormsModule,
  StartDateTimeValidator,
} from '@wilson/forms';
import {
  Activity,
  ActivityWithCategoryAndLocationsAndReportedDetails,
  GeoLocation,
  OperationStatus,
  RoleAction,
  RolePermissionSubject,
  Shift,
} from '@wilson/interfaces';
import { LocationSelectsComponent } from '@wilson/non-domain-specific/activities-helpers/components';
import { ShiftOverwriteAllToggleComponent } from '@wilson/non-domain-specific/stays-form-and-drawer';
import { PipesModule } from '@wilson/pipes';
import { SectorState } from '@wilson/sectors';
import { ShiftsService } from '@wilson/shifts';
import { DateTimeFormat } from '@wilson/utils';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { Observable, of } from 'rxjs';
import { IsShiftSubmittedOrAcceptedPipe } from '../../pipes/is-shift-submitted-or-accepted/is-shift-submitted-or-accepted.pipe';
import { ShiftActivitiesContentComponent } from '../shift-activities-content/shift-activities-content.component';

export type ShiftContentForm = FormGroup<ShiftContentFormControls>;

export type ShiftContentFormControls = {
  [Property in keyof ShiftContentDrawerFormValue]: FormControl<
    ShiftContentDrawerFormValue[Property]
  >;
};

export enum ShiftContentFormFieldEnum {
  OverWriteAll = 'isOverNightStayRequired',
  ShiftStartTime = 'shiftStartTime',
  ShiftEndTime = 'shiftEndTime',
  ShiftStartLocation = 'shiftStartLocation',
  ShiftEndLocation = 'shiftEndTimeLocation',
}
export interface ShiftContentDrawerFormValue {
  [ShiftContentFormFieldEnum.OverWriteAll]: boolean | null;
  [ShiftContentFormFieldEnum.ShiftStartTime]: Date | null;
  [ShiftContentFormFieldEnum.ShiftEndTime]: Date | null;
  [ShiftContentFormFieldEnum.ShiftStartLocation]:
    | GeoLocation
    | null
    | undefined;
  [ShiftContentFormFieldEnum.ShiftEndLocation]: GeoLocation | null | undefined;
}

@Component({
  selector: 'wilson-shift-content',
  templateUrl: './shift-content.component.html',
  styleUrls: ['./shift-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NzSwitchModule,
    FormsModule,
    AngularFormsModule,
    FeatureFlagsModule,
    CommonModule,
    TranslateModule,
    NzSelectModule,
    AuthorizationModule,
    NzToolTipModule,
    NzFormModule,
    ReactiveFormsModule,
    PipesModule,
    NzTimePickerModule,
    LocationSelectsComponent,
    ShiftOverwriteAllToggleComponent,
    NzButtonModule,
    NzCheckboxModule,
    IsShiftSubmittedOrAcceptedPipe,
    NzInputModule,
    ShiftActivitiesContentComponent,
  ],
})
export class ShiftContentComponent implements OnChanges {
  @Input({ required: true }) shiftWithActivities:
    | (Shift & { id: string } & {
        activities: ActivityWithCategoryAndLocationsAndReportedDetails[];
      })
    | null = null;

  @Output() isShiftUpdateOperationOngoing = new EventEmitter<boolean>();
  @Output() updateSuccess = new EventEmitter<boolean>();
  private readonly store = inject(Store);
  private readonly nzMessageService = inject(NzMessageService);
  private translateService = inject(TranslateService);
  private shiftsService = inject(ShiftsService);

  private shiftStartTimeControl = new FormControl(new Date(), [
    StartDateTimeValidator(ShiftContentFormFieldEnum.ShiftStartTime),
    Validators.required,
  ]);

  private shiftEndTimeControl = new FormControl(new Date(), [
    EndDateTimeValidator(ShiftContentFormFieldEnum.ShiftEndTime),
    Validators.required,
  ]);

  protected formControls: ShiftContentFormControls = {
    [ShiftContentFormFieldEnum.OverWriteAll]: new FormControl<boolean>(false, [
      Validators.required,
    ]),
    [ShiftContentFormFieldEnum.ShiftStartTime]: this.shiftStartTimeControl,
    [ShiftContentFormFieldEnum.ShiftEndTime]: this.shiftEndTimeControl,
    [ShiftContentFormFieldEnum.ShiftStartLocation]: new FormControl<
      GeoLocation | null | undefined
    >(null, [Validators.required]),
    [ShiftContentFormFieldEnum.ShiftEndLocation]: new FormControl<
      GeoLocation | null | undefined
    >(null, [Validators.required]),
  };

  protected form: ShiftContentForm = new FormGroup(this.formControls);
  protected DateTimeFormat = DateTimeFormat;
  protected ShiftContentFormFieldEnum = ShiftContentFormFieldEnum;
  protected renderShiftEndLocation = false;
  protected sector$ = this.store.select(SectorState.firstSector);
  protected disableClearButtonSignal = signal(false);
  protected hasUpdatePermissions$: Observable<boolean> = of(false);
  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  protected hasServiceActivity = false;
  protected OperationStatus = OperationStatus;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['shiftWithActivities'] && this.shiftWithActivities) {
      const shiftActivities = this.shiftWithActivities.activities;

      this.hasServiceActivity = shiftActivities.some(
        (activity) => activity.serviceId,
      );
      this.updateRenderShiftEndLocation(shiftActivities);
      this.form.patchValue(this.preparePrefillObject(shiftActivities));
    }
  }

  protected async setLocation(value: {
    location: GeoLocation | null;
    locationToFill:
      | ShiftContentFormFieldEnum.ShiftStartLocation
      | ShiftContentFormFieldEnum.ShiftEndLocation;
  }): Promise<void> {
    if (value.location) {
      this.disableClearButtonSignal.set(false);
      this.form.get(value.locationToFill)?.setValue(value.location);
      this.form.markAsDirty();
      const activities = this.shiftWithActivities.activities;
      await this.updateActivityLocation(value, activities);
    } else {
      this.disableClearButtonSignal.set(true);
    }
  }

  protected resetEndLocation(): void {
    this.renderShiftEndLocation = false;

    const { controls } = this.form;
    const startLocation =
      controls[ShiftContentFormFieldEnum.ShiftStartLocation].value;
    const endLocation =
      controls[ShiftContentFormFieldEnum.ShiftEndLocation].value;

    const areLocationsDifferent = startLocation.id !== endLocation.id;
    if (areLocationsDifferent) {
      this.setLocation({
        location: startLocation,
        locationToFill: ShiftContentFormFieldEnum.ShiftEndLocation,
      });
    }
  }

  private updateRenderShiftEndLocation(shiftActivities: Activity[]): void {
    const shiftHasDifferentStartAndEndLocations =
      shiftActivities[0].startLocation.id !==
      shiftActivities[shiftActivities.length - 1].endLocation.id;
    if (shiftHasDifferentStartAndEndLocations) {
      this.renderShiftEndLocation = true;
    }
  }

  private preparePrefillObject(
    value: Activity[] | null,
  ): Partial<ShiftContentDrawerFormValue> | undefined {
    if (value) {
      const prefillObject: Partial<ShiftContentDrawerFormValue> = {
        [ShiftContentFormFieldEnum.ShiftStartTime]: new Date(
          value[0].startDatetime,
        ),
        [ShiftContentFormFieldEnum.ShiftEndTime]: new Date(
          value[value.length - 1].endDatetime,
        ),
        [ShiftContentFormFieldEnum.ShiftStartLocation]: value[0].startLocation,
        [ShiftContentFormFieldEnum.ShiftEndLocation]:
          value[value.length - 1].endLocation,
      };

      return prefillObject;
    } else {
      this.nzMessageService.error(
        this.translateService.instant('general.error'),
      );
      return undefined;
    }
  }

  private async updateActivityLocation(
    value: {
      location: GeoLocation | null;
      locationToFill:
        | ShiftContentFormFieldEnum.ShiftStartLocation
        | ShiftContentFormFieldEnum.ShiftEndLocation;
    },
    activities: Activity[],
  ): Promise<void> {
    if (value.location && activities.length) {
      this.isShiftUpdateOperationOngoing.emit(true);
      let updatedActivity: Activity;

      switch (value.locationToFill) {
        case ShiftContentFormFieldEnum.ShiftStartLocation:
          updatedActivity = {
            ...activities[0],
            startLocationId: value.location.id as string,
            startLocation: value.location,
          };
          break;

        case ShiftContentFormFieldEnum.ShiftEndLocation:
          updatedActivity = {
            ...activities[activities.length - 1],
            endLocationId: value.location.id as string,
            endLocation: value.location,
          };
          break;

        default:
          return;
      }

      try {
        await this.shiftsService.update({
          ...this.shiftWithActivities,
          activities: [...activities, updatedActivity],
        });
        this.updateSuccess.emit();
      } catch (error) {
        console.error('Error updating shift:', error);
        captureException(error);
        this.nzMessageService.error(
          this.translateService.instant('general.error'),
        );
      } finally {
        this.isShiftUpdateOperationOngoing.emit(false);
      }
    } else {
      this.nzMessageService.error(
        this.translateService.instant('general.error'),
      );
    }
  }

  protected async takeOverAllActivityLocations(): Promise<void> {
    const startLocation: GeoLocation =
      this.form.controls['shiftStartLocation'].value;

    const allSameBeforeUpdate = this.shiftWithActivities.activities.every(
      (activity) =>
        activity.startLocationId === startLocation.id &&
        activity.endLocationId === startLocation.id,
    );
    if (!allSameBeforeUpdate) {
      this.isShiftUpdateOperationOngoing.emit(true);
      const updatedShiftActivities: Activity[] =
        this.shiftWithActivities.activities.map((activity) =>
          activity.startLocationId === startLocation.id &&
          activity.endLocationId === startLocation.id
            ? activity
            : {
                ...activity,
                startLocationId: startLocation.id,
                startLocation: startLocation,
                endLocationId: startLocation.id,
                endLocation: startLocation,
              },
        );

      try {
        await this.shiftsService.update({
          ...this.shiftWithActivities,
          activities: updatedShiftActivities,
        });
        this.updateSuccess.emit();
      } catch (error) {
        console.error('Error updating shift:', error);
        captureException(error);
        this.nzMessageService.error(
          this.translateService.instant('general.error'),
        );
      } finally {
        this.isShiftUpdateOperationOngoing.emit(false);
      }
    }
  }
}
