import { Inject, Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { StateOperator, patch } from '@ngxs/store/operators';
import { AccountService } from '@wilson/account';
import { UserTimelinesGateway } from '@wilson/api/gateway';
import { AuthState } from '@wilson/auth/core';
import { FeaturePurePipe } from '@wilson/authorization';
import {
  Absence,
  Activity,
  ActivityRow,
  ActivityWithCategoryAndLocationsAndReportedDetails,
  AssignmentStack,
  DetermineAssignmentsOverlapWebWorker,
  DetermineAssignmentsOverlapWorkerWrapper,
  FeatureName,
  GroupedUserDataV2,
  PublicationStatus,
  Shift,
  ShiftValidationDetails,
  Stay,
  TimelineFetchState,
  TimelineShift,
  TimelineUserDTO,
  UserOvertime,
  UserTimelines,
  UserTimelinesWithoutActivitiesPayload,
  UserValidationsFetchState,
  WithPreparedAttributes,
} from '@wilson/interfaces';
import { LocationsStoreService } from '@wilson/locations';
import { ShiftTimelinePreferredSettingsState } from '@wilson/preferred-settings/state';
import { getTimeZoneCorrectedDateRange } from '@wilson/utils';
import {
  eachDayOfInterval,
  endOfDay,
  formatISO,
  startOfDay,
  startOfYear,
} from 'date-fns';
import { cloneDeep } from 'lodash';
import {
  Observable,
  Subject,
  catchError,
  combineLatest,
  firstValueFrom,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {
  AbsenceId,
  ShiftId,
  ShiftTimelineDataStateModel,
  StayId,
  UserId,
} from '../interfaces';
import { ActivitiesStateLoaderService } from '../services/activities-state-loader.service';
import { shiftTimelineDefaultData } from '../shift-timeline-default-data';
import { ShiftTimelineFilterStateService } from '../shift-timeline-filters/shift-timeline-filter-state.service';
import { TimelineDataUtilityState } from '../timeline-data-utility/timeline-data-utility.state';
import { TimelineKpisState } from '../timeline-kpis/timeline-kpis.state';
import {
  ChangeNumberOfDaysInDatepicker,
  ClearAssignmentsFromAllUsers,
  ClearDeterminedShiftActivities,
  ClearTimelineStays,
  DeleteShiftDeterminedActivities,
  HighlightSelectedServices,
  HighlightSelectedShifts,
  HighlightSelectedStays,
  PinUsersOnTimeline,
  RemoveTimelinesNonMatchingElements,
  ResetTimelineData,
  SetMainTimelineHeightPx,
  SetVisibleDateRange,
  TimelineCreateNewShiftV2,
  TimelineFetchUserValidations,
  TimelineFetchUsers,
  TimelineFetchUsersOvertimes,
  TimelineFetchUsersWithFilters,
  TimelineLoadActivitiesForShifts,
  TimelinePublishShifts,
  TimelineRemoveAbsence,
  TimelineRemoveActivityFromShiftV2,
  TimelineRemoveShiftFromUserV2,
  TimelineRemoveStay,
  TimelineSetCurrentDate,
  TimelineSetDate,
  TimelineUpdateAbsence,
  TimelineUpdateAssignmentsV2,
  TimelineUpdateShiftV2,
  TimelineUpdateShiftValidations,
  TimelineUpdateUserStays,
  TimelineUpsertActivityV2,
  TimelineUpsertNewAbsence,
  TimelineUpsertNewShiftV2,
  TimelineUpsertNewStay,
} from './shift-timeline-data.actions';
import {
  clearLoadingUserIds,
  getDataInRange,
  reduceIncludingUnmatchingElements,
  removeInPlaceFromUserInDateToAssignmentIdRecord,
  updateInPlaceDateToAbsenceIdRecord,
  updateInPlaceDateToShiftIdRecord,
  updateInPlaceDateToStayIdRecord,
  updateRowOverlapDataSyncOrAssignmentsStack,
  updateRowOverlapOrAssignmentStacks,
  zipRecords,
} from './shift-timeline-data.helpers';
import { ShiftValidationsHelperService } from './shift-validations.helper.service';
import {
  DataCacheRecords,
  filterAndUpdateDataRecords,
  isUserAndDateInRecord,
  trackAndMaintainMaxRequestCount,
} from './validation-overlap-tracking.helper.fn';

@State({
  name: 'shiftTimelineData',
  defaults: shiftTimelineDefaultData,
  children: [TimelineDataUtilityState, TimelineKpisState],
})
@Injectable()
export class ShiftTimelineDataState {
  private unsubFetchUsersOvertimesRequestSubject = new Subject();
  private fetchUserTimelinesRequestSubject: Subject<unknown>[] = [];

  constructor(
    private store: Store,
    private accountService: AccountService,
    private featurePurePipe: FeaturePurePipe,
    private userTimelineGateway: UserTimelinesGateway,
    private readonly activitiesStateLoaderService: ActivitiesStateLoaderService,
    private readonly shiftValidationsHelperService: ShiftValidationsHelperService,
    @Inject(DetermineAssignmentsOverlapWebWorker)
    protected readonly determineAssignmentsOverlapWorkerWrapper: DetermineAssignmentsOverlapWorkerWrapper,
    private readonly shiftTimelineFilterStateService: ShiftTimelineFilterStateService,
    private readonly locationsStoreService: LocationsStoreService,
  ) {}

  @Selector()
  static currentDate(state: ShiftTimelineDataStateModel): string | null {
    return state.currentDate;
  }

  @Selector()
  static visibleDateRange(state: ShiftTimelineDataStateModel): {
    start: Date | null;
    end: Date | null;
  } {
    return state.visibleDateRange;
  }

  @Selector()
  static timelineUsers(state: ShiftTimelineDataStateModel): UserTimelines[] {
    return Object.values(state.usersDictionary);
  }

  @Selector()
  static timelineUserIds(
    state: ShiftTimelineDataStateModel,
  ): (string | undefined)[] {
    return Object.keys(state.usersDictionary);
  }

  @Selector()
  static loadingActivitiesForShiftIds(
    state: ShiftTimelineDataStateModel,
  ): string[] {
    return state.loadingActivitiesForShiftIds;
  }

  @Selector()
  static determinedShiftActivities(
    state: ShiftTimelineDataStateModel,
  ): Record<ShiftId, ActivityRow[]> {
    return state.determinedShiftActivities;
  }

  @Selector()
  static usersDictionary(
    state: ShiftTimelineDataStateModel,
  ): Record<UserId, UserTimelines> {
    return state.usersDictionary;
  }

  @Selector()
  static filterUserIds(state: ShiftTimelineDataStateModel): string[] {
    return state.filterUserIds;
  }

  @Selector()
  static timeframe(state: ShiftTimelineDataStateModel): Interval {
    return state.timeframe;
  }

  @Selector()
  static daysRange(state: ShiftTimelineDataStateModel): Date[] {
    return state.daysRange;
  }

  @Selector()
  static daysRangeCount(state: ShiftTimelineDataStateModel): number {
    return eachDayOfInterval(state.timeframe).length;
  }

  @Selector()
  static isLoadingUsers(state: ShiftTimelineDataStateModel): boolean {
    return state.isLoadingUsers;
  }

  @Selector()
  static isLoadingFilteredUsers(state: ShiftTimelineDataStateModel): boolean {
    return state.isLoadingFilteredUsers;
  }

  @Selector()
  static hasSuccessfullyFetchedUsers(
    state: ShiftTimelineDataStateModel,
  ): boolean {
    return state.hasSuccessfullyFetchedUsers;
  }

  @Selector()
  static isLoadingUsersOvertimes(state: ShiftTimelineDataStateModel): boolean {
    return state.isLoadingUsersOvertimes;
  }

  @Selector()
  static hasSuccessfullyFetchedUsersOvertimes(
    state: ShiftTimelineDataStateModel,
  ): boolean {
    return state.hasSuccessfullyFetchedUsersOvertimes;
  }

  @Selector()
  static isLoadingValidations(state: ShiftTimelineDataStateModel): boolean {
    return state.isLoadingValidations;
  }

  @Selector()
  static isLoadingValidationsError(
    state: ShiftTimelineDataStateModel,
  ): boolean {
    return state.isLoadingValidationsError;
  }

  @Selector()
  static isLoadingValidationsV1Error(
    state: ShiftTimelineDataStateModel,
  ): boolean {
    return state.isLoadingValidationsV1Error;
  }

  @Selector()
  static currentTimelineHeightPx(state: ShiftTimelineDataStateModel): number {
    return state.currentTimelineHeightPx;
  }

  @Selector()
  static maxUnassignedRowHeightPx(state: ShiftTimelineDataStateModel): number {
    return state.currentTimelineHeightPx / 2;
  }

  @Selector()
  static usersValidationRecords(
    state: ShiftTimelineDataStateModel,
  ): Record<string, ShiftValidationDetails[]> {
    return state.usersValidationRecords;
  }

  @Selector()
  static validatedUserRecords(
    state: ShiftTimelineDataStateModel,
  ): DataCacheRecords {
    return state.validatedUserRecords;
  }

  @Selector()
  static userOvertime(state: ShiftTimelineDataStateModel): UserOvertime[] {
    return state.usersOvertimes;
  }

  @Selector()
  static isRefreshingUsers(state: ShiftTimelineDataStateModel): boolean {
    return state.isRefreshingUsers;
  }

  @Selector()
  static usersWithUpdatingShiftAssignments(
    state: ShiftTimelineDataStateModel,
  ): string[] {
    return state.usersWithUpdatingShiftAssignments;
  }

  @Selector()
  static shiftIdsToHighlight(state: ShiftTimelineDataStateModel): string[] {
    return state.shiftIdsToHighlight;
  }

  @Selector()
  static stayIdsToHighlight(state: ShiftTimelineDataStateModel): string[] {
    return state.stayIdsToHighlight;
  }

  @Selector()
  static serviceIdsToHighlight(state: ShiftTimelineDataStateModel): string[] {
    return state.serviceIdsToHighlight;
  }

  @Selector()
  static userIdsToPinOnTimeline(state: ShiftTimelineDataStateModel): string[] {
    return state.userIdsToPinOnTimeline;
  }

  @Selector()
  static usersWithUpdatingValidations(
    state: ShiftTimelineDataStateModel,
  ): string[] {
    return state.usersWithUpdatingValidations;
  }

  @Selector()
  static dateToUserIdAssignmentIdRecord(
    state: ShiftTimelineDataStateModel,
  ): Record<string, Record<string, Record<string, undefined>>> {
    return state.dateToUserIdAssignmentIdRecord;
  }

  static getDataInRangeForUsers(
    userIds: UserId[],
  ): (
    dateToUserIdAssignmentIdRecord: Record<
      string,
      Record<string, Record<string, undefined>>
    >,
    usersDictionary: Record<string, UserTimelines>,
    visibleDateRange: { start: Date | null; end: Date | null },
  ) => UserTimelines[] {
    return createSelector(
      [
        ShiftTimelineDataState.dateToUserIdAssignmentIdRecord,
        ShiftTimelineDataState.usersDictionary,
        ShiftTimelineDataState.visibleDateRange,
      ],
      (dateToUserIdAssignmentIdRecord, usersDictionary, visibleDateRange) => {
        return getDataInRange({
          dateToUserIdAssignmentIdRecord,
          usersDictionary,
          userIds,
          visibleDateRange,
        });
      },
    );
  }

  static userOvertimeMinutesStream(
    userId: string,
  ): (overtime: UserOvertime[]) => number | undefined {
    return createSelector(
      [ShiftTimelineDataState.userOvertime],
      (overtime: UserOvertime[]) => {
        return overtime.find(
          (userOvertimes) => userOvertimes.user.id === userId,
        )?.overtimes[0].overtimeInMinutes;
      },
    );
  }

  static isShiftValidated(
    userId: string,
    shiftStartDate: Date,
  ): (
    validatedUserRecords: ShiftTimelineDataStateModel['validatedUserRecords'],
  ) => boolean {
    return createSelector(
      [ShiftTimelineDataState.validatedUserRecords],
      (
        validatedUserRecords: ShiftTimelineDataStateModel['validatedUserRecords'],
      ) => {
        return isUserAndDateInRecord(
          validatedUserRecords,
          userId,
          shiftStartDate,
        );
      },
    );
  }

  static shiftDeterminedActivities(
    shiftId: string,
  ): (
    determinedShiftActivities: ShiftTimelineDataStateModel['determinedShiftActivities'],
  ) => ActivityRow[] {
    return createSelector(
      [ShiftTimelineDataState.determinedShiftActivities],
      (
        determinedShiftActivities: ShiftTimelineDataStateModel['determinedShiftActivities'],
      ) => {
        return determinedShiftActivities[shiftId] || [];
      },
    );
  }

  static activityOfShift({
    shiftId,
    activityId,
  }: {
    shiftId: string;
    activityId: string;
  }): (
    activityRows: ActivityRow[],
  ) => ActivityWithCategoryAndLocationsAndReportedDetails | null {
    return createSelector(
      [ShiftTimelineDataState.shiftDeterminedActivities(shiftId)],
      (activityRows: ActivityRow[]) => {
        const activityRowOfInterest = activityRows.find(
          (activityRow) => activityId in activityRow,
        );
        return activityRowOfInterest?.[activityId] || null;
      },
    );
  }

  static usersTimelineDataStream(
    userIds: string[],
  ): (
    usersDictionary: ShiftTimelineDataStateModel['usersDictionary'],
  ) => Record<string, UserTimelines> {
    return createSelector(
      [ShiftTimelineDataState.usersDictionary],
      (usersDictionary: ShiftTimelineDataStateModel['usersDictionary']) => {
        return userIds.reduce((acc, userId) => {
          if (usersDictionary[userId]) {
            acc[userId] = usersDictionary[userId];
          }
          return acc;
        }, {} as Record<string, UserTimelines>);
      },
    );
  }

  static userTimelineRowDataStreamV2(
    userId: string,
  ): (
    usersDictionary: ShiftTimelineDataStateModel['usersDictionary'],
  ) => GroupedUserDataV2[] | null {
    return createSelector(
      [ShiftTimelineDataState.usersDictionary],
      (
        usersDictionary: ShiftTimelineDataStateModel['usersDictionary'],
      ): GroupedUserDataV2[] | null => {
        const userTimeline = usersDictionary[userId];

        if (userTimeline) {
          const { determinedRowDataV2 } = userTimeline;
          return determinedRowDataV2;
        } else {
          return null;
        }
      },
    );
  }

  static userTimelineAssignmentStacksStream(
    userId: string,
  ): (
    usersDictionary: ShiftTimelineDataStateModel['usersDictionary'],
  ) => AssignmentStack[] | null {
    return createSelector(
      [ShiftTimelineDataState.usersDictionary],
      (
        usersDictionary: ShiftTimelineDataStateModel['usersDictionary'],
      ): AssignmentStack[] | null => {
        const userTimeline = usersDictionary[userId];

        if (userTimeline) {
          const { assignmentStacks } = userTimeline;
          return assignmentStacks;
        } else {
          return null;
        }
      },
    );
  }

  static shiftActivitiesArray(
    shiftId: string,
  ): (
    activityRows: ActivityRow[],
  ) => ActivityWithCategoryAndLocationsAndReportedDetails[] {
    return createSelector(
      [ShiftTimelineDataState.shiftDeterminedActivities(shiftId)],
      (activityRows: ActivityRow[]) => {
        return activityRows.flatMap((row) => Object.values(row));
      },
    );
  }

  static getShiftOfUser(
    shiftId: string,
    userId: string,
  ): (userTimeline: UserTimelines) => TimelineShift {
    return createSelector(
      [ShiftTimelineDataState.usersTimelineDataStream([userId])],
      (userTimeline: UserTimelines) => {
        return userTimeline.shiftsWithoutActivitiesDictionary[shiftId];
      },
    );
  }

  static getShiftWithoutActivities(
    shiftId: string,
  ): (users: UserTimelines[]) => TimelineShift | undefined {
    return createSelector(
      [ShiftTimelineDataState.timelineUsers],
      (users: UserTimelines[]) => {
        const user = users.find(
          (user) => user.shiftsWithoutActivitiesDictionary[shiftId],
        );
        return user?.shiftsWithoutActivitiesDictionary[shiftId];
      },
    );
  }

  static isCurrentUserValidationLoading(
    userId: string,
  ): (
    usersWithUpdatingValidations: ShiftTimelineDataStateModel['usersWithUpdatingValidations'],
    isLoadingValidations: ShiftTimelineDataStateModel['isLoadingValidations'],
  ) => boolean {
    return createSelector(
      [
        ShiftTimelineDataState.usersWithUpdatingValidations,
        ShiftTimelineDataState.isLoadingValidations,
      ],
      (
        usersWithUpdatingValidations: ShiftTimelineDataStateModel['usersWithUpdatingValidations'],
        isLoadingValidations: ShiftTimelineDataStateModel['isLoadingValidations'],
      ) => {
        return (
          usersWithUpdatingValidations.includes(userId) || isLoadingValidations
        );
      },
    );
  }

  @Action(ClearAssignmentsFromAllUsers)
  clearAssignmentsFromAllUsers(
    ctx: StateContext<ShiftTimelineDataStateModel>,
  ): void {
    const clonedUsersDictionary = cloneDeep(ctx.getState().usersDictionary);
    Object.values(clonedUsersDictionary).forEach((userAssignmentRecord) => {
      userAssignmentRecord.shiftsWithoutActivitiesDictionary = {};
      userAssignmentRecord.absencesDictionary = {};
      userAssignmentRecord.determinedRowDataV2 = [];
      userAssignmentRecord.assignmentStacks = [];
      userAssignmentRecord.staysDictionary = {};
    });
    ctx.patchState({
      usersDictionary: clonedUsersDictionary,
    });
  }

  @Action(RemoveTimelinesNonMatchingElements)
  removeTimelinesNonMatchingElements(
    ctx: StateContext<ShiftTimelineDataStateModel>,
  ): void {
    const clonedUsersDictionary = cloneDeep(ctx.getState().usersDictionary);

    const userTimelineChangeInstructions = Object.entries(
      clonedUsersDictionary,
    ).reduce(
      (
        instructions: Record<string, StateOperator<UserTimelines>>,
        [userId, userAssignmentRecord],
      ) => {
        const filteredShiftsWithoutActivitiesDictionary: Record<
          ShiftId,
          Shift &
            WithPreparedAttributes & { id: string; isHighlighted: boolean }
        > = {};
        for (const [shiftId, shift] of Object.entries(
          userAssignmentRecord.shiftsWithoutActivitiesDictionary,
        )) {
          if (shift.isHighlighted) {
            filteredShiftsWithoutActivitiesDictionary[shiftId] = shift;
          }
        }

        const filteredAbsencesDictionary: Record<
          AbsenceId,
          Absence & { id: string; isHighlighted: boolean }
        > = {};
        for (const [absenceId, absence] of Object.entries(
          userAssignmentRecord.absencesDictionary,
        )) {
          if (absence.isHighlighted) {
            filteredAbsencesDictionary[absenceId] = absence;
          }
        }

        instructions[userId] = patch({
          shiftsWithoutActivitiesDictionary:
            filteredShiftsWithoutActivitiesDictionary,
          absencesDictionary: filteredAbsencesDictionary,
        });

        return instructions;
      },
      {},
    );

    ctx.setState(
      patch({
        usersDictionary: patch(userTimelineChangeInstructions),
      }),
    );
  }

  @Action(ClearDeterminedShiftActivities)
  clearDeterminedShiftActivities(
    ctx: StateContext<ShiftTimelineDataStateModel>,
  ): void {
    ctx.patchState({
      determinedShiftActivities: {},
    });
  }

  @Action(ClearTimelineStays)
  clearTimelineStays(ctx: StateContext<ShiftTimelineDataStateModel>): void {
    const {
      visibleDateRange,
      dateToUserIdAssignmentIdRecord,
      usersDictionary,
    } = ctx.getState();

    const clonedDateToUserIdAssignmentIdRecord = cloneDeep(
      dateToUserIdAssignmentIdRecord,
    );
    const userIds = Object.keys(usersDictionary);

    const userTimelineChangeInstructions = userIds.reduce(
      (instructions: Record<string, StateOperator<UserTimelines>>, userId) => {
        const staysIds = Object.keys(usersDictionary[userId].staysDictionary);
        staysIds.forEach((stayId) =>
          removeInPlaceFromUserInDateToAssignmentIdRecord(
            stayId,
            userId,
            clonedDateToUserIdAssignmentIdRecord,
          ),
        );

        instructions[userId as string] = patch({
          staysDictionary: {},
        });

        return instructions;
      },
      {},
    );

    ctx.setState(
      patch({
        usersDictionary: patch(userTimelineChangeInstructions),
        dateToUserIdAssignmentIdRecord: clonedDateToUserIdAssignmentIdRecord,
      }),
    );

    const authUserId = this.store.selectSnapshot(AuthState.userId);
    if (authUserId) {
      const isCellView = this.store.selectSnapshot(
        ShiftTimelinePreferredSettingsState.isCellView(authUserId),
      );
      updateRowOverlapDataSyncOrAssignmentsStack({
        ctx,
        userIds: userIds,
        visibleDateRange,
        isCellView,
      });
    }
  }

  @Action(SetVisibleDateRange)
  setVisibleDateRange(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: SetVisibleDateRange,
  ): void {
    ctx.patchState({
      visibleDateRange: action.range,
    });
  }

  @Action(TimelineSetCurrentDate)
  timelineSetCurrentDate(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: TimelineSetCurrentDate,
  ): void {
    ctx.patchState({
      currentDate: action.date,
    });
  }

  @Action(TimelineSetDate)
  timelineSetDate(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: TimelineSetDate,
  ): void {
    const timeframe = {
      start: startOfDay(action.date.start),
      end: endOfDay(action.date.end),
    };
    ctx.patchState({
      timeframe,
      daysRange: eachDayOfInterval(timeframe),
    });
  }

  @Action(SetMainTimelineHeightPx)
  setMainTimelineHeightPx(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    { payload }: SetMainTimelineHeightPx,
  ): void {
    ctx.patchState({
      currentTimelineHeightPx: payload.heightPx,
    });
  }

  @Action(TimelineFetchUsersWithFilters)
  timelineFetchUsersWithFilters(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    { options }: TimelineFetchUsersWithFilters,
  ): Observable<
    | string[]
    | (TimelineFetchState & {
        isLoadingFilteredUsers: boolean;
      })
  > {
    ctx.patchState({
      isLoadingFilteredUsers: true,
    });

    return this.userTimelineGateway.getFilteredUsers(options).pipe(
      tap((userIds: UserId[]) => {
        ctx.patchState({
          filterUserIds: userIds,
          isLoadingFilteredUsers: false,
        });
      }),
      catchError(() =>
        of({
          isRequestError: true,
          data: [],
          isLoadingFilteredUsers: false,
        }),
      ),
    );
  }

  @Action(TimelineFetchUsers)
  timelineFetchUsers(
    ctx: StateContext<ShiftTimelineDataStateModel>,
  ): Observable<
    | TimelineUserDTO[]
    | (TimelineFetchState & {
        isLoadingUsers: boolean;
        hasSuccessfullyFetchedUsers: boolean;
      })
  > {
    ctx.patchState({
      isLoadingUsers: true,
      hasSuccessfullyFetchedUsers: false,
    });

    return this.accountService.getTimelineUsers().pipe(
      switchMap((users) =>
        combineLatest(
          users.map((user) => {
            const locationId = user.organizationalUnit?.locationId;
            return this.locationsStoreService
              .getLocationFromCache(locationId as string)
              .pipe(
                map((location) => ({
                  ...user,
                  organizationalUnit: {
                    ...user.organizationalUnit,
                    location: location || undefined,
                  },
                })),
              );
          }),
        ),
      ),
      tap((users: TimelineUserDTO[]) => {
        ctx.patchState({
          usersDictionary: Object.fromEntries(
            this.sortUserByName(users).map((user) => [
              user.id,
              {
                ...user,
                id: user.id as string,
                userRoles: user.userRoles ?? [],
                shifts: [],
                shiftsWithoutActivities: [],
                absences: [],
                determinedRowDataV2: [],
                determinedRowDataTimestamp: 0,
                absencesDictionary: {},
                shiftsWithoutActivitiesDictionary: {},
                determinedShiftActivities: {},
                staysDictionary: {},
                assignmentStacks: [],
              },
            ]),
          ),
          isLoadingUsers: false,
          hasSuccessfullyFetchedUsers: true,
        });
      }),
      catchError(() =>
        of({
          isRequestError: true,
          data: [],
          isLoadingUsers: false,
          hasSuccessfullyFetchedUsers: false,
        }),
      ),
    );
  }

  @Action(TimelineUpdateAssignmentsV2)
  async timelineUpdateAssignmentsV2(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    { payload }: TimelineUpdateAssignmentsV2,
  ): Promise<
    Observable<UserTimelinesWithoutActivitiesPayload[] | TimelineFetchState>
  > {
    const cancelSubject = new Subject();
    trackAndMaintainMaxRequestCount({
      cancellationSubject: cancelSubject,
      requestReferences: this.fetchUserTimelinesRequestSubject,
      maxParallelRequestCount: 2,
    });

    if (
      payload.dates.start &&
      payload.dates.end &&
      payload.userIds.length > 0
    ) {
      const correctedInterval = getTimeZoneCorrectedDateRange({
        start: payload.dates.start as Date,
        end: payload.dates.end as Date,
      });

      ctx.patchState({
        usersWithUpdatingShiftAssignments: [
          ...ctx.getState().usersWithUpdatingShiftAssignments,
          ...payload.userIds,
        ],
      });

      const options =
        await this.shiftTimelineFilterStateService.generateFilterOptionsForAssignedRegion(
          correctedInterval.start,
          correctedInterval.end,
          payload.userIds,
        );

      const renderUnmatchingElements = await firstValueFrom(
        this.shiftTimelineFilterStateService.renderUnmatchedElements$,
      );

      return this.userTimelineGateway
        .getUserTimelinesWithoutActivities(options)
        .pipe(
          takeUntil(cancelSubject),
          tap(() => {
            const { updatedValidatedUsersRecords } = filterAndUpdateDataRecords(
              ctx.getState().shiftAssignmentDataRecordsV2,
              payload.userIds,
              correctedInterval.start,
              correctedInterval.end,
            );

            ctx.patchState({
              shiftAssignmentDataRecordsV2: updatedValidatedUsersRecords,
            });
          }),
          tap(async (users) => {
            const dateToAssignmentIdsRecord: ShiftTimelineDataStateModel['dateToUserIdAssignmentIdRecord'] =
              cloneDeep(ctx.getState().dateToUserIdAssignmentIdRecord);
            let userTimelineChangeInstructions: Record<
              string,
              StateOperator<UserTimelines>
            > = {};

            userTimelineChangeInstructions = reduceIncludingUnmatchingElements(
              users,
              dateToAssignmentIdsRecord,
              correctedInterval,
            );
            ctx.setState(
              patch({
                usersDictionary: patch(userTimelineChangeInstructions),
                dateToUserIdAssignmentIdRecord: dateToAssignmentIdsRecord,
              }),
            );

            if (!renderUnmatchingElements) {
              this.store.dispatch(new RemoveTimelinesNonMatchingElements());
            }

            const authUserId = this.store.selectSnapshot(AuthState.userId);
            if (authUserId) {
              const isCellView = this.store.selectSnapshot(
                ShiftTimelinePreferredSettingsState.isCellView(authUserId),
              );

              await updateRowOverlapOrAssignmentStacks({
                determineAssignmentsOverlapWorkerWrapper:
                  this.determineAssignmentsOverlapWorkerWrapper,
                ctx,
                userIds: payload.userIds,
                visibleDateRange: payload.dates,
                isCellView,
              });
            }

            clearLoadingUserIds(ctx, payload.userIds);
          }),
          catchError(() => {
            clearLoadingUserIds(ctx, payload.userIds);
            return of({
              isRequestError: true,
              data: [],
            });
          }),
        );
    } else {
      return of({
        isRequestError: true,
        data: [],
      });
    }
  }

  @Action(TimelineFetchUsersOvertimes)
  timelineFetchUsersOvertimes(
    ctx: StateContext<ShiftTimelineDataStateModel>,
  ): Observable<
    | UserOvertime[]
    | (TimelineFetchState & {
        hasSuccessfullyFetchedUsersOvertimes: boolean;
      })
  > {
    const allowOvertime$ = this.featurePurePipe.transform(FeatureName.Payroll);

    return allowOvertime$.pipe(
      switchMap((allowOvertime) => {
        if (allowOvertime) {
          this.unsubFetchUsersOvertimesRequestSubject.next(null);
          ctx.patchState({
            isLoadingUsersOvertimes: true,
            hasSuccessfullyFetchedUsersOvertimes: false,
          });

          return this.accountService
            .getUserOvertimes([
              {
                start: formatISO(startOfYear(new Date())),
                end: formatISO(new Date()),
              },
            ])
            .pipe(
              takeUntil(this.unsubFetchUsersOvertimesRequestSubject),
              tap((usersOvertimes: UserOvertime[]) => {
                ctx.patchState({
                  usersOvertimes,
                  isLoadingUsersOvertimes: false,
                  hasSuccessfullyFetchedUsersOvertimes: true,
                });
              }),
              catchError(() =>
                of({
                  isRequestError: true,
                  data: [],
                  hasSuccessfullyFetchedUsersOvertimes: false,
                }),
              ),
            );
        } else {
          return of();
        }
      }),
    );
  }

  @Action(TimelineLoadActivitiesForShifts)
  loadActivitiesForShifts(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    { payload }: TimelineLoadActivitiesForShifts,
  ): void {
    this.activitiesStateLoaderService.loadActivitiesToAssignedRegionState(
      ctx,
      Array.from(payload),
    );
  }

  @Action(TimelineUpsertNewShiftV2)
  timelineUpsertNewShiftV2(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: TimelineUpsertNewShiftV2,
  ): void {
    const { visibleDateRange, dateToUserIdAssignmentIdRecord } = ctx.getState();
    const { shift } = action.payload;

    if (shift.userId) {
      const clonedDateToUserIdAssignmentIdRecord = cloneDeep(
        dateToUserIdAssignmentIdRecord,
      );

      removeInPlaceFromUserInDateToAssignmentIdRecord(
        shift.id,
        shift.userId,
        clonedDateToUserIdAssignmentIdRecord,
      );

      updateInPlaceDateToShiftIdRecord({
        shift,
        dateToAssignmentIdsRecord: clonedDateToUserIdAssignmentIdRecord,
        cleanUpRange: visibleDateRange as {
          start: Date;
          end: Date;
        },
      });

      ctx.setState(
        patch({
          usersDictionary: patch({
            [shift.userId as string]: patch({
              shiftsWithoutActivitiesDictionary: patch({
                [shift.id]: shift,
              }),
            }),
          }),
          dateToUserIdAssignmentIdRecord: clonedDateToUserIdAssignmentIdRecord,
        }),
      );

      const authUserId = this.store.selectSnapshot(AuthState.userId);
      if (authUserId) {
        const isCellView = this.store.selectSnapshot(
          ShiftTimelinePreferredSettingsState.isCellView(authUserId),
        );
        updateRowOverlapDataSyncOrAssignmentsStack({
          ctx,
          userIds: [shift.userId],
          visibleDateRange,
          isCellView,
        });
      }
    }
  }

  @Action(TimelineUpsertActivityV2)
  timelineUpsertActivityV2(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: TimelineUpsertActivityV2,
  ): void {
    const state = ctx.getState();
    const determinedShiftActivities = state.determinedShiftActivities;
    let activityRows = determinedShiftActivities[action.shiftId];
    if (!activityRows) {
      activityRows = [];
    }
    const allActivities = activityRows.reduce((acc, row) => {
      return { ...acc, ...row };
    }, {});

    if (action.activities.length) {
      action.activities.forEach((activity) => {
        if (activity.id) {
          allActivities[activity.id] = activity;
        }
      });
    }

    const determinedActivities =
      this.activitiesStateLoaderService.getActivityRows(
        Object.values(allActivities) as (Activity & { id: string })[],
      );

    ctx.setState(
      patch({
        determinedShiftActivities: patch({
          [action.shiftId]: determinedActivities,
        }),
      }),
    );
  }

  @Action(TimelineRemoveActivityFromShiftV2)
  timelineRemoveActivityFromShiftV2(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: TimelineRemoveActivityFromShiftV2,
  ): void {
    const state = ctx.getState();
    const determinedShiftActivities = state.determinedShiftActivities;
    let activityRows = determinedShiftActivities[action.shiftId];
    if (!activityRows) {
      activityRows = [];
    }
    const allActivities = activityRows.reduce((acc, row) => {
      return { ...acc, ...row };
    }, {});

    delete allActivities[action.activityId];

    const determinedActivities =
      this.activitiesStateLoaderService.getActivityRows(
        Object.values(allActivities) as (Activity & { id: string })[],
      );

    ctx.setState(
      patch({
        determinedShiftActivities: patch({
          [action.shiftId]: determinedActivities,
        }),
      }),
    );
  }

  @Action(TimelineCreateNewShiftV2)
  timelineCreateNewShiftV2(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: TimelineCreateNewShiftV2,
  ): void {
    const {
      usersDictionary,
      dateToUserIdAssignmentIdRecord,
      visibleDateRange,
    } = ctx.getState();
    const clonedDateToUserIdAssignmentIdRecord = cloneDeep(
      dateToUserIdAssignmentIdRecord,
    );
    const clonedUser = cloneDeep(usersDictionary[action.userId]);
    const shiftToCreateWithUserId = {
      ...action.shiftToCreate,
      userId: action.userId,
    };

    clonedUser.shiftsWithoutActivitiesDictionary[shiftToCreateWithUserId.id] =
      shiftToCreateWithUserId;

    updateInPlaceDateToShiftIdRecord({
      shift: shiftToCreateWithUserId,
      dateToAssignmentIdsRecord: clonedDateToUserIdAssignmentIdRecord,
      cleanUpRange: visibleDateRange as {
        start: Date;
        end: Date;
      },
    });

    ctx.patchState({
      usersDictionary: {
        ...usersDictionary,
        [action.userId]: clonedUser,
      },
      dateToUserIdAssignmentIdRecord: clonedDateToUserIdAssignmentIdRecord,
    });

    const authUserId = this.store.selectSnapshot(AuthState.userId);
    if (authUserId) {
      const isCellView = this.store.selectSnapshot(
        ShiftTimelinePreferredSettingsState.isCellView(authUserId),
      );
      updateRowOverlapDataSyncOrAssignmentsStack({
        ctx,
        userIds: [action.userId],
        visibleDateRange,
        isCellView,
      });
    }
  }

  @Action(TimelineUpsertNewAbsence)
  timelineUpsertNewAbsence(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: TimelineUpsertNewAbsence,
  ): void {
    const { visibleDateRange, dateToUserIdAssignmentIdRecord } = ctx.getState();
    const { absence } = action.payload;
    const clonedDateToUserIdAssignmentIdRecord = cloneDeep(
      dateToUserIdAssignmentIdRecord,
    );

    removeInPlaceFromUserInDateToAssignmentIdRecord(
      absence.id,
      absence.userId,
      clonedDateToUserIdAssignmentIdRecord,
    );

    updateInPlaceDateToAbsenceIdRecord({
      absence,
      dateToAssignmentIdsRecord: clonedDateToUserIdAssignmentIdRecord,
      cleanUpRange: visibleDateRange as {
        start: Date;
        end: Date;
      },
    });

    ctx.setState(
      patch({
        usersDictionary: patch({
          [absence.userId]: patch({
            absencesDictionary: patch({
              [absence.id]: absence,
            }),
          }),
        }),
        dateToUserIdAssignmentIdRecord: clonedDateToUserIdAssignmentIdRecord,
      }),
    );

    const authUserId = this.store.selectSnapshot(AuthState.userId);
    if (authUserId) {
      const isCellView = this.store.selectSnapshot(
        ShiftTimelinePreferredSettingsState.isCellView(authUserId),
      );
      updateRowOverlapDataSyncOrAssignmentsStack({
        ctx,
        userIds: [absence.userId],
        visibleDateRange,
        isCellView,
      });
    }
  }

  @Action(TimelineUpsertNewStay)
  timelineUpsertNewStay(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: TimelineUpsertNewStay,
  ): void {
    const { visibleDateRange, dateToUserIdAssignmentIdRecord } = ctx.getState();
    const stay = action.payload;
    const clonedDateToUserIdAssignmentIdRecord = cloneDeep(
      dateToUserIdAssignmentIdRecord,
    );

    removeInPlaceFromUserInDateToAssignmentIdRecord(
      stay.id,
      stay.userId,
      clonedDateToUserIdAssignmentIdRecord,
    );

    updateInPlaceDateToStayIdRecord({
      stay,
      dateToAssignmentIdsRecord: clonedDateToUserIdAssignmentIdRecord,
      cleanUpRange: visibleDateRange as {
        start: Date;
        end: Date;
      },
    });

    ctx.setState(
      patch({
        usersDictionary: patch({
          [stay.userId]: patch({
            staysDictionary: patch({
              [stay.id]: stay,
            }),
          }),
        }),
        dateToUserIdAssignmentIdRecord: clonedDateToUserIdAssignmentIdRecord,
      }),
    );

    const authUserId = this.store.selectSnapshot(AuthState.userId);
    if (authUserId) {
      const isCellView = this.store.selectSnapshot(
        ShiftTimelinePreferredSettingsState.isCellView(authUserId),
      );
      updateRowOverlapDataSyncOrAssignmentsStack({
        ctx,
        userIds: [stay.userId],
        visibleDateRange,
        isCellView,
      });
    }
  }

  @Action(TimelineRemoveAbsence)
  timelineRemoveAbsence(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: TimelineRemoveAbsence,
  ): void {
    const {
      visibleDateRange,
      dateToUserIdAssignmentIdRecord,
      usersDictionary,
    } = ctx.getState();
    const { absenceId, userId } = action.payload;
    const clonedDateToUserIdAssignmentIdRecord = cloneDeep(
      dateToUserIdAssignmentIdRecord,
    );

    const userToUpdate = cloneDeep(usersDictionary[userId]);
    removeInPlaceFromUserInDateToAssignmentIdRecord(
      absenceId,
      userId,
      clonedDateToUserIdAssignmentIdRecord,
    );
    delete userToUpdate.absencesDictionary[absenceId];

    ctx.setState(
      patch({
        usersDictionary: patch({
          [userId]: patch({
            absencesDictionary: userToUpdate.absencesDictionary,
          }),
        }),
        dateToUserIdAssignmentIdRecord: clonedDateToUserIdAssignmentIdRecord,
      }),
    );

    const authUserId = this.store.selectSnapshot(AuthState.userId);
    if (authUserId) {
      const isCellView = this.store.selectSnapshot(
        ShiftTimelinePreferredSettingsState.isCellView(authUserId),
      );
      updateRowOverlapDataSyncOrAssignmentsStack({
        ctx,
        userIds: [userId],
        visibleDateRange,
        isCellView,
      });
    }
  }

  @Action(TimelineUpdateShiftV2)
  timelineUpdateShiftV2(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: TimelineUpdateShiftV2,
  ): void {
    const {
      usersDictionary,
      dateToUserIdAssignmentIdRecord,
      visibleDateRange,
    } = ctx.getState();
    const clonedUser = cloneDeep(
      usersDictionary[action.shiftToUpdate.userId as string],
    );
    const clonedDateToUserIdAssignmentIdRecord = cloneDeep(
      dateToUserIdAssignmentIdRecord,
    );

    clonedUser.shiftsWithoutActivitiesDictionary[action.shiftToUpdate.id] =
      action.shiftToUpdate;

    ctx.patchState({
      usersDictionary: {
        ...usersDictionary,
        [action.shiftToUpdate.userId as string]: clonedUser,
      },
      dateToUserIdAssignmentIdRecord: clonedDateToUserIdAssignmentIdRecord,
    });

    if (action.shiftToUpdate.userId) {
      const authUserId = this.store.selectSnapshot(AuthState.userId);
      if (authUserId) {
        const isCellView = this.store.selectSnapshot(
          ShiftTimelinePreferredSettingsState.isCellView(authUserId),
        );
        updateRowOverlapDataSyncOrAssignmentsStack({
          ctx,
          userIds: [action.shiftToUpdate.userId],
          visibleDateRange,
          isCellView,
        });
      }
    }
  }

  @Action(TimelineUpdateAbsence)
  async timelineUpdateAbsence(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: TimelineUpdateAbsence,
  ): Promise<void> {
    const {
      usersDictionary,
      dateToUserIdAssignmentIdRecord,
      visibleDateRange,
    } = ctx.getState();
    const clonedUser = cloneDeep(
      usersDictionary[action.absenceToUpdate.userId as string],
    );
    const clonedDateToUserIdAssignmentIdRecord = cloneDeep(
      dateToUserIdAssignmentIdRecord,
    );

    clonedUser.absencesDictionary[action.absenceToUpdate.id] =
      action.absenceToUpdate;

    removeInPlaceFromUserInDateToAssignmentIdRecord(
      action.absenceToUpdate.id,
      action.absenceToUpdate.userId,
      clonedDateToUserIdAssignmentIdRecord,
    );

    updateInPlaceDateToAbsenceIdRecord({
      absence: action.absenceToUpdate,
      dateToAssignmentIdsRecord: clonedDateToUserIdAssignmentIdRecord,
      cleanUpRange: visibleDateRange as {
        start: Date;
        end: Date;
      },
    });

    ctx.patchState({
      usersDictionary: {
        ...usersDictionary,
        [action.absenceToUpdate.userId]: clonedUser,
      },
      dateToUserIdAssignmentIdRecord: clonedDateToUserIdAssignmentIdRecord,
    });

    const authUserId = this.store.selectSnapshot(AuthState.userId);
    if (authUserId) {
      const isCellView = this.store.selectSnapshot(
        ShiftTimelinePreferredSettingsState.isCellView(authUserId),
      );
      updateRowOverlapDataSyncOrAssignmentsStack({
        ctx,
        userIds: [action.absenceToUpdate.userId],
        visibleDateRange,
        isCellView,
      });
    }
  }

  @Action(DeleteShiftDeterminedActivities)
  bulkDeleteShiftActivitiesFromUserV2(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: DeleteShiftDeterminedActivities,
  ): void {
    const determinedShiftActivitiesClone = {
      ...ctx.getState().determinedShiftActivities,
    };

    action.shiftIds.forEach(
      (shiftId) => delete determinedShiftActivitiesClone[shiftId],
    );
    ctx.setState(
      patch({
        determinedShiftActivities: determinedShiftActivitiesClone,
      }),
    );
  }

  @Action(TimelineRemoveShiftFromUserV2)
  timelineRemoveShiftFromUserV2(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: TimelineRemoveShiftFromUserV2,
  ): void {
    const {
      visibleDateRange,
      usersDictionary,
      dateToUserIdAssignmentIdRecord,
    } = ctx.getState();
    const clonedDateToUserIdAssignmentIdRecord = cloneDeep(
      dateToUserIdAssignmentIdRecord,
    );

    const { userId, shiftId } = action.shiftToRemove;
    const userToUpdate = cloneDeep(usersDictionary[userId]);

    delete userToUpdate.shiftsWithoutActivitiesDictionary[shiftId];

    removeInPlaceFromUserInDateToAssignmentIdRecord(
      shiftId,
      userId,
      clonedDateToUserIdAssignmentIdRecord,
    );

    ctx.setState(
      patch({
        usersDictionary: patch({
          [userId]: patch({
            shiftsWithoutActivitiesDictionary:
              userToUpdate.shiftsWithoutActivitiesDictionary,
          }),
        }),
        dateToUserIdAssignmentIdRecord: clonedDateToUserIdAssignmentIdRecord,
      }),
    );

    const authUserId = this.store.selectSnapshot(AuthState.userId);
    if (authUserId) {
      const isCellView = this.store.selectSnapshot(
        ShiftTimelinePreferredSettingsState.isCellView(authUserId),
      );
      updateRowOverlapDataSyncOrAssignmentsStack({
        ctx,
        userIds: [action.shiftToRemove.userId],
        visibleDateRange,
        isCellView,
      });
    }
  }

  @Action(ResetTimelineData)
  reset({ setState }: StateContext<ShiftTimelineDataStateModel>): void {
    setState(shiftTimelineDefaultData);
  }

  @Action(TimelineFetchUserValidations)
  async fetchUserValidations(
    ctx: StateContext<ShiftTimelineDataStateModel>,
  ): Promise<Observable<UserValidationsFetchState>> {
    return this.shiftValidationsHelperService.fetchUserValidations(ctx);
  }

  @Action(TimelineUpdateShiftValidations)
  fetchAndUpdateShiftValidations(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: TimelineUpdateShiftValidations,
  ): Observable<UserValidationsFetchState> {
    return this.shiftValidationsHelperService.fetchAndUpdateShiftValidations(
      ctx,
      action,
    );
  }

  @Action(TimelinePublishShifts)
  timelinePublishShifts(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: TimelinePublishShifts,
  ): void {
    const { usersDictionary, visibleDateRange } = ctx.getState();
    const users = Object.values(usersDictionary);
    const userIdsToUpdate = users
      .filter((user) =>
        action.shiftIdsToUpdate.some(
          (id) => user.shiftsWithoutActivitiesDictionary[id],
        ),
      )
      .map((user) => {
        action.shiftIdsToUpdate.forEach((id) => {
          if (user.shiftsWithoutActivitiesDictionary[id]) {
            ctx.setState(
              patch({
                usersDictionary: patch({
                  [user.id as string]: patch({
                    shiftsWithoutActivitiesDictionary: patch({
                      [id]: patch({
                        publicationStatus: PublicationStatus.Published,
                      }),
                    }),
                  }),
                }),
              }),
            );
          }
        });
        return user.id as string;
      });
    if (userIdsToUpdate.length) {
      const authUserId = this.store.selectSnapshot(AuthState.userId);
      if (authUserId) {
        const isCellView = this.store.selectSnapshot(
          ShiftTimelinePreferredSettingsState.isCellView(authUserId),
        );
        updateRowOverlapDataSyncOrAssignmentsStack({
          ctx,
          userIds: userIdsToUpdate,
          visibleDateRange,
          isCellView,
        });
      }
    }
  }

  @Action(ChangeNumberOfDaysInDatepicker)
  changeNumberOfDaysInDatepicker(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: ChangeNumberOfDaysInDatepicker,
  ): void {
    ctx.patchState({
      numberOfDaysInDatepicker: action.days,
    });
  }

  @Action(HighlightSelectedShifts)
  highlightSelectedShifts(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: HighlightSelectedShifts,
  ): void {
    ctx.patchState({
      shiftIdsToHighlight: action.selectedShiftIds,
    });
  }

  @Action(HighlightSelectedStays)
  highlightSelectedStays(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: HighlightSelectedStays,
  ): void {
    ctx.patchState({
      stayIdsToHighlight: action.selectedStayIds,
    });
  }

  @Action(HighlightSelectedServices)
  highlightSelectedServices(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: HighlightSelectedServices,
  ): void {
    ctx.patchState({
      serviceIdsToHighlight: action.selectedServiceIds,
    });
  }

  @Action(PinUsersOnTimeline)
  pinUsersOnTimeline(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: PinUsersOnTimeline,
  ): void {
    ctx.patchState({
      userIdsToPinOnTimeline: action.userIds,
    });
  }

  @Action(TimelineUpdateUserStays)
  timelineUpdateUserStays(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    { payload }: TimelineUpdateUserStays,
  ): void {
    const dateToAssignmentIdsRecord: ShiftTimelineDataStateModel['dateToUserIdAssignmentIdRecord'] =
      cloneDeep(ctx.getState().dateToUserIdAssignmentIdRecord);
    const newStays: Record<StayId, Stay> = {};
    const userTimelineChangeInstructions = payload.stays.reduce(
      (instructions: Record<string, StateOperator<UserTimelines>>, stay) => {
        newStays[stay.id] = stay;
        updateInPlaceDateToStayIdRecord({
          stay,
          dateToAssignmentIdsRecord,
          cleanUpRange: {
            start: payload.dates.start,
            end: payload.dates.end,
          },
        });

        instructions[stay.userId as string] = patch({
          staysDictionary: zipRecords(newStays),
        });

        return instructions;
      },
      {},
    );

    ctx.setState(
      patch({
        usersDictionary: patch(userTimelineChangeInstructions),
        dateToUserIdAssignmentIdRecord: dateToAssignmentIdsRecord,
      }),
    );

    const authUserId = this.store.selectSnapshot(AuthState.userId);
    if (authUserId) {
      const isCellView = this.store.selectSnapshot(
        ShiftTimelinePreferredSettingsState.isCellView(authUserId),
      );
      updateRowOverlapDataSyncOrAssignmentsStack({
        ctx,
        userIds: payload.userIds,
        visibleDateRange: payload.dates,
        isCellView,
      });
    }
  }

  @Action(TimelineRemoveStay)
  timelineRemoveStay(
    ctx: StateContext<ShiftTimelineDataStateModel>,
    action: TimelineRemoveStay,
  ): void {
    const {
      visibleDateRange,
      dateToUserIdAssignmentIdRecord,
      usersDictionary,
    } = ctx.getState();
    const { stayId, userId } = action.payload;
    const clonedDateToUserIdAssignmentIdRecord = cloneDeep(
      dateToUserIdAssignmentIdRecord,
    );

    const userToUpdate = cloneDeep(usersDictionary[userId]);
    removeInPlaceFromUserInDateToAssignmentIdRecord(
      stayId,
      userId,
      clonedDateToUserIdAssignmentIdRecord,
    );
    delete userToUpdate.staysDictionary[stayId];

    ctx.setState(
      patch({
        usersDictionary: patch({
          [userId]: patch({
            staysDictionary: userToUpdate.staysDictionary,
          }),
        }),
        dateToUserIdAssignmentIdRecord: clonedDateToUserIdAssignmentIdRecord,
      }),
    );

    const authUserId = this.store.selectSnapshot(AuthState.userId);
    if (authUserId) {
      const isCellView = this.store.selectSnapshot(
        ShiftTimelinePreferredSettingsState.isCellView(authUserId),
      );
      updateRowOverlapDataSyncOrAssignmentsStack({
        ctx,
        userIds: [userId],
        visibleDateRange,
        isCellView,
      });
    }
  }

  private sortUserByName(users: TimelineUserDTO[]): TimelineUserDTO[] {
    return users.sort((a, b) =>
      `${a.lastName} ${a.firstName}`.localeCompare(
        `${b.lastName} ${b.firstName}`,
      ),
    );
  }
}
