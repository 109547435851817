import {
  BackendLangType,
  EntityLangType,
  LangType,
  langTypes,
} from '../types/language';

export function toBackendLangType(untyped: string): BackendLangType;
export function toBackendLangType(langType: LangType): BackendLangType;
export function toBackendLangType(
  entityLangType: EntityLangType,
): BackendLangType;
export function toBackendLangType(
  type: LangType | EntityLangType | string,
): BackendLangType {
  if (typeof type === 'string' && type.includes('-')) {
    return type.split('-')[0].toLowerCase() as BackendLangType;
  } else {
    return type.slice(4).toLowerCase() as BackendLangType;
  }
}

export function toEntityLangType(untyped: string): EntityLangType;
export function toEntityLangType(langType: LangType): EntityLangType;
export function toEntityLangType(
  backendLangType: BackendLangType,
): EntityLangType;
export function toEntityLangType(
  type: LangType | BackendLangType | string,
): EntityLangType {
  let backendLang: BackendLangType;
  if (typeof type === 'string' && type.includes('-')) {
    backendLang = type.split('-')[0].toLowerCase() as BackendLangType;
  } else {
    backendLang = type as BackendLangType;
  }

  return `name${
    backendLang.charAt(0).toUpperCase() + backendLang.slice(1)
  }` as EntityLangType;
}

export function toLangType(untyped: string): LangType;
export function toLangType(entityLangType: EntityLangType): LangType;
export function toLangType(backendLangType: BackendLangType): LangType;
export function toLangType(
  type: EntityLangType | BackendLangType | string,
): LangType {
  let backendLang: BackendLangType;
  if (type.startsWith('name')) {
    backendLang = type.slice(4).toLowerCase() as BackendLangType;
  } else {
    backendLang = type as BackendLangType;
  }

  return (
    langTypes.find((langType) =>
      langType.toLowerCase().startsWith(backendLang),
    ) || langTypes[0]
  );
}
