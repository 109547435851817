import {
  Activity,
  ActivityWithCategoryAndLocationsAndReportedDetails,
} from '@wilson/interfaces';
import { isBefore } from 'date-fns';

export const activitiesCompareFn = (
  activityA: Pick<Activity, 'startDatetime'>,
  activityB: Pick<Activity, 'startDatetime'>,
): number => {
  if (
    isBefore(
      new Date(activityB.startDatetime),
      new Date(activityA.startDatetime),
    )
  )
    return 1;
  else return -1;
};

export const reportedActivitiesCompareFn = (
  activityA: Pick<
    ActivityWithCategoryAndLocationsAndReportedDetails,
    'reportedStartDatetime'
  >,
  activityB: Pick<
    ActivityWithCategoryAndLocationsAndReportedDetails,
    'reportedStartDatetime'
  >,
): number => {
  if (!activityA.reportedStartDatetime || !activityB.reportedStartDatetime) {
    return 0;
  } else if (
    isBefore(
      new Date(activityB.reportedStartDatetime),
      new Date(activityA.reportedStartDatetime),
    )
  ) {
    return 1;
  } else {
    return -1;
  }
};
