import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  AssignmentOptimizerJobSuccessResponse,
  AssignmentOptimizerJobStatusResponse,
  StartAssignmentOptimizerJobDto,
  AssignmentOptimizerKPIResponse,
  AssignmentData,
  AssignmentOptimizerSetting,
} from '@wilson/interfaces';
import { firstValueFrom, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShiftOptimizerGatewayService {
  protected readonly path = 'shift-optimizer-jobs';

  constructor(
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
    protected readonly http: HttpClient,
  ) {}

  public createShiftAssignmentOptimizerJob(
    payload: StartAssignmentOptimizerJobDto,
  ): Observable<AssignmentOptimizerJobStatusResponse> {
    return this.http.post<AssignmentOptimizerJobStatusResponse>(
      `${this.config.host}/${this.path}`,
      payload,
    );
  }

  public getShiftAssignmentOptimizerJob(
    jobId: string,
  ): Observable<AssignmentOptimizerJobSuccessResponse> {
    return this.http.get<AssignmentOptimizerJobSuccessResponse>(
      `${this.config.host}/${this.path}/${jobId}`,
    );
  }

  public getAssignmentKpis(
    jobId: string,
    payload: AssignmentData,
  ): Observable<AssignmentOptimizerKPIResponse> {
    return this.http.post<AssignmentOptimizerKPIResponse>(
      `${this.config.host}/${this.path}/${jobId}/kpis`,
      payload,
    );
  }

  public getAssignmentOptimizerSettings(): Observable<
    AssignmentOptimizerSetting[] | null
  > {
    return this.http.get<AssignmentOptimizerSetting[]>(
      `${this.config.host}/shift-optimizer-settings`,
    );
  }

  public patchAssignmentOptimizerSettings(
    payload: Partial<AssignmentOptimizerSetting>,
  ): Promise<AssignmentOptimizerSetting> {
    return firstValueFrom(
      this.http.patch<AssignmentOptimizerSetting>(
        `${this.config.host}/shift-optimizer-settings/${payload.id}`,
        payload,
      ),
    );
  }
}
