import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuthorizationModule } from '@wilson/authorization';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import {
  ActivityWithCategoryAndLocationsAndReportedDetails,
  RoleAction,
  RolePermissionSubject,
  Shift,
} from '@wilson/interfaces';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { ShiftContentComponent } from '../../shift-content/shift-content.component';

@Component({
  selector: 'wilson-collapsible-shift-content',
  standalone: true,
  imports: [
    CommonModule,
    NzCollapseModule,
    FeatureFlagsModule,
    TranslateModule,
    AuthorizationModule,
    ShiftContentComponent,
    NzDividerModule,
  ],
  templateUrl: './collapsible-shift-content.component.html',
  styleUrl: './collapsible-shift-content.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleShiftContentComponent {
  @Input({ required: true }) shiftWithActivities:
    | (Shift & { id: string } & {
        activities: ActivityWithCategoryAndLocationsAndReportedDetails[];
      })
    | null = null;
  @Input() isExpandedOnOpen = false;
  @Input() areInputsDisabled = false;
  @Output() isShiftUpdateOperationOngoing = new EventEmitter<boolean>();
  @Output() updateSuccess = new EventEmitter<boolean>();
  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
}
