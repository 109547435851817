@if( ('portal-reopen-shift-from-detail-page' | featureFlagPure | async) &&
(RoleAction.Update | ablePure : RolePermissionSubject.WorkTimeManagement |
async) && (FeatureName.WorkTimeManagement | featurePure | async) && shift?.state
=== ShiftState.Submitted ){

<button
  nz-button
  nz-tooltip
  [nzTooltipTitle]="
    showIconOnly ? ('page.work_time_management.for_processing' | translate) : ''
  "
  class="w-100 me-2 fw-normal"
  (click)="updateShiftState()"
  [disabled]="disabled"
>
  @if(showIconOnly){
  <span class="fas fa-level-down-alt"></span>
  }@else{
  <span class="fas fa-level-down-alt me-1"></span>
  {{ 'page.work_time_management.for_processing' | translate }}
  }
</button>

}
