import { Pipe, PipeTransform } from '@angular/core';
import {
  ActivityFilter,
  ActivityWithCategoryAndLocationsAndReportedDetails,
} from '@wilson/interfaces';

@Pipe({
  name: 'activityEndDatetime',
  standalone: true,
})
export class ActivityEndDatetimePipe implements PipeTransform {
  transform(
    activity: ActivityWithCategoryAndLocationsAndReportedDetails,
    selectedFilter: ActivityFilter,
  ): string {
    if (selectedFilter === ActivityFilter.Reported) {
      return activity.reportedEndDatetime;
    } else {
      return activity.endDatetime;
    }
  }
}
