@let hasShiftUpdatePermissions = (RoleAction.Update | ablePure :
RolePermissionSubject.Shift | async) ; @let hasWorkTimeManagementPermissions =
RoleAction.Update | ablePure : RolePermissionSubject.WorkTimeManagement | async;
<button
  nz-button
  nzType="primary"
  nz-tooltip
  [nzTooltipTitle]="
    !(hasShiftUpdatePermissions || hasWorkTimeManagementPermissions)
      ? ('general.forbidden_action' | translate)
      : ''
  "
  nzTooltipPlacement="bottom"
  [disabled]="
    disabled || !(hasShiftUpdatePermissions || hasWorkTimeManagementPermissions)
  "
  class="w-100 me-2 fw-normal"
  (click)="updateShiftState()"
  *ngIf="
    ('portal_accept_shift_from_detail_page' | featureFlagPure | async) &&
    (FeatureName.WorkTimeManagement | featurePure | async) &&
    shift?.state === ShiftState.Submitted
  "
  data-test-id="wilson-accept-shift-button:action-button"
>
  @if(textOnly){
  {{ 'page.work_time_management.accept' | translate }}
  }@else{
  <span class="fas fa-check-circle me-1"></span>
  {{ 'page.work_time_management.accept' | translate }}
  }
</button>
