import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  faProlongEnd,
  faProlongStart,
  faPushUnder,
  faPushUnderSingle,
  faInsert,
  faPushAbove,
  faPushAboveSingle,
} from '@awesome.me/kit-d87edcad8c/icons/kit/custom';

@Component({
  selector: 'wilson-update-time-popover',
  templateUrl: './update-time-popover.component.html',
  styleUrls: ['./update-time-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateTimePopoverComponent {
  readonly faPushUnder = faPushUnder;
  readonly faPushUnderSingle = faPushUnderSingle;
  readonly faProlongEnd = faProlongEnd;
  readonly faProlongStart = faProlongStart;
  readonly faInsert = faInsert;
  readonly faPushAbove = faPushAbove;
  readonly faPushAboveSingle = faPushAboveSingle;

  @Input() activityFormArray!: FormArray;
  @Input() currentIndex!: number;
  @Input() canEditServiceActivity!: boolean;

  @Output() insertActivity = new EventEmitter();
  @Output() pushActivity = new EventEmitter();
  @Output() pullActivity = new EventEmitter();
  @Output() pushActivities = new EventEmitter();
  @Output() pullActivities = new EventEmitter();
  @Output() prolongActivityEndTime = new EventEmitter();
  @Output() prolongActivityStartTime = new EventEmitter();

  constructor(private readonly translate: TranslateService) {}

  get currentActivityCategoryName(): string {
    const name = this.activityFormArray
      ?.at(this.currentIndex)
      ?.get('activityCategory')?.value?.name;
    return name ? this.translate.instant(`activity_category.${name}`) : '';
  }

  get nextActivityCategoryName(): string {
    const name = this.activityFormArray
      ?.at(this.currentIndex + 1)
      ?.get('activityCategory')?.value?.name;
    return name ? this.translate.instant(`activity_category.${name}`) : '';
  }

  triggerPushActivity(): void {
    this.pushActivity.emit();
  }

  triggerPullActivity(): void {
    this.pullActivity.emit();
  }

  triggerInsertActivity(): void {
    this.insertActivity.emit();
  }

  triggerProlongActivityEnd(): void {
    this.prolongActivityEndTime.emit();
  }

  triggerProlongActivityStart(): void {
    this.prolongActivityStartTime.emit();
  }

  triggerPushActivities(): void {
    this.pushActivities.emit();
  }

  triggerPullActivities(): void {
    this.pullActivities.emit();
  }
}
