import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigService, ConfigOptions } from '@wilson/config';
import { QualificationCategoryV2 } from '@wilson/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QualificationCategoryV2Service {
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  public getCategories(): Observable<QualificationCategoryV2[]> {
    return this.http.get<QualificationCategoryV2[]>(
      `${this.config.host}/qualifications/categories`,
    );
  }
}
