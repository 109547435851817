import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Route } from '@wilson/interfaces';
import { stringify } from 'qs';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable()
export class RoutesService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  public getRoute(id: string): Observable<Route> {
    return this.httpClient.get<Route>(`${this.config.host}/routes/${id}`);
  }

  public getRouteById({
    id,
    relations,
    withViaLocations,
  }: {
    id: string;
    relations: string[];
    withViaLocations?: boolean;
  }): Observable<Route> {
    const queryParams = {
      relations,
      withViaLocations,
    };

    const queryString = stringify(queryParams, {
      arrayFormat: 'brackets',
      addQueryPrefix: true,
    });

    return this.httpClient.get<Route>(
      `${this.config.host}/routes/${id}${queryString}`,
    );
  }

  public searchRoute(conditions: Partial<Route>): Observable<Route[]> {
    return this.httpClient.post<Route[]>(
      `${this.config.host}/routes/search`,
      conditions,
    );
  }

  public createRoutes(routes: Route): Promise<Route[] | undefined> {
    return firstValueFrom(
      this.httpClient.post<Route[]>(`${this.config.host}/routes`, {
        items: [routes],
      }),
    );
  }

  public getAllRoutesById(rootId: string): Observable<Route[]> {
    return this.httpClient.post<Route[]>(
      `${this.config.host}/routes/resolved`,
      {
        organizationalUnitId: rootId,
      },
    );
  }
}
