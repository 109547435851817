<div class="details-container" [ngClass]="{ 'timeline-view': timelineView }">
  <h6 *ngIf="!timelineView" class="mb-3 title">
    {{ 'tooltip.subheader.qualifications_and_professions' | translate }}
  </h6>
  <ng-container
    *ngIf="
      !!qualificationNames?.length || !!professionValidations?.length;
      else emptyStateQualification
    "
  >
    <div class="mb-3" *ngFor="let type of groupedQualifications | keyvalue">
      <p *ngIf="!timelineView" class="text-uppercase mb-1">
        <strong>{{ type.key | translate }}</strong>
      </p>
      <div
        class="d-flex align-items-center qualification-item"
        *ngFor="let qualification of type.value"
      >
        <fa-icon
          *ngIf="qualification.valid"
          class="icon validIcon"
          [icon]="faValidIcon"
        ></fa-icon>
        <fa-icon
          *ngIf="!qualification.valid"
          class="icon inValidIcon"
          [icon]="faInValidIcon"
        ></fa-icon>
        <p
          class="m-0"
          [ngClass]="timelineView ? 'timeline-item-text' : 'item-text'"
        >
          {{ qualification.title }}
        </p>
      </div>
    </div>
    <div class="mb-3" *ngFor="let profession of professionValidations">
      <p *ngIf="!timelineView" class="text-uppercase mb-1">
        <strong>{{ 'general.profession' | translate }}</strong>
      </p>
      <div class="d-flex align-items-center qualification-item">
        <fa-icon
          *ngIf="profession.valid"
          class="icon validIcon"
          [icon]="faValidIcon"
        ></fa-icon>
        <fa-icon
          *ngIf="!profession.valid"
          class="icon inValidIcon"
          [icon]="faInValidIcon"
        ></fa-icon>
        <p
          class="m-0"
          [ngClass]="timelineView ? 'timeline-item-text' : 'item-text'"
        >
          {{ 'enum.shift_validation.' + profession.checkName | translate }}
        </p>
      </div>
    </div>
  </ng-container>
  <ng-template #emptyStateQualification>
    <span class="no-qualifications">
      {{
        'tooltip.subheader.no_qualifications_or_professions_deposited'
          | translate
      }}
    </span>
  </ng-template>
</div>
