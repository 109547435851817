import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { RxFor } from '@rx-angular/template/for';
import { TranslateActivityCategoryPipeModule } from '@wilson/activities/pipes';
import {
  activitiesCompareFn,
  reportedActivitiesCompareFn,
} from '@wilson/activities/util';
import { AuthState } from '@wilson/auth/core';
import { AuthorizationModule } from '@wilson/authorization';
import {
  Activity,
  ActivityFilter,
  ActivityWithCategoryAndLocationsAndReportedDetails,
  OperationStatus,
  RoleAction,
  RoleIDsMap,
  RolePermissionSubject,
  Shift,
  ShiftState,
  WilsonApp,
  WithPreparedAttributes,
} from '@wilson/interfaces';
import { PipesModule } from '@wilson/pipes';
import { DateTimeFormat } from '@wilson/utils';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { map, Observable } from 'rxjs';
import { ActivitiesSettingButtonComponent } from './activities-setting-button/activities-setting-button.component';
import { DeleteActivityButtonComponent } from './delete-activity-button/delete-activity-button.component';
import { ActivityEndDatetimePipe } from './pipes/activity-end-datetime.pipe';
import { ActivityEndLocationPipe } from './pipes/activity-end-location.pipe';
import { ActivityStartDatetimePipe } from './pipes/activity-start-datetime.pipe';
import { ActivityStartLocationPipe } from './pipes/activity-start-location.pipe';
import { AddActivityForbiddenMessagePipe } from './pipes/add-activity-forbidden-message.pipe';
import { AddActivityHelperService } from './services/add-activity-helper.service';
import { DeleteActivityHelperService } from './services/delete-activity-helper.service';

@Component({
  selector: 'wilson-collapsible-activities-list',
  standalone: true,
  imports: [
    CommonModule,
    NzCollapseModule,
    TranslateModule,
    NzTableModule,
    TranslateActivityCategoryPipeModule,
    RxFor,
    NzToolTipModule,
    FontAwesomeModule,
    FormsModule,
    NzSegmentedModule,
    NzTagModule,
    ActivityStartDatetimePipe,
    ActivityEndDatetimePipe,
    ActivityStartLocationPipe,
    ActivityEndLocationPipe,
    NzEmptyModule,
    PipesModule,
    NzButtonModule,
    AuthorizationModule,
    RouterModule,
    DeleteActivityButtonComponent,
    AddActivityForbiddenMessagePipe,
    NzDropDownModule,
    NzSwitchModule,
    ActivitiesSettingButtonComponent,
  ],
  providers: [AddActivityHelperService, DeleteActivityHelperService],
  templateUrl: './collapsible-activities-list.component.html',
  styleUrl: './collapsible-activities-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleActivitiesListComponent implements OnInit, OnChanges {
  @Input() isExpandedOnOpen = false;
  @Input({ required: true }) shift: Shift &
    WithPreparedAttributes & {
      id: string;
      activities: ActivityWithCategoryAndLocationsAndReportedDetails[];
    };

  @Output() refresh = new EventEmitter<void>();
  @Output() delete = new EventEmitter<Activity>();

  readonly faPlus = faPlus;

  readonly DateTimeFormat = DateTimeFormat;
  readonly WilsonApp = WilsonApp;
  readonly OperationStatus = OperationStatus;
  readonly ActivityFilter = ActivityFilter;

  protected filteredActivities!: ActivityWithCategoryAndLocationsAndReportedDetails[];
  protected filterOptions = [
    {
      label: this.translate.instant('page.shift.activity_filter.all'),
      value: ActivityFilter.All,
    },
    {
      label: this.translate.instant('page.shift.activity_filter.planned'),
      value: ActivityFilter.Planned,
    },
    {
      label: this.translate.instant('page.shift.activity_filter.reported'),
      value: ActivityFilter.Reported,
    },
  ];
  protected selectedActivityFilterIndex = 0;
  protected selectedActivityFilter =
    this.filterOptions[this.selectedActivityFilterIndex];
  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  protected isShiftAcceptedOrSubmittedToPayrollProviderSignal = signal(false);
  protected isShiftSubmittedSignal = signal(false);
  protected isShiftStartedSignal = signal(false);
  protected isSavingSignal = signal(false);
  protected highlightedActivityIdsSignal = signal<string[]>([]);
  protected isAutoAdaptActivitiesEnabled = true;
  private previousActivities: ActivityWithCategoryAndLocationsAndReportedDetails[] =
    [];
  protected activities: ActivityWithCategoryAndLocationsAndReportedDetails[] =
    [];
  protected isAdminUser$!: Observable<boolean | undefined>;

  constructor(
    private readonly store: Store,
    private readonly translate: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly addActivityHelperService: AddActivityHelperService,
  ) {}

  ngOnInit(): void {
    this.isAdminUser$ = this.store.select(AuthState.userRoles).pipe(
      map((userRoles) => {
        return userRoles?.some(
          (userRole) => userRole.roleId === RoleIDsMap.CompanyAdmin,
        );
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['shift']) {
      this.activities = this.shift.activities || [];
      this.computeSelectedActivityFilter(this.shift.state);
      this.computeHighlightedActivityIds();
      this.computeFilteredActivities();
    }
  }

  private computeSelectedActivityFilter(state: ShiftState): void {
    this.isShiftAcceptedOrSubmittedToPayrollProviderSignal.set(
      state === ShiftState.AcceptedTimes ||
        state === ShiftState.SubmittedToPayrollProvider,
    );
    this.isShiftSubmittedSignal.set(state === ShiftState.Submitted);
    this.isShiftStartedSignal.set(
      !this.shift.activities.every(
        (activity) => activity.operationalStatus === OperationStatus.NotStarted,
      ),
    );
    if (state === ShiftState.NotSubmitted) {
      this.selectedActivityFilterIndex = this.filterOptions.findIndex(
        (option) => option.value === ActivityFilter.Planned,
      );
    } else if (
      state === ShiftState.Submitted ||
      state === ShiftState.Reopened ||
      state === ShiftState.AcceptedTimes ||
      state === ShiftState.SubmittedToPayrollProvider
    ) {
      this.selectedActivityFilterIndex = this.filterOptions.findIndex(
        (option) => option.value === ActivityFilter.Reported,
      );
    } else {
      this.selectedActivityFilterIndex = this.filterOptions.findIndex(
        (option) => option.value === ActivityFilter.All,
      );
    }
    this.selectedActivityFilter =
      this.filterOptions[this.selectedActivityFilterIndex];
  }

  private computeHighlightedActivityIds(): void {
    this.highlightedActivityIdsSignal.set(
      this.getHighlightedActivityIds(this.previousActivities, this.activities),
    );
    this.previousActivities = [...this.activities];
    setTimeout(() => {
      this.highlightedActivityIdsSignal.set([]);
    }, 2000);
  }

  private computeFilteredActivities(): void {
    if (this.selectedActivityFilter.value === ActivityFilter.All) {
      this.filteredActivities = this.activities;
      this.filteredActivities =
        this.filteredActivities.sort(activitiesCompareFn);
    } else if (this.selectedActivityFilter.value === ActivityFilter.Planned) {
      this.filteredActivities = this.activities.filter(
        (activity) => activity.createdFrom === WilsonApp.Portal,
      );
      this.filteredActivities =
        this.filteredActivities.sort(activitiesCompareFn);
    } else if (this.selectedActivityFilter.value === ActivityFilter.Reported) {
      this.filteredActivities = this.activities.filter(
        (activity) => activity.activityReports.length,
      );
      this.filteredActivities = this.filteredActivities.sort(
        reportedActivitiesCompareFn,
      );
    }
    this.changeDetectorRef.detectChanges();
  }

  protected onActivityFilterChange(selectedIndex: number): void {
    this.selectedActivityFilterIndex = selectedIndex;
    this.selectedActivityFilter =
      this.filterOptions[this.selectedActivityFilterIndex];
    this.computeFilteredActivities();
  }

  protected async addActivity(): Promise<void> {
    this.isSavingSignal.set(true);
    const isAdded = await this.addActivityHelperService.addActivity(
      this.activities,
      this.shift,
      this.isShiftSubmittedSignal(),
    );
    if (isAdded) {
      this.refresh.emit();
    }
    this.isSavingSignal.set(false);
  }

  protected deleteActivity(activity: Activity): void {
    this.delete.emit(activity);
    this.refresh.emit();
  }

  private getHighlightedActivityIds(
    previousActivities: ActivityWithCategoryAndLocationsAndReportedDetails[],
    activities: ActivityWithCategoryAndLocationsAndReportedDetails[],
  ): string[] {
    let modifiedActivityIds: string[] = [];
    if (previousActivities.length > 0) {
      modifiedActivityIds = activities
        .filter((activity) => {
          const previousActivity = previousActivities.find(
            (a) => a.id === activity.id,
          );
          return (
            previousActivity?.startDatetime !== activity.startDatetime ||
            previousActivity?.endDatetime !== activity.endDatetime ||
            previousActivity?.reportedStartDatetime !==
              activity.reportedStartDatetime ||
            previousActivity?.reportedEndDatetime !==
              activity.reportedEndDatetime
          );
        })
        .map((activity) => activity.id);
    }
    return modifiedActivityIds;
  }
}
