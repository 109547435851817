import { StateContext, StateOperator } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import * as Sentry from '@sentry/capacitor';
import { Base } from '@wilson/base';
import {
  Absence,
  AccurateDateIntervalWithAbsence,
  AccurateDateIntervalWithShift,
  AccurateDateIntervalWithStay,
  DetermineAssignmentsOverlapWorkerWrapper,
  Shift,
  Stay,
  TimelineShift,
  UserTimelines,
  UserTimelinesWithoutActivitiesPayload,
  WithPreparedAttributes,
} from '@wilson/interfaces';
import {
  consoleInfo,
  isDebugEnabled,
} from '@wilson/non-domain-specific/logger/logger';
import {
  calculateUserAssignmentStack,
  determineAssignmentsOverlaps,
} from '@wilson/non-domain-specific/overlap-helpers';
import { joinById } from '@wilson/utils';
import { eachDayOfInterval } from 'date-fns';
import { cloneDeep } from 'lodash';
import {
  AbsenceId,
  ShiftId,
  ShiftTimelineDataStateModel,
  UserId,
} from '../interfaces';

export function zipRecords<T extends Base>(
  newData: Record<string, T>,
): StateOperator<Record<string, T>> {
  return (state: Readonly<Record<string, T>>) => {
    return Object.entries(newData).reduce(
      (acc: Record<string, T>, [newDataKey, newData]) => {
        acc[newDataKey] = newData;
        return acc;
      },
      {
        ...state,
      },
    );
  };
}
export function zipNestedRecords<T extends Base>(
  newData: Record<string, Record<string, T>[]>,
): StateOperator<Record<string, Record<string, T>[]>> {
  return (state: Readonly<Record<string, Record<string, T>[]>>) => {
    return Object.entries(newData).reduce(
      (
        acc: Record<string, Record<string, T>[]>,
        [newDataKey, newDataValue],
      ) => {
        acc[newDataKey] = newDataValue;
        return acc;
      },
      {
        ...state,
      },
    );
  };
}

export function zipItem<T extends Base>(newData: T[]): StateOperator<T[]> {
  return (state: Readonly<T[]>) => {
    const stateClone = [...state];
    return joinById(stateClone, newData);
  };
}

export function clearLoadingUserIds(
  ctx: StateContext<ShiftTimelineDataStateModel>,
  userIds: string[],
): void {
  const previousIds = ctx
    .getState()
    .usersWithUpdatingValidations.filter((userId) => !userIds.includes(userId));

  ctx.patchState({
    usersWithUpdatingShiftAssignments: previousIds,
    isLoadingUsers: false,
  });
}

export async function updateRowOverlapDataSyncOrAssignmentsStack({
  ctx,
  userIds,
  visibleDateRange,
  isCellView,
}: {
  ctx: StateContext<ShiftTimelineDataStateModel>;
  userIds: string[];
  visibleDateRange: {
    start: Date | null;
    end: Date | null;
  };
  isCellView: boolean;
}): Promise<void> {
  const { usersDictionary, dateToUserIdAssignmentIdRecord } = ctx.getState();

  // We only calculate overlap based on current range
  const filteredUsersTimelineData = getDataInRange({
    usersDictionary,
    userIds,
    visibleDateRange,
    dateToUserIdAssignmentIdRecord,
  });

  if (isCellView) {
    determineAssignmentStack(filteredUsersTimelineData, ctx.setState);
  } else {
    const userRowsData: {
      userId: string;
      rows: {
        shifts: AccurateDateIntervalWithShift[];
        absences: AccurateDateIntervalWithAbsence[];
        stays: AccurateDateIntervalWithStay[];
      }[];
      resultTimestamp: number;
    }[] = filteredUsersTimelineData.map(
      ({
        id,
        shiftsWithoutActivitiesDictionary,
        absencesDictionary,
        staysDictionary,
      }) => {
        const absences = Object.values(absencesDictionary);
        const shifts = Object.values(shiftsWithoutActivitiesDictionary);
        const stays = Object.values(staysDictionary);
        return {
          userId: id as string,
          rows: determineAssignmentsOverlaps({
            shifts,
            absences,
            stays,
            isDebugEnabled: isDebugEnabled(),
          }),
          resultTimestamp: new Date().getTime(),
        };
      },
    );

    updateRowOverlapDataInState(userRowsData, usersDictionary, ctx.setState);
  }
}

export async function updateRowOverlapOrAssignmentStacks({
  determineAssignmentsOverlapWorkerWrapper,
  ctx,
  userIds,
  visibleDateRange,
  isCellView,
}: {
  determineAssignmentsOverlapWorkerWrapper: DetermineAssignmentsOverlapWorkerWrapper;
  ctx: StateContext<ShiftTimelineDataStateModel>;
  userIds: string[];
  visibleDateRange: {
    start: Date | null;
    end: Date | null;
  };
  isCellView: boolean;
}): Promise<void> {
  const { usersDictionary, dateToUserIdAssignmentIdRecord } = ctx.getState();

  // We only calculate overlap based on current range
  const filteredUsersTimelineData = getDataInRange({
    usersDictionary,
    userIds,
    visibleDateRange,
    dateToUserIdAssignmentIdRecord,
  });
  if (isCellView) {
    determineAssignmentStack(filteredUsersTimelineData, ctx.setState);
  } else {
    const userRowsData: {
      userId: string;
      rows: {
        shifts: AccurateDateIntervalWithShift[];
        absences: AccurateDateIntervalWithAbsence[];
        stays: AccurateDateIntervalWithStay[];
      }[];
      resultTimestamp: number;
    }[] = await determineAssignmentsOverlapWorkerWrapper.execute(
      filteredUsersTimelineData,
    );
    updateRowOverlapDataInState(userRowsData, usersDictionary, ctx.setState);
  }
}

function determineAssignmentStack(
  filteredUsersTimelineData: UserTimelines[],
  setState: StateContext<ShiftTimelineDataStateModel>['setState'],
): void {
  const userTimelineChangeInstructions = filteredUsersTimelineData.reduce(
    (
      instructions: Record<string, StateOperator<UserTimelines>>,
      userTimeline,
    ) => {
      const usersAssignmentStacks = calculateUserAssignmentStack(
        userTimeline,
        isDebugEnabled(),
      );

      instructions[userTimeline.id as string] = patch({
        assignmentStacks: usersAssignmentStacks,
      });
      return instructions;
    },
    {},
  );

  setState(
    patch({
      usersDictionary: patch(userTimelineChangeInstructions),
    }),
  );
}

function updateRowOverlapDataInState(
  userRowsData: {
    userId: string;
    rows: {
      shifts: AccurateDateIntervalWithShift[];
      absences: AccurateDateIntervalWithAbsence[];
      stays: AccurateDateIntervalWithStay[];
    }[];
    resultTimestamp: number;
  }[],
  usersDictionary: ShiftTimelineDataStateModel['usersDictionary'],
  setState: StateContext<ShiftTimelineDataStateModel>['setState'],
): void {
  const patchInstruction = userRowsData.reduce(
    (instructions: Record<string, StateOperator<UserTimelines>>, userRows) => {
      const userFromState = usersDictionary[userRows.userId];
      if (userFromState.determinedRowDataTimestamp < userRows.resultTimestamp) {
        instructions[userRows.userId] = patch({
          determinedRowDataV2: userRows.rows,
          determinedRowDataTimestamp: userRows.resultTimestamp,
        });
      }

      return instructions;
    },
    {},
  );

  setState(
    patch({
      usersDictionary: patch(patchInstruction),
    }),
  );
}

export function getDataInRange({
  dateToUserIdAssignmentIdRecord,
  usersDictionary,
  userIds,
  visibleDateRange,
}: {
  dateToUserIdAssignmentIdRecord: ShiftTimelineDataStateModel['dateToUserIdAssignmentIdRecord'];
  usersDictionary: Record<string, UserTimelines>;
  userIds: string[];
  visibleDateRange: {
    start: Date | null;
    end: Date | null;
  };
}): UserTimelines[] {
  const dataInterval = eachDayOfInterval({
    start: new Date(visibleDateRange.start as Date),
    end: new Date(visibleDateRange.end as Date),
  });

  const filteredUsersTimelineData = userIds.map((userId) => {
    const filteredUser: UserTimelines = cloneDeep(usersDictionary[userId]);
    filteredUser.absencesDictionary = {};
    filteredUser.shiftsWithoutActivitiesDictionary = {};
    filteredUser.staysDictionary = {};

    const {
      shiftsWithoutActivitiesDictionary,
      absencesDictionary,
      staysDictionary,
    } = usersDictionary[userId];
    dataInterval.forEach((date) => {
      const dateKey = getDateKey(date);
      const assignmentsInViewForUser =
        dateToUserIdAssignmentIdRecord[dateKey]?.[userId];

      if (assignmentsInViewForUser) {
        Object.keys(assignmentsInViewForUser).forEach((assignmentId) => {
          if (assignmentId in shiftsWithoutActivitiesDictionary) {
            filteredUser.shiftsWithoutActivitiesDictionary[assignmentId] =
              cloneDeep(shiftsWithoutActivitiesDictionary[assignmentId]);
          } else if (assignmentId in absencesDictionary) {
            filteredUser.absencesDictionary[assignmentId] = cloneDeep(
              absencesDictionary[assignmentId],
            );
          } else if (assignmentId in staysDictionary) {
            filteredUser.staysDictionary[assignmentId] = cloneDeep(
              staysDictionary[assignmentId],
            );
          }
        });
      }
    });

    return filteredUser;
  });

  return filteredUsersTimelineData;
}

/**
 * Update in place
 */
export function updateInPlaceDateToShiftIdRecord({
  shift,
  dateToAssignmentIdsRecord,
  cleanUpRange,
}: {
  shift: Shift &
    WithPreparedAttributes & {
      id: string;
    };
  dateToAssignmentIdsRecord: ShiftTimelineDataStateModel['dateToUserIdAssignmentIdRecord'];
  cleanUpRange: {
    start: Date;
    end: Date;
  };
}): void {
  removeInPlaceIdInRange({
    assignmentId: shift.id,
    dateToAssignmentIdsRecord,
    cleanUpRange,
  });

  try {
    eachDayOfInterval({
      start: new Date(shift.startDatetime),
      end: new Date(shift.endDatetime),
    }).forEach((dateInInterval) => {
      const dateKey = getDateKey(dateInInterval);
      dateToAssignmentIdsRecord[dateKey] = {
        ...dateToAssignmentIdsRecord[dateKey],
        [shift.userId as string]: {
          ...dateToAssignmentIdsRecord[dateKey]?.[shift.userId as string],
          [shift.id]: undefined,
        },
      };
    });
  } catch (e) {
    console.error(e);
    consoleInfo(JSON.stringify(shift));
  }
}

/**
 * Update in place
 */
export function updateInPlaceDateToAbsenceIdRecord({
  absence,
  dateToAssignmentIdsRecord,
  cleanUpRange,
}: {
  absence: Absence & { id: string };
  dateToAssignmentIdsRecord: ShiftTimelineDataStateModel['dateToUserIdAssignmentIdRecord'];
  cleanUpRange: {
    start: Date;
    end: Date;
  };
}): void {
  removeInPlaceIdInRange({
    assignmentId: absence.id,
    dateToAssignmentIdsRecord,
    cleanUpRange,
  });
  const absenceStart = new Date(absence.absentFrom);
  const absenceEnd = new Date(absence.absentTo);

  const isAbsenceEndingBeforeCleanUpRange =
    absenceEnd.getTime() < cleanUpRange.start.getTime();
  const isAbsenceStartingAfterCleanUpRange =
    absenceStart.getTime() > cleanUpRange.end.getTime();

  if (
    !isAbsenceEndingBeforeCleanUpRange &&
    !isAbsenceStartingAfterCleanUpRange
  ) {
    try {
      const startInterval =
        absenceStart.getTime() < cleanUpRange.start.getTime()
          ? cleanUpRange.start
          : absenceStart;
      const endInterval =
        absenceEnd.getTime() > cleanUpRange.end.getTime()
          ? cleanUpRange.end
          : absenceEnd;

      eachDayOfInterval({
        start: startInterval,
        end: endInterval,
      }).forEach((dateInInterval) => {
        const dateKey = getDateKey(dateInInterval);
        dateToAssignmentIdsRecord[dateKey] = {
          ...dateToAssignmentIdsRecord[dateKey],
          [absence.userId]: {
            ...dateToAssignmentIdsRecord[dateKey]?.[absence.userId],
            [absence.id]: undefined,
          },
        };
      });
    } catch (e) {
      console.error(e);
      consoleInfo('cleanUpRange:' + cleanUpRange);
      consoleInfo(JSON.stringify(absence));
      Sentry.captureException(e, {
        level: 'error',
        tags: {
          assignmentObject: JSON.stringify(absence),
          cleanUpRange: JSON.stringify(cleanUpRange),
        },
      });
    }
  }
}

/**
 * Update in place
 */
export function updateInPlaceDateToStayIdRecord({
  stay,
  dateToAssignmentIdsRecord,
  cleanUpRange,
}: {
  stay: Stay;
  dateToAssignmentIdsRecord: ShiftTimelineDataStateModel['dateToUserIdAssignmentIdRecord'];
  cleanUpRange: {
    start: Date;
    end: Date;
  };
}): void {
  removeInPlaceIdInRange({
    assignmentId: stay.id,
    dateToAssignmentIdsRecord,
    cleanUpRange,
  });
  try {
    eachDayOfInterval({
      start: new Date(stay.startDatetime),
      end: new Date(stay.endDatetime),
    }).forEach((dateInInterval) => {
      const dateKey = getDateKey(dateInInterval);
      dateToAssignmentIdsRecord[dateKey] = {
        ...dateToAssignmentIdsRecord[dateKey],
        [stay.userId]: {
          ...dateToAssignmentIdsRecord[dateKey]?.[stay.userId],
          [stay.id]: undefined,
        },
      };
    });
  } catch (e) {
    console.error(e);
    consoleInfo(JSON.stringify(stay));
  }
}

function removeInPlaceIdInRange({
  assignmentId,
  dateToAssignmentIdsRecord,
  cleanUpRange,
}: {
  assignmentId: string;
  dateToAssignmentIdsRecord: ShiftTimelineDataStateModel['dateToUserIdAssignmentIdRecord'];
  cleanUpRange: {
    start: Date;
    end: Date;
  };
}): void {
  try {
    eachDayOfInterval(cleanUpRange).forEach((dateInInterval) => {
      const dateKey = getDateKey(dateInInterval);
      const userAssignments = dateToAssignmentIdsRecord[dateKey];
      if (userAssignments) {
        for (const userId in userAssignments) {
          if (assignmentId in userAssignments[userId]) {
            delete userAssignments[userId][assignmentId];
          }
        }
      }
    });
  } catch (e) {
    console.error(e);
    consoleInfo(JSON.stringify(cleanUpRange));
  }
}

export function removeInPlaceFromUserInDateToAssignmentIdRecord(
  assignmentId: string,
  userId: string,
  dateToAssignmentIdsMap: ShiftTimelineDataStateModel['dateToUserIdAssignmentIdRecord'],
): void {
  Object.keys(dateToAssignmentIdsMap).forEach((dateKey) => {
    if (userId in dateToAssignmentIdsMap[dateKey]) {
      delete dateToAssignmentIdsMap[dateKey][userId][assignmentId];
    }
  });
}

function getDateKey(date: Date): string {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function reduceIncludingUnmatchingElements(
  users: UserTimelinesWithoutActivitiesPayload[],
  dateToAssignmentIdsRecord: Record<
    string,
    Record<UserId, Record<ShiftId | AbsenceId, undefined>>
  >,
  correctedInterval: { start: Date; end: Date },
): Record<string, StateOperator<UserTimelines>> {
  return users.reduce(
    (
      instructions: Record<string, StateOperator<UserTimelines>>,
      userTimeLineData,
    ) => {
      const newShiftsWithoutActivitiesRecord: Record<ShiftId, TimelineShift> =
        {};
      userTimeLineData.shifts.forEach((shift) => {
        newShiftsWithoutActivitiesRecord[shift.id] = shift;
        updateInPlaceDateToShiftIdRecord({
          shift,
          dateToAssignmentIdsRecord,
          cleanUpRange: {
            start: correctedInterval.start,
            end: correctedInterval.end,
          },
        });
      });

      const newAbsences: Record<
        AbsenceId,
        Absence & { id: string; isHighlighted: boolean }
      > = {};
      userTimeLineData.absences.forEach((absence) => {
        newAbsences[absence.id] = absence;
        updateInPlaceDateToAbsenceIdRecord({
          absence,
          dateToAssignmentIdsRecord,
          cleanUpRange: {
            start: correctedInterval.start,
            end: correctedInterval.end,
          },
        });
      });

      instructions[userTimeLineData.id as string] = patch({
        shiftsWithoutActivitiesDictionary: zipRecords(
          newShiftsWithoutActivitiesRecord,
        ),
        absencesDictionary: zipRecords(newAbsences),
      });

      return instructions;
    },
    {},
  );
}
