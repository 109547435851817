<button
  nz-button
  nzType="default"
  class="me-2"
  nz-dropdown
  nzTrigger="click"
  [nzDropdownMenu]="menu"
>
  <fa-icon [icon]="faGear"></fa-icon>
</button>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <div nz-menu class="p-3 d-flex align-items-center">
    <span class="me-3">
      {{
        'page.shift_timeline.shift_drawer.activity.auto_adapt_activities'
          | translate
      }}
    </span>
    <nz-switch
      [ngModel]="isAutoAdaptActivitiesEnabled"
      [nzCheckedChildren]="checkedTemplate"
      [nzUnCheckedChildren]="unCheckedTemplate"
      (ngModelChange)="isAutoAdaptActivitiesEnabledChange.emit($event)"
    ></nz-switch>
    <ng-template #checkedTemplate>
      <fa-icon [icon]="faCheck" />
    </ng-template>
    <ng-template #unCheckedTemplate>
      <fa-icon [icon]="faXmark" />
    </ng-template>
  </div>
</nz-dropdown-menu>
