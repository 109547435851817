import { Injectable } from '@angular/core';
import { WilsonState } from '@wilson/non-domain-specific/decorators/wilson-state';
import { Observable } from 'rxjs';
import { ShiftId, UserId } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class ShiftTimelineVisibilityStateService {
  @WilsonState(new Set())
  private visibleShiftIdsSet!: Set<ShiftId>;
  visibleShiftIdsSet$!: Observable<Set<ShiftId>>;

  @WilsonState(new Set())
  private visibleUserIdsSet!: Set<UserId>;
  visibleUserIdsSet$!: Observable<Set<UserId>>;

  updateUserVisibility(isVisible: boolean, userId: UserId): void {
    let newSet: Set<string>;
    if (isVisible) {
      if (!this.visibleUserIdsSet.has(userId)) {
        newSet = new Set(this.visibleUserIdsSet);
        newSet.add(userId);
        this.visibleUserIdsSet = newSet;
      }
    } else {
      if (this.visibleUserIdsSet.has(userId)) {
        newSet = new Set(this.visibleUserIdsSet);
        newSet.delete(userId);
        this.visibleUserIdsSet = newSet;
      }
    }
  }

  updateShiftVisibility(isVisible: boolean, shiftId: ShiftId): void {
    let newSet: Set<string>;
    if (isVisible) {
      if (!this.visibleShiftIdsSet.has(shiftId)) {
        newSet = new Set(this.visibleShiftIdsSet);
        newSet.add(shiftId);
        this.visibleShiftIdsSet = newSet;
      }
    } else {
      if (this.visibleShiftIdsSet.has(shiftId)) {
        newSet = new Set(this.visibleShiftIdsSet);
        newSet.delete(shiftId);
        this.visibleShiftIdsSet = newSet;
      }
    }
  }

  clearAllShiftVisibilityRecord(): void {
    this.visibleShiftIdsSet = new Set();
  }
}
