import { Pipe, PipeTransform } from '@angular/core';
import { GeoLocation } from '@wilson/interfaces';
import { Observable, of } from 'rxjs';
import { LocationsStoreService } from '../../locations-store.service';

@Pipe({
  name: 'locationIdToLocation',
  standalone: true,
})
export class LocationIdToLocationPipe implements PipeTransform {
  constructor(private readonly locationStoreService: LocationsStoreService) {}

  transform(
    id: string | undefined,
  ): Observable<GeoLocation | undefined | null> {
    if (!id) {
      return of(undefined);
    }
    return this.locationStoreService.getLocationFromCache(id);
  }
}
