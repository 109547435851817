import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import {
  QualificationName,
  ShiftValidationDetails,
  ValidationCheckCategory,
} from '@wilson/interfaces';
import { ShiftValidationErrorService } from '@wilson/shifts';
import { TooltipModule } from '@wilson/tooltip';
import { combineLatest, map, Observable, of } from 'rxjs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerModule } from '@wilson/shared-components';
import { TimelineShiftValidationTooltipComponent } from '@wilson/shift-validations/core';

@Component({
  selector: 'wilson-shift-quick-check',
  templateUrl: './shift-quick-check.component.html',
  styleUrls: ['./shift-quick-check.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    TooltipModule,
    NzToolTipModule,
    LoadingSpinnerModule,
    TimelineShiftValidationTooltipComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftQuickCheckComponent implements OnChanges {
  @Input() shiftValidationDetails!: ShiftValidationDetails[];
  @Input() isUserAssigned = false;

  areShiftContentsSufficient = false;
  areWorkingTimeRulesSufficient = false;
  areQualificationsSufficient = false;
  isLoading = true;
  workingTimeValidation$: Observable<ShiftValidationDetails[] | undefined> = of(
    [],
  );
  workingTimeValidation!: ShiftValidationDetails[];
  qualificationNames: QualificationName[] = [];
  professionValidations$!: Observable<ShiftValidationDetails[] | undefined>;
  qualificationsAndProfessions: ShiftValidationDetails[] | QualificationName[] =
    [];
  shiftLogicValidations: ShiftValidationDetails[] = [];

  constructor(
    private readonly shiftValidationErrorService: ShiftValidationErrorService,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
  ) {}

  ngOnChanges(): void {
    if (this.shiftValidationDetails) {
      this.isLoading = true;
      this.areQualificationsSufficient = !this.shiftValidationDetails.some(
        (validation) =>
          validation.category === ValidationCheckCategory.Qualifications &&
          !validation.valid,
      );
      this.areWorkingTimeRulesSufficient = !this.shiftValidationDetails.some(
        (validation) =>
          validation.category === ValidationCheckCategory.WorkingTimeRules &&
          !validation.valid,
      );
      this.areShiftContentsSufficient = !this.shiftValidationDetails.some(
        (validation) =>
          validation.category === ValidationCheckCategory.ShiftLogic &&
          !validation.valid,
      );
      this.isLoading = false;
    }

    const isUserApprovalEnabled$ = this.featureFlagPurePipe.transform(
      'portal-shift-validation-user-approval',
    );

    this.workingTimeValidation$ = combineLatest([
      isUserApprovalEnabled$,
      this.shiftValidationDetails,
    ]).pipe(
      map(([isUserApprovalEnabled]) => {
        if (!this.shiftValidationDetails) return [];
        return ShiftValidationErrorService.getShiftWorkingTimeValidation(
          this.shiftValidationDetails,
          isUserApprovalEnabled,
        );
      }),
    );

    this.qualificationNames =
      this.shiftValidationErrorService.getQualificationName(
        this.shiftValidationDetails,
      );

    this.shiftLogicValidations =
      this.shiftValidationErrorService.getShiftLogicValidation(
        this.shiftValidationDetails,
      );

    const isProfessionCheckEnabled$ = this.featureFlagPurePipe.transform(
      'portal-shift-validation-user-professions',
    );

    this.professionValidations$ = combineLatest([
      isProfessionCheckEnabled$,
      this.shiftValidationDetails,
    ]).pipe(
      map(([isProfessionEnabled]) => {
        if (!this.shiftValidationDetails) return [];
        return this.shiftValidationErrorService.getShiftProfessionValidation(
          this.shiftValidationDetails,
          isProfessionEnabled,
        );
      }),
    );
  }
}
