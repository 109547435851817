import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Sector, ShiftCategory } from '@wilson/interfaces';
import { SectorState } from '@wilson/sectors';
import { ShiftCategoryService } from '@wilson/shift-category';
import { Observable, filter, of, shareReplay, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShiftDropdownOptionsService {
  private _shiftCategories$: Observable<ShiftCategory[]>;
  constructor(
    private readonly store: Store,
    private readonly translate: TranslateService,
    private readonly shiftCategoriesService: ShiftCategoryService,
  ) {}

  get shiftCategories$(): Observable<ShiftCategory[]> {
    if (!this._shiftCategories$) {
      this._shiftCategories$ = this.createShiftCategoriesObservable().pipe(
        shareReplay(1),
      );
    }
    return this._shiftCategories$;
  }

  createShiftCategoriesObservable(): Observable<ShiftCategory[]> {
    return this.store.select<Sector[]>(SectorState.sectors).pipe(
      filter((sectors) => !!sectors[0]),
      switchMap((sectors) => {
        const sector = sectors[0];
        return this.shiftCategoriesService
          .getAll({ relations: ['sectors'] })
          .pipe(
            switchMap((shiftCategories) => {
              const shiftsCategoryList = shiftCategories.filter((s) => {
                if (!s.sectors) return [];

                return s.sectors.find((sec) => sec.id == sector.id);
              });

              shiftsCategoryList.map(
                (category) =>
                  (category.name = 'shift_category.' + category.name),
              );
              shiftsCategoryList.sort((a, b) =>
                this.translate
                  .instant(a.name)
                  .localeCompare(this.translate.instant(b.name)),
              );
              return of(shiftsCategoryList);
            }),
            shareReplay(1),
          );
      }),
    );
  }
}
