@let labelText = 'page.shift_timeline.stay_content.drawer.toggle' | translate ;
<div
  nz-tooltip
  [nzTooltipTitle]="labelText"
  class="form-text d-flex align-items-center text-truncate"
>
  {{ labelText }}
</div>
<div class="d-flex align-items-center mt-1">
  <nz-switch
    [(ngModel)]="toggleValue"
    (ngModelChange)="takeOverAllActivityLocations($event)"
    [nzCheckedChildren]="checkedTemplate"
    [nzDisabled]="!hasUpdatePermissions || isDisabled"
    nz-tooltip
    [nzTooltipTitle]="errorMessage"
    data-test-id="wilson-shift-overwrite-all-toggle:toggle-switch"
  ></nz-switch>
</div>

<ng-template #checkedTemplate>
  <i class="fa fa-check icon-mar-top"></i>
</ng-template>
