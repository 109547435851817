import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { ClientPartnership } from '@wilson/clients/interfaces';
import {
  ClientsV2Service,
  ExternalUser,
  ExternalUsersService,
} from '@wilson/clients/services';
import {
  catchError,
  forkJoin,
  map,
  mergeAll,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  FetchAndSetAllExternalUsers,
  ResetExternalUsers,
} from './external-users.action';

export interface ExternalUsersStateModel {
  users: ExternalUser[];
  loading: boolean;
}

const EXTERNAL_USERS_STATE = 'externalUsers';
const defaultExternalUsersState: ExternalUsersStateModel = {
  users: [],
  loading: false,
};

@State<ExternalUsersStateModel>({
  name: EXTERNAL_USERS_STATE,
  defaults: defaultExternalUsersState,
})
@Injectable()
export class ExternalUsersState implements NgxsAfterBootstrap {
  constructor(
    private readonly storage: Storage,
    private readonly clientsV2Service: ClientsV2Service,
    private readonly externalUsersService: ExternalUsersService,
  ) {}

  async ngxsAfterBootstrap(
    ctx: StateContext<ExternalUsersStateModel>,
  ): Promise<void> {
    const storageState = await this.storage.get(EXTERNAL_USERS_STATE);
    if (storageState) {
      ctx.patchState({
        users: storageState,
      });
    }
  }

  @Selector()
  static isLoading(state: ExternalUsersStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static users(state: ExternalUsersStateModel): ExternalUser[] {
    return state.users;
  }

  static userByUserId(
    userId: string,
  ): (state: ExternalUsersStateModel) => ExternalUser | undefined {
    return createSelector(
      [ExternalUsersState],
      (state: ExternalUsersStateModel) => {
        return state.users.find((user) => user.userId === userId);
      },
    );
  }

  @Action(ResetExternalUsers)
  resetExternalUsers({
    setState,
    getState,
  }: StateContext<ExternalUsersStateModel>): void {
    setState(defaultExternalUsersState);
    this.updateStorage(getState());
  }

  @Action(FetchAndSetAllExternalUsers)
  fetchAndSetAllExternalUsers({
    patchState,
    getState,
  }: StateContext<ExternalUsersStateModel>): void {
    this.clientsV2Service
      .findAll({
        relations: ['clientPartnership'],
        limit: 0,
      })
      .pipe(
        take(1),
        map((clients) => {
          return clients.data
            .filter(
              (client) =>
                client.id !== undefined &&
                client.clientPartnership &&
                client.clientPartnership.partnershipId,
            )
            .map(
              (client) =>
                (client.clientPartnership as ClientPartnership).partnershipId,
            );
        }),
        switchMap((partnerShipIds) => {
          return forkJoin(
            partnerShipIds.map((id) => this.externalUsersService.getUser(id)),
          ).pipe(mergeAll());
        }),
        tap((externalUsers) => {
          patchState({
            users: externalUsers,
            loading: false,
          });
          this.updateStorage(getState());
        }),
        catchError(() => {
          patchState({
            loading: false,
          });
          return of([]);
        }),
      )
      .subscribe();
  }

  private updateStorage(state: ExternalUsersStateModel) {
    this.storage.set(EXTERNAL_USERS_STATE, state.users);
  }
}
