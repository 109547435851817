<input
  nz-input
  [placeholder]="readonly ? '---' : (placeholder | translate)"
  [ngModel]="formatedLocation$ | async"
  (input)="searchLocation($event.target.value)"
  [disabled]="readonly"
  [class.is-invalid]="invalid"
  [class.location-warning]="warning"
  (ngModelChange)="setSelectedLocation($event)"
  [nzAutocomplete]="addressAutoComplete"
  (focus)="touched.emit(true)"
/>
<nz-autocomplete #addressAutoComplete>
  <nz-auto-option
    *ngFor="let location of locationOptions$ | async"
    [nzLabel]="location.description"
    [nzValue]="location"
    nz-tooltip
    [nzTooltipOverlayClassName]="'dark'"
    [nzTooltipPlacement]="'bottom'"
    [nzTooltipTitle]="location.description"
    [attr.data-test-id]="'wilson-address-autocomplete:' + location.description"
    >{{ location.description }}</nz-auto-option
  >
</nz-autocomplete>
