import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalToTimePipe } from './decimal-to-time/decimal-to-time.pipe';
import { IsTodayPipe } from './is-today/is-today.pipe';
import { DisabledWeekdaysRangePipe } from './disabled-weekdays-range/disabled-weekdays-range.pipe';
import { SelectedAgreementPipe } from './selected-agreement/selected-agreement.pipe';
import { FormControlInvalidPipe } from './form-control-invalid/form-control-invalid.pipe';
import { LocationNamePipe } from './location-name/location-name.pipe';
import { LocationNameSubPipe } from './location-name-sub/location-name-sub.pipe';
import { AddressPipe } from './address/address.pipe';
import { NavigateToServicePipe } from './navigate-to-service/navigate-to-service.pipe';
import { ProjectNamePipe } from './projects/project-name.pipe';
import { InvoiceNumberPipe } from './invoice/invoice-number.pipe';
import { DecimalToHourFormatPipe } from './decimal-to-hour-format/decimal-to-hour-format.pipe';

@NgModule({
  declarations: [
    DecimalToTimePipe,
    IsTodayPipe,
    DisabledWeekdaysRangePipe,
    SelectedAgreementPipe,
    FormControlInvalidPipe,
    LocationNamePipe,
    LocationNameSubPipe,
    AddressPipe,
    NavigateToServicePipe,
    ProjectNamePipe,
    InvoiceNumberPipe,
    DecimalToHourFormatPipe,
  ],
  providers: [
    AddressPipe,
    LocationNamePipe,
    LocationNameSubPipe,
    FormControlInvalidPipe,
    InvoiceNumberPipe,
  ],
  imports: [CommonModule],
  exports: [
    DecimalToTimePipe,
    IsTodayPipe,
    DisabledWeekdaysRangePipe,
    SelectedAgreementPipe,
    FormControlInvalidPipe,
    LocationNamePipe,
    LocationNameSubPipe,
    AddressPipe,
    NavigateToServicePipe,
    ProjectNamePipe,
    InvoiceNumberPipe,
    DecimalToHourFormatPipe,
  ],
})
export class PipesModule {}
