import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzTSType } from 'ng-zorro-antd/core/types';

@Pipe({
  name: 'addActivityForbiddenMessage',
  standalone: true,
})
export class AddActivityForbiddenMessagePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform({
    hasShiftUpdatePermissions,
    isShiftAcceptedOrSubmittedToPayrollProvider,
    isShiftStarted,
    isShiftSubmitted,
    isAdminUser,
  }: {
    hasShiftUpdatePermissions: boolean;
    isShiftAcceptedOrSubmittedToPayrollProvider: boolean;
    isShiftStarted: boolean;
    isShiftSubmitted: boolean;
    isAdminUser: boolean;
  }): NzTSType {
    if (!hasShiftUpdatePermissions) {
      return this.translateService.instant('general.forbidden_action');
    } else if (isShiftAcceptedOrSubmittedToPayrollProvider) {
      return this.translateService.instant(
        'page.shift_timeline.shift_drawer.activity.can_not_add_activity',
      );
    } else if (isShiftStarted && !isShiftSubmitted) {
      return this.translateService.instant(
        'page.shift_timeline.shift_drawer.activity.shift_started',
      );
    } else if (isShiftSubmitted) {
      //TODO: "isShiftSubmitted && !isAdminUser" add this case back after BE support activity report deletion in single endpoint - https://menlo1679.atlassian.net/browse/WILP-7918
      //   return this.translateService.instant(
      //     'page.shift_timeline.shift_drawer.activity.only_admin_can_do_this_action',
      //   );
      return this.translateService.instant(
        isAdminUser
          ? 'page.shift_timeline.shift_drawer.activity.only_admin_can_do_this_action_on_shift_detail_page'
          : 'page.shift_timeline.shift_drawer.activity.only_admin_can_do_this_action',
      );
    } else {
      return '';
    }
  }
}
