import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalToHourFormat',
})
export class DecimalToHourFormatPipe implements PipeTransform {
  transform(timeInHours: number): string {
    const isNegative = timeInHours < 0;
    const absTime = Math.abs(timeInHours);
    const hours = Math.floor(absTime);
    const minutes = Math.round((absTime % 1) * 60) || 0;

    return (
      (isNegative ? '-' : '') +
      (hours < 10 ? '0' : '') +
      hours +
      ':' +
      minutes.toString().padStart(2, '0')
    );
  }
}
