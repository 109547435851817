import { ShiftId } from '../interfaces';

export class UpdateAssignedShiftsKPIs {
  static readonly type = '[Timeline] fetch unreleased shifts';
  constructor(public visibleDateRange: { start: Date; end: Date }) {}
}

export class RemoveUnreleasedShifts {
  static readonly type = '[Timeline] update unreleased shifts';
  constructor(public releasedShiftId: string) {}
}

export class FetchProductivityForAllFilteredUsers {
  static readonly type = '[Timeline] fetch users productivity';
  constructor(public userIds: string[], public showLoading: boolean) {}
}

export class UpdateProductivityOfUsers {
  static readonly type = '[Timeline] update users productivity';
  constructor(public userIds: string[]) {}
}
