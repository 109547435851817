<div
  *ngIf="{
    totalPushableActivities:
      activityFormArray | totalPushableActivities : currentIndex,
    totalPullableActivities:
      activityFormArray | totalPullableActivities : currentIndex
  } as data"
>
  <div
    class="px-3 py-1 cursor-pointer action-list"
    [class.disable-action]="data.totalPushableActivities <= 1"
    (click)="triggerPushActivities()"
  >
    <fa-icon class="me-2" [size]="'lg'" [icon]="faPushUnder"></fa-icon>
    <span class="text-dark">
      {{
        (data.totalPushableActivities <= 1
          ? 'shift.activity_time_gap.push_single_activity'
          : 'shift.activity_time_gap.push_activities'
        )
          | translate
            : {
                noOfActivities: data.totalPushableActivities
              }
      }}
    </span>
  </div>
  <nz-divider class="my-0"></nz-divider>
  <div
    class="px-3 py-1 cursor-pointer action-list"
    [class.disable-action]="
      !(activityFormArray | isActivityAdjustable : currentIndex)
    "
    (click)="triggerPushActivity()"
  >
    <fa-icon class="me-2" [size]="'lg'" [icon]="faPushUnderSingle"></fa-icon>
    <span class="text-dark">
      {{
        'shift.activity_time_gap.push_activity'
          | translate
            : {
                activityCategory: currentActivityCategoryName
              }
      }}
    </span>
  </div>
  <nz-divider class="my-0"></nz-divider>
  <div
    class="px-3 py-1 cursor-pointer action-list"
    [class.disable-action]="
      !(activityFormArray | isActivityAdjustable : currentIndex) ||
      canEditServiceActivity
    "
    (click)="triggerProlongActivityEnd()"
  >
    <fa-icon class="me-2" [size]="'lg'" [icon]="faProlongEnd"></fa-icon>
    <span class="text-dark">
      {{
        'shift.activity_time_gap.extend_activity'
          | translate
            : {
                activityCategory: currentActivityCategoryName
              }
      }}
    </span>
  </div>
  <nz-divider class="my-0"></nz-divider>

  <nz-divider class="mt-2 mb-0"></nz-divider>
  <div
    class="px-3 py-1 cursor-pointer action-list"
    (click)="triggerInsertActivity()"
  >
    <fa-icon class="me-2" [size]="'lg'" [icon]="faInsert"></fa-icon>
    <span class="text-dark">
      {{ 'shift.activity_time_gap.add_activity' | translate }}
    </span>
  </div>
  <nz-divider class="mb-2 mt-0"></nz-divider>

  <nz-divider class="my-0"></nz-divider>
  <div
    class="px-3 py-1 cursor-pointer action-list"
    [class.disable-action]="
      !(activityFormArray | isActivityAdjustable : currentIndex + 1) ||
      canEditServiceActivity
    "
    (click)="triggerProlongActivityStart()"
  >
    <fa-icon class="me-2" [size]="'lg'" [icon]="faProlongStart"></fa-icon>
    <span class="text-dark">
      {{
        'shift.activity_time_gap.extend_activity'
          | translate
            : {
                activityCategory: nextActivityCategoryName
              }
      }}
    </span>
  </div>
  <nz-divider class="my-0"></nz-divider>
  <div
    class="px-3 py-1 cursor-pointer action-list"
    [class.disable-action]="
      !(activityFormArray | isActivityAdjustable : currentIndex + 1) ||
      canEditServiceActivity
    "
    (click)="triggerPullActivity()"
  >
    <fa-icon class="me-2" [size]="'lg'" [icon]="faPushAboveSingle"></fa-icon>
    <span class="text-dark">
      {{
        'shift.activity_time_gap.pull_activity'
          | translate
            : {
                activityCategory: nextActivityCategoryName
              }
      }}
    </span>
  </div>
  <nz-divider class="my-0"></nz-divider>
  <div
    class="px-3 py-1 cursor-pointer action-list"
    [class.disable-action]="data.totalPullableActivities <= 1"
    (click)="triggerPullActivities()"
  >
    <fa-icon class="me-2" [size]="'lg'" [icon]="faPushAbove"></fa-icon>
    <span class="text-dark">
      {{
        (data.totalPullableActivities <= 1
          ? 'shift.activity_time_gap.pull_single_activity'
          : 'shift.activity_time_gap.pull_activities'
        ) | translate : { noOfActivities: data.totalPullableActivities }
      }}
    </span>
  </div>
</div>
