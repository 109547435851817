import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ShiftAssignmentOption } from '@wilson/interfaces';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { AssignAllAlertComponent } from '../assign-all-alert/assign-all-alert.component';

@Component({
  selector: 'wilson-assign-all-confirmation-popup',
  templateUrl: './assign-all-confirmation-popup.component.html',
  styleUrls: ['./assign-all-confirmation-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzAlertModule,
    AssignAllAlertComponent,
  ],
})
export class AssignAllConfirmationPopupComponent {
  public dateRange!: [Date, Date];
  public selectedOption = ShiftAssignmentOption.AssignAllOpen;

  public shiftValidOptions = [
    {
      translate: 'page.shift_disposition.occupy_all_shift',
      value: ShiftAssignmentOption.AssignAllOpen,
    },
    {
      translate: 'page.shift_disposition.entire_shift_plan',
      value: ShiftAssignmentOption.ReassignAll,
    },
    {
      translate: 'page.shift_disposition.recast_with_as_few_changes',
      value: ShiftAssignmentOption.ReassignWithMinimalChanges,
    },
  ];

  changeUserShiftvalidationOption(option: ShiftAssignmentOption): void {
    this.selectedOption = option;
  }
}
