import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { captureException } from '@sentry/angular';
import { ActivityReportsService } from '@wilson/activities';
import { reportedActivitiesCompareFn } from '@wilson/activities/util';
import { ActivitiesGateway } from '@wilson/api/gateway';
import {
  Activity,
  ActivityReportCategory,
  ActivityReportType,
  ActivityWithCategoryAndLocationsAndReportedDetails,
  OperationStatus,
  PublicationStatus,
  Shift,
  WilsonApp,
  WithPreparedAttributes,
} from '@wilson/interfaces';
import { ShiftsService } from '@wilson/shifts';
import { addHours } from 'date-fns';
import { NzMessageService } from 'ng-zorro-antd/message';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class AddActivityHelperService {
  constructor(
    private readonly nzMessageService: NzMessageService,
    private readonly translate: TranslateService,
    private readonly activitiesGateway: ActivitiesGateway,
    private readonly activityReportsService: ActivityReportsService,
    private readonly shiftsService: ShiftsService,
  ) {}

  public async addActivity(
    activities: ActivityWithCategoryAndLocationsAndReportedDetails[],
    shift: Shift & WithPreparedAttributes & { id: string },
    isShiftSubmitted: boolean,
  ): Promise<boolean> {
    try {
      let previousActivity: ActivityWithCategoryAndLocationsAndReportedDetails;
      if (isShiftSubmitted) {
        const reportedActivities = activities
          .filter((activity) => activity.activityReports.length)
          .sort(reportedActivitiesCompareFn);
        previousActivity = reportedActivities[reportedActivities.length - 1];
      } else {
        previousActivity = activities[activities.length - 1];
      }

      const newActivity: Activity = {
        activityCategoryId: previousActivity.activityCategoryId,
        createdFrom: WilsonApp.Portal,
        startDatetime: previousActivity.endDatetime,
        endDatetime: addHours(
          new Date(previousActivity.endDatetime),
          1,
        ).toISOString(),
        startLocationId: previousActivity.endLocationId,
        endLocationId: previousActivity.endLocationId,
        name: previousActivity.name,
        operationalStatus: isShiftSubmitted
          ? OperationStatus.Completed
          : OperationStatus.NotStarted,
        serviceId: null,
        shiftId: previousActivity.shiftId,
        jobId: previousActivity.jobId,
        professionId: previousActivity.professionId,
        viaLocationIds: previousActivity.viaLocationIds,
        agreementId: previousActivity.agreementId,
        priority: previousActivity.priority,
        tonnage: previousActivity.tonnage,
        numberOfWagons: previousActivity.numberOfWagons,
        organizationalUnitTransportGoodsId:
          previousActivity.organizationalUnitTransportGoodsId,
        travelDirection: previousActivity.travelDirection,
        cargo: previousActivity.cargo,
        track: previousActivity.track,
        note: previousActivity.note,
        externalNumber: previousActivity.externalNumber,
        vehicleEmissionClassId: previousActivity.vehicleEmissionClassId,
      };

      const createdActivity = await firstValueFrom(
        this.activitiesGateway.createActivities([newActivity]),
      );
      await firstValueFrom(
        this.shiftsService.updateShift({
          id: shift.id,
          publicationStatus: isShiftSubmitted
            ? shift.publicationStatus
            : PublicationStatus.NotPublished,
        }),
      );

      if (isShiftSubmitted) {
        await this.activityReportsService.create(
          {
            reportType: ActivityReportType.IsTimes,
            reportCategory: ActivityReportCategory.Start,
            dateTime: previousActivity.reportedEndDatetime,
            activityId: createdActivity[0].id,
            locationId: previousActivity.reportedEndLocationId,
          },
          {
            reportType: ActivityReportType.IsTimes,
            reportCategory: ActivityReportCategory.End,
            dateTime: addHours(
              new Date(previousActivity.reportedEndDatetime),
              1,
            ).toISOString(),
            activityId: createdActivity[0].id,
            locationId: previousActivity.reportedEndLocationId,
          },
        );
      }
      return true;
    } catch (error) {
      this.nzMessageService.error(
        this.translate.instant('general.something_went_wrong'),
      );
      captureException(error);
      return false;
    }
  }
}
