import { Injectable, OnDestroy } from '@angular/core';
import { WilsonState } from '@wilson/non-domain-specific/decorators/wilson-state';
import { interval, Observable, Subscription } from 'rxjs';

@Injectable()
export class AnimationHelperService implements OnDestroy {
  @WilsonState<number>(100)
  private progress: number;
  public progress$!: Observable<number>;
  @WilsonState<boolean>(false)
  private showUndo: boolean;
  public showUndo$!: Observable<boolean>;

  private readonly TOTAL_TIME = 5000;
  private timeout!: number;
  private startTime!: number;
  private progressDuration = this.TOTAL_TIME;
  private progressInterval?: Subscription;

  ngOnDestroy(): void {
    this.cleanup();
  }

  startAnimation(onComplete: () => void): void {
    this.cleanup();
    this.progressDuration = this.TOTAL_TIME;
    this.showUndo = true;
    this.startTimeout(onComplete);
  }

  pauseAnimation(): void {
    if (this.startTime) {
      this.progressDuration -= Date.now() - this.startTime;
    }
    this.cleanup();
  }

  resumeAnimation(onComplete: () => void): void {
    this.startTimeout(onComplete);
  }

  resetAnimation(): void {
    this.cleanup();
    this.startTime = undefined;
    this.progressDuration = this.TOTAL_TIME;
    this.progress = 100;
    this.showUndo = false;
  }

  private startTimeout(onComplete: () => void): void {
    this.clearTimeout();
    this.startTime = Date.now();
    this.startProgressTracking();
    this.timeout = window.setTimeout(() => {
      onComplete();
    }, this.progressDuration);
  }

  private startProgressTracking(): void {
    this.stopProgressTracking();
    this.updateProgress();
    this.progressInterval = interval(100).subscribe(() => {
      this.updateProgress();
    });
  }

  private stopProgressTracking(): void {
    if (this.progressInterval) {
      this.progressInterval.unsubscribe();
      this.progressInterval = undefined;
    }
  }

  private updateProgress(): void {
    const elapsed = Date.now() - this.startTime;
    const remaining = Math.max(0, this.progressDuration - elapsed);
    this.progress = (remaining / this.TOTAL_TIME) * 100;
  }

  private clearTimeout(): void {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  private cleanup(): void {
    this.clearTimeout();
    this.stopProgressTracking();
  }
}
