import { AssignmentStack, UserTimelines } from '@wilson/interfaces';
import { eachDayOfInterval, startOfDay } from 'date-fns';
import { determineShiftRenderDatetime } from './determine-assignments-overlap';

export function calculateUserAssignmentStack(
  userTimeline: UserTimelines,
  isDebugEnabled: boolean,
): AssignmentStack[] {
  const userAssignmentStacks: AssignmentStack[] = [];
  const dateMap = new Map<string, AssignmentStack>();

  Object.values(userTimeline.absencesDictionary).forEach((absence) => {
    const absenceStartDateKey = startOfDay(new Date(absence.absentFrom));

    const absenceEndDateKey = startOfDay(new Date(absence.absentTo));

    eachDayOfInterval({
      start: absenceStartDateKey,
      end: absenceEndDateKey,
    }).forEach((dateInInterval) => {
      const dateInIntervalString = dateInInterval.toISOString();
      let assignment = dateMap.get(dateInIntervalString);

      if (assignment) {
        assignment.absences.push(absence);
      } else {
        assignment = {
          date: dateInInterval,
          absences: [absence],
          shifts: [],
        };
        dateMap.set(dateInIntervalString, assignment);
        userAssignmentStacks.push(assignment);
      }
    });
  });

  Object.values(userTimeline.shiftsWithoutActivitiesDictionary).forEach(
    (shift) => {
      const shiftStartDateKey = startOfDay(
        new Date(
          determineShiftRenderDatetime(
            shift,
            isDebugEnabled,
          ).startDatetime.date,
        ),
      ).toISOString();

      let assignment = dateMap.get(shiftStartDateKey);

      if (assignment) {
        assignment.shifts.push(shift);
      } else {
        assignment = {
          date: new Date(shiftStartDateKey),
          absences: [],
          shifts: [shift],
        };
        dateMap.set(shiftStartDateKey, assignment);
        userAssignmentStacks.push(assignment);
      }
    },
  );

  return userAssignmentStacks;
}
