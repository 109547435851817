import { ControlsOf, FormGroup } from '@ngneat/reactive-forms';
import { ServiceDeviation } from '@wilson/service-deviation/interfaces';
import { Absence } from '../absences/absence';
import { User, UserAuthOption } from '../accounts/User';
import { Activity } from '../activities/activity';
import { ActivityCategory } from '../activities/activity-category';
import { ActivityReport } from '../activities/activity-report';
import { ActivityDeviationStatus } from '../activities/DeviationStatus';
import { OperationStatus } from '../activities/operationStatus';
import { UserRole } from '../authorization/role';
import { Label } from '../labels/labels';
import { GeoLocation } from '../locations/geoLocation';
import { PublicationStatus } from '../shifts/publicationStatus';
import { Shift, WithPreparedAttributes } from '../shifts/shift';
import { Stay } from '../stays/stays';
import { StrictBase } from '@wilson/base';
import { OrganizationalUnit } from '../organizational-units/organizationalUnit';
import { ResolvedShiftValidation } from '../shifts/shiftValidation';
import { ShiftState } from '../shifts/shift-state';

export interface LocationAndDateTimeRange {
  startDateTime: string;
  endDateTime: string;
  startLocation: string | null;
  endLocation: string | null;
}

export interface AccurateDateInterval {
  accurateStartDateTime: Date;
  accurateEndDateTime: Date;
}

export type ActivityWithOverlapLevel = (Activity & {
  overlapLevel: number;
  id: string;
  operationalStatus: OperationStatus;
})[];

export interface ItemWithActivities {
  id: string;
  overlapLevel: number;
  activities: (Activity & { id: string })[];
}

export type ShiftWithActivitiesAndOverlap = Shift & {
  id: string;
  startDatetime: Date;
  endDatetime: Date;
  activities: ActivityWithOverlapLevel[];
};

export interface DraftShift {
  isDraft: boolean;
}

export interface ItemViewModel {
  activitiesInRowLevels: ActivityWithOverlapLevel[];
  shift: Shift & {
    id: string;
    startDatetime: Date;
    endDatetime: Date;
    activities: Activity[];
    priority: number;
  };
}
export type ShiftWithUpdatedActivities = Shift & {
  id: string;
  startDatetime: Date;
  endDatetime: Date;
  activities: ActivityWithOverlapLevel[];
  activitiesInRowLevels: ActivityWithOverlapLevel[];
  overlapLevel: number;
};

export interface GroupedUserData {
  rowHeightInPixels: number;
  itemViews: ItemViewModel[];
  absences: Absence[];
}

export type ItemViewModelWithOverlapLevel = ItemViewModel & {
  overlapLevel: number;
};

export type TimelineShiftForm = FormGroup<
  ControlsOf<
    Pick<Shift, 'name' | 'comment' | 'shiftCategoryId' | 'organizationalUnitId'>
  >
>;

export type TimelineShift = Shift &
  WithPreparedAttributes & {
    id: string;
    priority: number | null;
    isHighlighted: boolean;
  };

export type TimelineShiftWithActivities = TimelineShift & {
  activities: Activity[];
};

type AbsenceId = string;
type ShiftId = string;
type StayId = string;
type ActivityId = string;
export type ActivityWithCategoryAndLocationsAndReportedDetails = Activity & {
  id: string;
  activityCategory: ActivityCategory & { translatedName: string };
  startLocation: GeoLocation;
  endLocation: GeoLocation;
  reportedStartDatetime: string | null;
  reportedEndDatetime: string | null;
  reportedStartLocationId: string | null;
  reportedEndLocationId: string | null;
  activityReports: ActivityReport[];
};
export type ActivityRow = Record<
  ActivityId,
  ActivityWithCategoryAndLocationsAndReportedDetails
>;
export type UserTimelinesWithActivitiesPayload = User & {
  userRoles: UserRole[];
  shifts: TimelineShiftWithActivities[];
  absences: (Absence & { id: string; isHighlighted: boolean })[];
};

export type UserTimelinesWithoutActivitiesPayload = User & {
  userRoles: UserRole[];
  shifts: TimelineShift[];
  absences: (Absence & { id: string; isHighlighted: boolean })[];
};

export type UserTimelines = TimelineUserDTO & {
  userRoles: UserRole[];
  shiftsWithoutActivitiesDictionary: Record<ShiftId, TimelineShift>;
  absencesDictionary: Record<
    AbsenceId,
    Absence & { id: string; isHighlighted: boolean }
  >;
  staysDictionary: Record<StayId, Stay>;
  determinedRowDataV2: GroupedUserDataV2[];
  determinedRowDataTimestamp: number;
  assignmentStacks: AssignmentStack[];
};

export type AssignmentStack = {
  date: Date;
  shifts: TimelineShift[];
  absences: (Absence & { id: string; isHighlighted: boolean })[];
};

export type UserTimelinesModal = User & {
  userRoles: UserRole[];
  absences: (Absence & { id: string; isHighlighted: boolean })[];
  shifts: TimelineShiftWithActivities[];
};

export interface DemarcatorRange {
  left: number | null | undefined;
  right: number | null | undefined;
}

export interface AccurateTimeDetails {
  date: string;
  location: {
    locationCode: string;
    name: string;
  };
  type: ActivityDeviationStatus;
  timeDifference: number;
}

export interface AccurateDateIntervalWithAbsence extends AccurateDateInterval {
  absence: Absence & { id: string };
}

export interface AccurateDateIntervalWithStay extends AccurateDateInterval {
  stay: Stay;
}

export interface AccurateDateIntervalWithShift extends AccurateDateInterval {
  shift: TimelineShift;
}
export type AccurateDateIntervalWithActivity = Activity & AccurateDateInterval;

export type AccurateUnassignedItem = AccurateDateInterval & {
  id: string;
  activities: Activity[];
};

export interface GroupedUnassignedUserDataV2 {
  services: AccurateUnassignedItem[];
  jobs: AccurateUnassignedItem[];
}
export interface GroupedUserDataV2 {
  shifts: AccurateDateIntervalWithShift[];
  absences: AccurateDateIntervalWithAbsence[];
  stays: AccurateDateIntervalWithStay[];
}

export const TimelineDateQueryParamKey = 'timelineDate';
export const ZoomLevelQueryParamKey = 'timelineZoomLevel';
export const TimelineShowActivitiesQueryParamKey = 'showActivities';
export const TimelineShowStaysQueryParamKey = 'showStays';
export const TimelineAssignmentLayoutQueryParamKey = 'assignmentLayout';
export const TimelineUnassignedShiftIdsQueryParamKey = 'unassignedShiftIds';
export const TimelineUnassignedShiftSearchesQueryParamKey =
  'unassignedShiftSearches';
export const TimelineUnassignedServiceIdsQueryParamKey = 'unassignedServiceIds';
export const TimelineUnassignedServiceSearchesQueryParamKey =
  'unassignedServiceSearches';

export enum ZOOM_LEVEL {
  DAY = '1_Day',
  HALF_WEEK = '3_Days',
  DEFAULT = '7_Days',
  HALF_MONTH = '14_Days',
  MONTH = '31_Days',
}

export enum StaffLayoutStyle {
  Compact = 'compact',
  Minimal = 'minimal',
}

export enum AssignmentLayoutStyle {
  CellView = 'cell_view',
  TimelineView = 'timeline_view',
}

export enum ShiftColorOptions {
  ShiftCategory = 'shiftCategory',
  Label = 'label',
  Project = 'project',
}

export enum KPIOptions {
  OvertimeAccount = 'overtime_account',
  Productivity = 'productivity',
  WorkTimeWeek = 'work_time_week',
  PlannedTimeWeek = 'planned_time_week',
  OvertimeWeek = 'overtime_week',
  WorkTimeMonth = 'work_time_month',
  PlannedTimeMonth = 'planned_time_month',
  OvertimeMonth = 'overtime_month',
  HomeLocation = 'home_location',
}

export enum SortDirection {
  Ascending,
  Descending,
}

export enum SortByOptions {
  Name = 'name',
  Profession = 'profession',
}

export enum TimelineActiveFilterRegionEnum {
  AssignedRegion = 'assignedRegion',
  UnassignedRegion = 'unassignedRegion',
}

export interface Threshold {
  min: number | null;
  max: number | null;
}

export interface PreferredSetting {
  mainKPIs: KPIOptions[];
  staffLayout: StaffLayoutStyle;
  assignmentLayout: AssignmentLayoutStyle;
  sortDirection: SortDirection;
  sortBy: SortByOptions;
  threshold: Threshold;
  shiftColorOption: ShiftColorOptions;
}

export interface DateStringInterval {
  startDatetime: string;
  endDatetime: string;
  serviceDeviations?: ServiceDeviation[];
  activityReports?: ActivityReport[] | null;
}

export interface DateInterval {
  startDatetime: Date;
  endDatetime: Date;
}

export enum ShiftConfirmationStatus {
  Confirmed = 'confirmed',
  Declined = 'declined',
  Pending = 'pending',
}
export interface Filters {
  userIds: string[];
  roleIds: string[];
  professionIds: string[];
  userLabels: string[];
  orgUnitIds: string[];
  homeLocationIds: string[];
  absencesCategoryIds: string[];
  shiftCategoryIds: string[];
  releaseStatus: PublicationStatus | null;
  shiftLabels: string[];
  projects: string[];
  confirmedDeclined: ShiftConfirmationStatus[];
  shiftOrgUnitIds: string[];
  shiftProfessionIds: string[];
  agreementIds: string[];
  timelineActiveFilterRegion: TimelineActiveFilterRegionEnum[];
}
export interface PartialLocation {
  id: string;
  name: string;
  locationCode: string;
}

export interface UnassignedShift {
  id: string;
  name: string;
  shiftCategoryId: string;
  startDatetime: string;
  endDatetime: string;
  startLocationId: string;
  endLocationId: string;
  reportedStartLocationId: string | null;
  reportedEndLocationId: string | null;
  startLocation: PartialLocation;
  endLocation: PartialLocation;
  deviatedStartDatetime: string | null;
  deviatedEndDatetime: string | null;
  reportedStartDatetime: string | null;
  reportedEndDatetime: string | null;
  reportedStartLocation: PartialLocation | null;
  reportedEndLocation: PartialLocation | null;
  labels: Label[];
  projectId: string;
  activities: Activity[] | null;
  comment: string | null;
  organizationalUnitId: string;
  publicationStatus: PublicationStatus;
  startDate: string;
  state: ShiftState;
  userId: string | null;
  operationalStatus: OperationStatus;
  priority: number | null;
  ignoreStayNecessary: boolean;
}

export interface NumberOfShiftPerDay {
  date: Date;
  numberOfShifts: number;
}

export type NumberOfShiftPerDayRecord = Record<string, number>;

export type UserTimelineModalShiftsAndAbsences =
  | (Shift &
      WithPreparedAttributes & {
        id: string;
        activities: Activity[];
        priority: number | null;
      })
  | (Absence & { id: string; isHighlighted: boolean });

export interface WorktimeUtilizationDTO {
  [userId: string]: UserKpisInMinutes;
}

export interface UserKpisInMinutes {
  plannedWorkTimeSumInMinutes: number;
  isWorkTimeSumInMinutes: number;
  overtimeInMinutes: number;
}

export interface TimelineUserDTO extends StrictBase {
  about: string | null;
  addressId: string | null;
  approvedToWork: boolean;
  authOption: UserAuthOption;
  autoSkipActivity: boolean;
  birthDate: string | null;
  canAccessWilson: boolean;
  comment: string | null;
  contractId: string;
  email: string;
  externalId: string | null;
  firstName: string;
  homeLocation: GeoLocation | null;
  homeLocationId: string | null;
  isConfirmed: boolean;
  isOnline: boolean;
  labels: Label[];
  lastLogin: Date | null;
  lastName: string;
  organizationalUnit: OrganizationalUnit;
  organizationalUnitId: string;
  personnelId: string | null;
  phone: string | null;
  professionIds: string[];
  profileImageUrl: string | null;
  publicHolidaysRegion: string[];
  userRoles?: UserRole[];
}

export interface TimelineFetchState {
  isRequestError: boolean;
  data: unknown[];
}

export type UserValidationsFetchState = {
  hasError: boolean;
  isLoadingValidationsV1Error: boolean;
  userIds: string[];
  validations: ResolvedShiftValidation[];
};

export type UnassignedShiftStackByDay = {
  day: Date;
  shifts: Record<ShiftId, UnassignedShift>;
};
