<input
  nz-input
  [placeholder]="placeholder | translate"
  [ngModel]="
    dataSourceLocation?.label ? dataSourceLocation.label : dataSourceLocation
  "
  (input)="changeLocation($event.target.value)"
  (ngModelChange)="setLocation($event)"
  [nzAutocomplete]="auto"
  [disabled]="readonly"
  [required]="invalid"
  [class.is-invalid]="invalid"
  [class.location-warning]="warning"
  (focusout)="clearLocation()"
  (focus)="touched.emit(true)"
/>
<nz-autocomplete
  nzBackfill
  #auto
  [nzOverlayStyle]="dropDownMinWidth ? nzMinWidthConfig : ''"
>
  <nz-auto-option
    *ngFor="let location of railGeoLocationDataSource$ | async"
    [nzValue]="location"
    [nzLabel]="location.label"
    nz-tooltip
    [nzTooltipOverlayClassName]="'light'"
    [nzTooltipPlacement]="'right'"
    [nzTooltipTitle]="location.label"
    >{{ location.label }}</nz-auto-option
  >
</nz-autocomplete>
