import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { WithTranslations } from '@wilson/interfaces';
import { LangState } from '@wilson/languages';
import { WithDescriptions } from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class TransformMasterDataTranslationService {
  constructor(private store: Store) {}

  transform<T extends Partial<WithTranslations>>(
    data: T[],
  ): (T & { translatedName: string })[] {
    const nameDescriptor = this.store.selectSnapshot(LangState.getEntityLang);

    return data.map((rawData) => ({
      ...rawData,
      translatedName: rawData[nameDescriptor] || rawData.nameEn || '',
    }));
  }

  transformDescriptions<T extends Partial<WithDescriptions>>(
    data: T[],
  ): (T & { translatedDescription: string })[] {
    const nameDescriptor = this.store.selectSnapshot(LangState.getEntityLang);
    const descriptionDescriptor = nameDescriptor.replace(
      'name',
      'description',
    ) as keyof WithDescriptions;
    return data.map((rawData) => ({
      ...rawData,
      translatedDescription:
        rawData[descriptionDescriptor] || rawData.descriptionEn || '',
    }));
  }
}
