@let hasShiftReadPermission = RoleAction.Read | ablePure :
RolePermissionSubject.Shift | async; @if(hasShiftReadPermission){
<nz-collapse
  [nzBordered]="false"
  nzGhost
  data-test-id="wilson-collapsible-shift-content:shift-content"
>
  <nz-collapse-panel
    [nzHeader]="'shift_drawer.shift_content' | translate"
    [nzActive]="isExpandedOnOpen"
    class="collapse-panel"
  >
    <wilson-shift-content
      [shiftWithActivities]="shiftWithActivities"
      (isShiftUpdateOperationOngoing)="
        isShiftUpdateOperationOngoing.emit($event)
      "
      (updateSuccess)="updateSuccess.emit($event)"
    ></wilson-shift-content>
  </nz-collapse-panel>
</nz-collapse>

<nz-divider class="my-0"></nz-divider>

}
