export function isPresignedUrlExpired(url: string): boolean {
  const urlObj = new URL(url);
  const amzDate = urlObj.searchParams.get('X-Amz-Date');
  const amzExpires = urlObj.searchParams.get('X-Amz-Expires');

  if (!amzDate || !amzExpires) {
    console.error('Missing required parameters in the S3 URL.');
    return true;
  }

  // https://docs.aws.amazon.com/AmazonECR/latest/APIReference/CommonParameters.html
  // YYYYMMDD'T'HHMMSS'Z'
  // e.g. 20250314T200229Z
  const requestTime = new Date(
    Date.UTC(
      parseInt(amzDate.substring(0, 4), 10), // Year
      parseInt(amzDate.substring(4, 6), 10) - 1, // Month (0-based)
      parseInt(amzDate.substring(6, 8), 10), // Day
      parseInt(amzDate.substring(9, 11), 10), // Hour
      parseInt(amzDate.substring(11, 13), 10), // Minute
      parseInt(amzDate.substring(13, 15), 10), // Second
    ),
  );

  const expiresInSeconds = parseInt(amzExpires, 10);
  const expiryTime = new Date(requestTime.getTime() + expiresInSeconds * 1000);

  return new Date() > expiryTime;
}
