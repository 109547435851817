import { Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import {
  COMPACT_ROW_HEIGHT,
  COMPACT_SHIFT_HEIGHT,
  ZOOM_LEVEL,
} from '@wilson/interfaces';
import {
  SideNavSettingModel,
  SideNavSettingsState,
} from '@wilson/side-nav-settings/state';
import { ShiftTimelineSettingsModel } from '../interfaces';
import {
  ResetRowHeight,
  ResetShiftTimelineWidth,
  SetAreActivitiesVisible,
  SetAreStaysVisible,
  SetDefaultRowHeight,
  SetDefaultShiftHeight,
  SetZoomLevel,
  UpdateWindowWidthToWindowInnerWidth,
} from './shift-timeline-settings.actions';

export const timelineDaysByZoomLevel = new Map([
  [ZOOM_LEVEL.MONTH, 31],
  [ZOOM_LEVEL.HALF_MONTH, 14],
  [ZOOM_LEVEL.DEFAULT, 7],
  [ZOOM_LEVEL.HALF_WEEK, 3],
  [ZOOM_LEVEL.DAY, 1],
]);

const PIXEL_EXCEPT_TIMELINE_WIDTH_WHEN_SIDENAV_OPEN = 360; // This ADJUSTMENT_PIXEL is calculated from Sidebar(px) + Page Frame Padding(px) + FIlter section(px)
const PIXEL_EXCEPT_TIMELINE_WIDTH_WHEN_SIDENAV_CLOSE = 168; // This ADJUSTMENT_PIXEL is calculated from Sidebar(px) + Page Frame Padding(px) + FIlter section(px)

const hoursPerZoomLevel = new Map([
  [ZOOM_LEVEL.MONTH, []], // On 31 Days View we are not showing any hours so make this array empty
  [ZOOM_LEVEL.HALF_MONTH, []], // On 14 Days View we are not showing any hours so make this array empty
  [ZOOM_LEVEL.DEFAULT, ['00:00', '08:00', '16:00', '24:00']],
  [
    ZOOM_LEVEL.HALF_WEEK,
    ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00', '24:00'],
  ],
  [
    ZOOM_LEVEL.DAY,
    [
      '00:00',
      '01:00',
      '02:00',
      '03:00',
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00',
      '24:00',
    ],
  ],
]);
const defaultResourcesColumnWidthPx = 288;

const defaults: ShiftTimelineSettingsModel = {
  windowWidth: window.innerWidth,
  zoomLevel: ZOOM_LEVEL.DEFAULT,
  hoursRange: hoursPerZoomLevel.get(ZOOM_LEVEL.DEFAULT) as string[],
  resourcesColumnWidthPx: defaultResourcesColumnWidthPx,
  defaultRowHeight: COMPACT_ROW_HEIGHT,
  defaultShiftHeight: COMPACT_SHIFT_HEIGHT,
  defaultUnassignedSectionHeight: 150,
  areActivitiesVisible: false,
  areStaysVisible: false,
};

@State({
  name: 'shiftTimelineSettings',
  defaults,
})
@Injectable()
export class ShiftTimelineSettingsState {
  constructor(private readonly store: Store) {}

  @Selector()
  static defaultUnassignedSectionHeight(
    state: ShiftTimelineSettingsModel,
  ): number {
    return state.defaultUnassignedSectionHeight;
  }

  @Selector()
  static resourcesColumnWidthPx(state: ShiftTimelineSettingsModel): number {
    return state.resourcesColumnWidthPx;
  }

  @Selector()
  static settings(
    state: ShiftTimelineSettingsModel,
  ): ShiftTimelineSettingsModel {
    return state;
  }

  @Selector()
  static hoursRange(state: ShiftTimelineSettingsModel): string[] {
    return state.hoursRange;
  }

  @Selector()
  static numberOfDaysFromZoomLevel(state: ShiftTimelineSettingsModel): number {
    return timelineDaysByZoomLevel.get(state.zoomLevel) as number;
  }

  @Selector()
  static zoomLevel(state: ShiftTimelineSettingsModel): ZOOM_LEVEL {
    return state.zoomLevel;
  }

  @Selector()
  static defaultRowHeight(state: ShiftTimelineSettingsModel): number {
    return state.defaultRowHeight;
  }

  @Selector()
  static defaultShiftHeight(state: ShiftTimelineSettingsModel): number {
    return state.defaultShiftHeight;
  }

  @Selector()
  static areActivitiesVisible(state: ShiftTimelineSettingsModel): boolean {
    return state.areActivitiesVisible;
  }

  @Selector()
  static areStaysVisible(state: ShiftTimelineSettingsModel): boolean {
    return state.areStaysVisible;
  }

  @Selector()
  static windowWidth(state: ShiftTimelineSettingsModel): number {
    return state.windowWidth;
  }

  static pixelWidthPerDay(): (
    userId: string,
    zoomLevel: ZOOM_LEVEL,
    resourcesColumnWidthPx: number,
    sideNavSettings: SideNavSettingModel,
    windowWidth: number,
  ) => number {
    return createSelector(
      [
        AuthState.userId,
        ShiftTimelineSettingsState.zoomLevel,
        ShiftTimelineSettingsState.resourcesColumnWidthPx,
        SideNavSettingsState,
        ShiftTimelineSettingsState.windowWidth,
      ],
      (
        userId: string,
        zoomLevel: ZOOM_LEVEL,
        resourcesColumnWidthPx: number,
        sideNavSettings: SideNavSettingModel,
        windowWidth: number,
      ) => {
        const isSidenavCollapsed = userId
          ? !!sideNavSettings.sideNavSettings[userId]?.isCollapsed
          : false;

        const pixelExceptTimelineWidth = isSidenavCollapsed
          ? PIXEL_EXCEPT_TIMELINE_WIDTH_WHEN_SIDENAV_CLOSE
          : PIXEL_EXCEPT_TIMELINE_WIDTH_WHEN_SIDENAV_OPEN;

        const timeframeWidth =
          (windowWidth - pixelExceptTimelineWidth - resourcesColumnWidthPx) /
            (timelineDaysByZoomLevel.get(zoomLevel) as number) || 0;

        return timeframeWidth;
      },
    );
  }

  @Action(SetZoomLevel)
  SetZoomLevel(
    { patchState }: StateContext<ShiftTimelineSettingsModel>,
    { zoomLevel }: SetZoomLevel,
  ): void {
    patchState({
      zoomLevel,
      hoursRange: hoursPerZoomLevel.get(zoomLevel) as string[],
    });
  }

  @Action(SetAreStaysVisible)
  setAreStaysVisible(
    { patchState }: StateContext<ShiftTimelineSettingsModel>,
    { isVisible }: SetAreStaysVisible,
  ): void {
    patchState({
      areStaysVisible: isVisible,
    });
  }

  @Action(SetAreActivitiesVisible)
  setAreActivitiesVisible(
    { patchState }: StateContext<ShiftTimelineSettingsModel>,
    { isVisible }: SetAreActivitiesVisible,
  ): void {
    patchState({
      areActivitiesVisible: isVisible,
    });
  }

  @Action(SetDefaultRowHeight)
  setDefaultRowHeight(
    { patchState }: StateContext<ShiftTimelineSettingsModel>,
    { height }: SetDefaultRowHeight,
  ): void {
    patchState({
      defaultRowHeight: height,
    });
  }

  @Action(SetDefaultShiftHeight)
  setDefaultShiftHeight(
    { patchState }: StateContext<ShiftTimelineSettingsModel>,
    { height }: SetDefaultShiftHeight,
  ): void {
    patchState({
      defaultShiftHeight: height,
    });
  }

  @Action(ResetShiftTimelineWidth)
  resetShiftTimelineWidth({
    patchState,
  }: StateContext<ShiftTimelineSettingsModel>): void {
    patchState({
      resourcesColumnWidthPx: defaults.resourcesColumnWidthPx,
    });
  }

  @Action(ResetRowHeight)
  resetRowHeight({
    patchState,
  }: StateContext<ShiftTimelineSettingsModel>): void {
    patchState({
      defaultRowHeight: defaults.defaultRowHeight,
    });
  }

  @Action(UpdateWindowWidthToWindowInnerWidth)
  updateWindowWidthToWindowInnerWidth({
    patchState,
  }: StateContext<ShiftTimelineSettingsModel>): void {
    patchState({ windowWidth: window.innerWidth });
  }
}
