import { StrictBase } from '@wilson/base';

type AbsenceId = string;
type ShiftId = string;

export enum AssignmentOptimizerJobStatusEnum {
  PENDING = 'pending',
  FAILED = 'failed',
  SUCCEEDED = 'succeeded',
}

export interface AssignmentOptimizerJobResult {
  shiftAssignments: {
    [userId: string]: ShiftId[];
  };
  unassignedShifts: ShiftId[];
}

export interface AssignmentData extends AssignmentOptimizerJobResult {
  absenceAssignments: {
    [userId: string]: AbsenceId[];
  };
}

export interface StartAssignmentOptimizerJobDto extends AssignmentData {
  startDateTime: string;
  endDateTime: string;
  jobId: string;
  timelineSettings: string;
  userAlgoSettings?: {
    blockNightHours?: boolean;
    ignoredQualifications?: string[];
    minimalRestTimeInHours?: number;
    maximalLengthOfTransfer?: number;
    reassignAll?: boolean;
  };
}

export interface AssignmentOptimizerJobStatusResponse {
  jobId: string;
  status: AssignmentOptimizerJobStatusEnum;
}

export type AssignmentOptimizerJobSuccessResponse =
  AssignmentOptimizerJobStatusResponse & {
    result: AssignmentOptimizerJobResult;
    originalInput: StartAssignmentOptimizerJobDto;
  };

export interface AssignmentOptimizerKpiDto {
  name_de: string;
  name_en: string;
  name_pl: string;
  name_fr: string;
  description_de: string;
  description_en: string;
  description_pl: string;
  description_fr: string;
  value: string;
}

export interface AssignmentOptimizerKPIResponse {
  total_kpis: AssignmentOptimizerKpiDto[];
  user_kpis: {
    [userId: string]: AssignmentOptimizerKpiDto[];
  };
  free_text_field: string;
}

export interface AssignmentOptimizerSetting extends StrictBase {
  nameEn: string;
  nameDe: string;
  nameFr: string;
  namePl: string;
  descriptionEn: string | null;
  descriptionDe: string | null;
  descriptionFr: string | null;
  descriptionPl: string | null;
  isSelected: boolean;
  organizationalUnitId: string;
  [ConfigurableFieldsEnum.BLOCK_TRANSFER_DURING_NIGHT_HOURS]: boolean | null;
  [ConfigurableFieldsEnum.MINIMAL_REST_TIME_BETWEEN_SHIFTS_MINUTES]:
    | number
    | null;
  [ConfigurableFieldsEnum.MAXIMAL_LENGTH_OF_TRANSFER_MINUTES]: number | null;
  [ConfigurableFieldsEnum.IGNORED_QUALIFICATION_CATEGORY_IDS]: string[];
  [ConfigurableFieldsEnum.REASSIGN_ALL]: boolean | null;
  [ConfigurableFieldsEnum.FIXED_SHIFT_CATEGORY_IDS]: string[];
  configurableFields: ConfigurableFieldsEnum[];
  taskDefinitionName: string;
  kpisUrl: string;
}

export enum ConfigurableFieldsEnum {
  BLOCK_TRANSFER_DURING_NIGHT_HOURS = 'blockTransferDuringNightHours',
  MINIMAL_REST_TIME_BETWEEN_SHIFTS_MINUTES = 'minimalRestTimeBetweenShiftsMinutes',
  MAXIMAL_LENGTH_OF_TRANSFER_MINUTES = 'maximalLengthOfTransferMinutes',
  IGNORED_QUALIFICATION_CATEGORY_IDS = 'ignoredQualificationCategoryIds',
  REASSIGN_ALL = 'reassignAll',
  FIXED_SHIFT_CATEGORY_IDS = 'fixedShiftCategoryIds',
}
