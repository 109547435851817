import { Injectable } from '@angular/core';
import { Absence } from '@wilson/interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AbsenceDrawerHelperService {
  private actualAbsenceSubject = new BehaviorSubject<
    (Absence & { id: string; isHighlighted: boolean }) | null
  >(null);
  private isAbsenceDrawerVisibleSubject = new BehaviorSubject(false);
  private selectedAbsenceIdSubject = new BehaviorSubject('');

  public actualAbsence$ = this.actualAbsenceSubject.asObservable();
  public isAbsenceDrawerVisible$ =
    this.isAbsenceDrawerVisibleSubject.asObservable();
  public selectedAbsenceId$ = this.selectedAbsenceIdSubject.asObservable();

  setActualAbsence(
    absence: (Absence & { id: string; isHighlighted: boolean }) | null,
  ): void {
    this.actualAbsenceSubject.next(absence);
  }

  toggleAbsenceDrawer(isVisible: boolean): void {
    this.isAbsenceDrawerVisibleSubject.next(isVisible);
  }

  setSelectedAbsenceId(id: string): void {
    this.selectedAbsenceIdSubject.next(id);
  }
}
