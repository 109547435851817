import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { QualificationCategory } from '@wilson/interfaces';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TransformMasterDataTranslationService } from '@wilson/non-domain-specific/master-data-translate';

@Injectable({
  providedIn: 'root',
})
export class QualificationCategoriesService {
  private categories$!: Observable<QualificationCategory[]>;

  constructor(
    private httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
    private transformMasterDataTranslationService: TransformMasterDataTranslationService,
  ) {}

  public getQualificationCategories(): Observable<QualificationCategory[]> {
    if (!this.categories$) {
      this.categories$ = this.httpClient
        .get<QualificationCategory[]>(
          `${this.config.host}/qualification-categories`,
        )
        .pipe(
          shareReplay(1),
          map((qualifications) => {
            return this.transformMasterDataTranslationService.transform(
              qualifications,
            );
          }),
        );
    }

    return this.categories$;
  }

  public getQualificationCategoryById(
    qualificationId: string,
  ): Observable<QualificationCategory> {
    return this.httpClient.get<QualificationCategory>(
      `${this.config.host}/qualification-categories/${qualificationId}`,
    );
  }

  public getUserQualificationCategories(
    userId: string,
  ): Observable<(QualificationCategory & { translatedName: string })[]> {
    return this.httpClient
      .get<QualificationCategory[]>(
        `${this.config.host}/user-qualifications/user/${userId}/categories`,
      )
      .pipe(
        map((qualifications) => {
          return this.transformMasterDataTranslationService.transform(
            qualifications,
          );
        }),
      );
  }
}
