import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faLinkSimpleSlash, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import {
  Activity,
  ActivityWithCategoryAndLocationsAndReportedDetails,
  Shift,
  WithPreparedAttributes,
} from '@wilson/interfaces';
import { PipesModule } from '@wilson/pipes';
import { cloneDeep } from 'lodash';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { DeleteActivityForbiddenMessagePipe } from '../pipes/delete-activity-forbidden-message.pipe';
import { DeleteActivityHelperService } from '../services/delete-activity-helper.service';

@Component({
  selector: 'wilson-delete-activity-button',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzToolTipModule,
    FontAwesomeModule,
    NzButtonModule,
    RouterModule,
    PipesModule,
    DeleteActivityForbiddenMessagePipe,
  ],
  templateUrl: './delete-activity-button.component.html',
  styleUrl: './delete-activity-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteActivityButtonComponent {
  @Input({ required: true }) shift: Shift &
    WithPreparedAttributes & { id: string } & {
      activities: ActivityWithCategoryAndLocationsAndReportedDetails[];
    };
  @Input({ required: true }) activity: Activity;
  @Input({ required: true }) hasShiftUpdatePermissions = false;
  @Input({ required: true }) hasServiceUpdatePermissions = false;
  @Input({ required: true }) isShiftAcceptedOrSubmittedToPayrollProvider =
    false;
  @Input({ required: true }) isShiftStarted = false;
  @Input({ required: true }) isShiftSubmitted = false;
  @Input({ required: true }) isAdminUser = false;
  @Input({ required: true }) isAutoAdaptActivitiesEnabled = true;

  @Output() delete = new EventEmitter<Activity>();

  protected deletingSignal = signal<boolean>(false);
  readonly faTrashCan = faTrash;
  readonly faLinkSimpleSlash = faLinkSimpleSlash;

  constructor(
    private readonly deleteActivityHelperService: DeleteActivityHelperService,
  ) {}

  protected async deleteActivity(activity: Activity): Promise<void> {
    this.deletingSignal.set(true);
    const isDeleted = await this.deleteActivityHelperService.deleteActivity(
      activity,
      cloneDeep(this.shift),
      this.isShiftSubmitted,
      this.isAutoAdaptActivitiesEnabled,
    );
    if (isDeleted) {
      this.delete.emit(activity);
    }
    this.deletingSignal.set(false);
  }
}
